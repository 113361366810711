import { lazy } from 'react'
import { RouteObject } from 'react-router'
const PAcontraAllocationPage = lazy(() => import('./PAcontraAllocationPage'))
const PAcontraDetailPage = lazy(() => import('./PAcontraDetailPage'))
const PAcontraForm = lazy(() => import('./PAcontraForm'))
const PAcontraListingPage = lazy(() => import('./PAcontraListingPage'))

//PA = purchaser's accounts
const PAContraRoutes: RouteObject[] = [
  {
    path: 'contra',
    children: [
      {
        path: '',
        element: <PAcontraListingPage tableRef="contra" />,
      },
      {
        path: ':id/view',
        element: <PAcontraDetailPage />,
      },
      {
        path: 'add',
        element: <PAcontraForm mode="add" tableRef="contra" />,
      },
      {
        path: ':id/edit',
        element: <PAcontraForm mode="edit" tableRef="contra" />,
      },
      {
        path: ':id/allocation',
        element: <PAcontraAllocationPage tableRef="contra" />,
      },
    ],
  },
]

export default PAContraRoutes
