import type * as React from 'react'
import { Menu as MuiMenu, type MenuProps as MuiMenuProps } from '@mui/material'

interface MenuProps extends MuiMenuProps {
  /** Retrieve parents ref and set Menu Width same like parents component Width  */
  divRef?: React.MutableRefObject<any>
}

export const Menu = ({ divRef, ...props }: MenuProps): JSX.Element => {
  return (
    <MuiMenu
      sx={{
        // '& .MuiList-root': {
        //   minWidth: '112px',
        // },
        '& .MuiPaper-root': {
          minWidth: `${divRef?.current?.offsetWidth || 112}px`,
        },
      }}
      {...props}
    />
  )
}
