import { formatDate } from 'helpers/StringNumberFunction/formatDate'
import { amtStr } from 'helpers/numFormatter'
import React from 'react'

import Typography, { TypographyProps } from '@mui/material/Typography'

interface TextXProps extends TypographyProps {
  className?: string
  data: string | number
  dataType?: 'Text' | 'Number' | 'Date' | 'Phone' | 'Email' | 'Percentage'
  alt?: string
}

const TextX: React.FC<TextXProps> = ({
  data,
  className,
  dataType = 'Text',
  component = 'span',
  alt = '-',
  variant = 'inherit',
  ...otherProps
}) => {
  let content

  if (data) {
    switch (dataType) {
      case 'Number':
        content = amtStr(data.toString())
        break
      case 'Percentage':
        content = `${amtStr(data.toString())}%`
        break
      case 'Text':
        content = data
        break
      case 'Date':
        const dateData = typeof data === 'number' ? data.toString() : data
        content = formatDate(dateData)
        break
      case 'Phone':
        content = <a href={`tel:${data}`}>{data}</a>
        break
      case 'Email':
        content = <a href={`mailto:${data}`}>{data}</a>
        break
      default:
        content = alt
    }
  } else {
    content = dataType === 'Number' ? amtStr(0) : alt
  }

  return (
    <Typography
      className={className}
      variant={variant}
      component={component}
      {...otherProps}
    >
      {content}
    </Typography>
  )
}

export default React.memo(TextX)
