import React, { lazy } from 'react'
import { RouteObject } from 'react-router'

const StakeholderReleaseListing = lazy(
  () => import('./StakeholderReleaseListing')
)
const StakeholderReleaseDetail = lazy(
  () => import('./StakeholderReleaseDetail')
)
const StakeholderReleaseForm = lazy(() => import('./StakeholderReleaseForm'))

const StakeholderReleaseRoutes: RouteObject[] = [
  {
    path: 'stakeholder-release',
    children: [
      {
        path: '',
        element: <StakeholderReleaseListing />,
      },
      {
        path: ':id/detail',
        element: <StakeholderReleaseDetail mode="detail" />,
      },
      {
        path: 'add',
        element: <StakeholderReleaseForm mode="add" />,
      },
      {
        path: ':id/edit',
        element: <StakeholderReleaseForm mode="edit" />,
      },
      {
        path: ':id/post',
        element: <StakeholderReleaseDetail mode="post" />,
      },
    ],
  },
]

export default StakeholderReleaseRoutes
