import { yupResolver } from '@hookform/resolvers/yup'
import {
  Autocomplete,
  Box,
  FormControlLabel,
  Switch,
  TextField,
  Typography,
} from '@mui/material'
import { DialogForm } from 'components/Form/DialogForm'
import { useAddressField } from 'containers/modules/EndFinancier/useAddressField'
import { AddressJson } from 'generated/graphql'
import { SystemMsgs } from 'helpers/Messages/SystemMsg'
import { CommonYupValidation } from 'helpers/yup'
import React, { useEffect, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { useParams } from 'react-router'
import * as yup from 'yup'

export interface addressProps {
  addressId?: string
  isPrimary: boolean
  addressType: string
  address: AddressJson
}
interface purchaserInfoAddressDialogProps {
  open: boolean
  setOpen: React.Dispatch<React.SetStateAction<boolean>>
  addressList: addressProps[]
  dialogMode: 'Add' | 'Edit' | ''
  menu: addressProps
  index: number
  update: any
  append: any
}

export interface addressTypeProps {
  addressType: string
}

export const PurchaserInfoAddressDialog = ({
  open,
  setOpen,
  dialogMode,
  menu,
  addressList,
  index,
  update,
  append,
}: purchaserInfoAddressDialogProps) => {
  // const [addAddressDialog, setAddAddressDialog] = React.useState(false)
  const { projectID, saleID }: any = useParams()
  const defaultAddressType = [
    'ID Address',
    'Billing Address',
    'Postal Address',
    'Company Address',
  ]
  const [addressType, setAddressType] = useState<string[]>(defaultAddressType)
  const [addrValue, setAddress] = useState(null)

  //region schema validation
  const validationSchema = yup.object().shape({
    addressType: CommonYupValidation.requireField(SystemMsgs.addressType()),
  })
  //#endregion

  //#region init useform method
  const {
    handleSubmit,
    control,
    formState: { errors, isSubmitted },
    setValue,
    reset,
    watch,
  } = useForm<addressProps>({
    defaultValues: {
      addressType: null,
      isPrimary: false,
    },

    mode: 'onSubmit',
    resolver: yupResolver(validationSchema),
  })

  //#endregion

  // const handleDialogClosed = () => {
  //   setAddAddressDialog(false)
  //   setDialogMode('')
  //   // reset({
  //   //   name: '',
  //   // })
  // }

  //#region auto address
  const { addressState, AddressFieldUI, handleAddressSubmitValidation } =
    useAddressField({
      editDataAddress: menu?.address,
      isSubmitted: isSubmitted,
    })
  //#endregion

  // const [
  //   getAddress,
  //   {
  //     data: { getAddressById } = { getAddressById: null },
  //     loading: getAddressByIdLoading,
  //     error: getAddressByIdError,
  //   },
  // ] = useGetAddressByIdLazyQuery({})

  useEffect(() => {
    if (open) {
      if (menu && dialogMode === 'Edit') {
        let array_of_address = menu?.address?.address
          ? [menu.address.address]
          : []
        setValue('addressType', menu?.address?.AddressType)
        setValue('isPrimary', menu?.isPrimary)
        setAddress(array_of_address?.slice(0))
      } else if (!menu && dialogMode === 'Add') {
        reset()
      }
    }

    // getAddress({
    //   variables: {
    //     saleId: saleID,
    //   },
    // })
  }, [open, dialogMode])

  useEffect(() => {
    if (open) {
      if (addressList) {
        const filteredAddressTypes = defaultAddressType.filter(
          type =>
            !addressList
              ?.flatMap(address => address?.address?.AddressType)
              ?.includes(type)
        )

        if (dialogMode === 'Edit' && menu?.address.AddressType) {
          filteredAddressTypes.push(menu.address.AddressType)
        }

        setAddressType(filteredAddressTypes)
      }
    }
  }, [open, addressList])

  const onSubmit = (data: addressProps) => {
    if (handleAddressSubmitValidation()) {
      setOpen(false)

      const submitData: addressProps = {
        address: {
          AddressType: data.addressType,
          address: addressState.address,
          city: addressState.city.name,
          country: addressState.country.name,
          postCode: addressState.postcode.postcode,
          state: addressState.state.name,
        },
        isPrimary: data.isPrimary,
        addressType: data.addressType,
        addressId: menu?.addressId ? menu?.addressId : null,
      }

      if (data?.isPrimary) {
        addressList?.map((el, i) => {
          update(i, {
            ...el,
            isPrimary: false,
          })
        })
      }

      if (dialogMode === 'Edit') {
        update(index, submitData)
      } else {
        append(submitData)
      }

      reset()
    }
  }

  // const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
  //   setIsSelectedAddress(event.target.checked)
  // }

  return (
    <>
      <DialogForm
        setOpenDialog={setOpen}
        dialogAction={open}
        mode={dialogMode}
        //onClose={() => handleDialogClosed()}
        title={'Address'}
        //subtitle={'Please fill in the form below'}
        body={
          <div>
            <Box pr="6px" width="100%">
              <Controller
                control={control}
                name="addressType"
                render={({ field: { onChange, onBlur, value } }) => (
                  <Autocomplete
                    options={addressType}
                    // getOptionLabel={option}
                    fullWidth
                    onChange={(event, value) => {
                      onChange(value)
                    }}
                    value={value}
                    renderInput={params => (
                      <TextField
                        {...params}
                        label="Address Type"
                        required
                        variant="standard"
                        margin="normal"
                        value={value}
                        autoComplete="off"
                        helperText={errors?.addressType?.message}
                        error={errors?.addressType ? true : false}
                      />
                    )}
                  />
                )}
              />
            </Box>
            {AddressFieldUI}
            <Box display={'flex'} marginTop={'20px'}>
              <Typography
                className="userDialogLabel"
                component="span"
                // flex="1 1"
                variant="inherit"
                marginTop={'6px'}
                marginRight={'30px'}
              >
                Primary
              </Typography>

              <FormControlLabel
                control={
                  <Controller
                    control={control}
                    name="isPrimary"
                    render={({ field: { onChange, onBlur, value } }) => (
                      <Switch
                        // className="switch-primary-color"
                        checked={value}
                        onChange={e => {
                          onChange(e.target.checked)
                        }}
                        color="primary"
                      />
                    )}
                  />
                }
                label=""
              />
            </Box>
          </div>
        }
        footer={{
          buttons: [
            {
              children: 'Cancel',
              color: 'primary',
              onClick: () => {
                setOpen(false)
                reset()
              },
            },

            {
              children: 'Save',
              color: 'primary',
              onClick: () => handleSubmit(onSubmit)(),
            },
          ],
        }}
      />
    </>
  )
}
