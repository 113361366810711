import React, { lazy } from 'react'
import { RouteObject } from 'react-router'

const SPReminderListing = lazy(() => import('./SPReminderListing'))
const SPReminderForm = lazy(() => import('./SPReminderForm'))

export const SP_REMINDER_PATH = {
  LISTING: '/:projectID/sales-purchase-reminder',
  FORM_ADD: '/:projectID/sales-purchase-reminder/add',
  DETAIL: '/:projectID/sales-purchase-reminder/detail/:id',
}

const SpReminderRoutes: RouteObject[] = [
  {
    path: 'sales-purchase-reminder',
    children: [
      {
        path: '',
        element: <SPReminderListing />,
      },
      {
        path: 'detail/:generationBatch',
        element: <SPReminderForm mode="detail" />,
      },
      {
        path: 'add',
        element: <SPReminderForm mode="add" />,
      },
    ],
  },
]

export default SpReminderRoutes
