import {
  Dialog,
  EmptyState,
  Expansion,
  ExpansionDetails,
  ExpansionSummary,
  List,
  ListItem,
  ListItemText,
  Subheader,
  SubheaderText,
} from '@ifca-ui/core'
import { ExpandMore, HouseOutlined } from '@mui/icons-material'
import { Box, Checkbox, Grid, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'

export interface Unit {
  amount: any
  unitNo: string
  unitId: string
  isSelectedUnit?: boolean
}
export interface Floor {
  floorLabel: string
  totalSelected?: number
  totalUnit?: number
  unitList: Unit[]
}
export interface BlockDetail {
  block: string
  totalAllSelected?: number
  totalAllUnitFloorBlock?: number
  totalFloor?: number
  floorList: Floor[]
}

interface BlockFloorUnitSelectionProps {
  blockDetails: any
  setSelectedUnit?: React.Dispatch<Unit[]>
  existingSelectedUnit?: string[]
  readOnly?: boolean
}

const BlockFloorUnitSelection = (props: BlockFloorUnitSelectionProps) => {
  const [selectedBlock, setSelectedBlock] = useState<BlockDetail>()
  const [selectedLevel, setSelectedLevel] = useState<Floor>()
  const [selectedUnit, setSelectedUnit] = useState<Unit[]>([])
  const [blockListData, setBlockListData] = useState<BlockDetail[]>([])
  const [previousSelected, setPreviousSelected] = useState<Unit[]>([])
  const [previousUnitsCount, setPreviousUnitsCount] = useState(0)
  const [initializeMasterData, setInitializeMasterData] =
    useState<boolean>(false)
  const [openUnit, setOpenUnit] = useState<boolean>(false)
  const [openView, setOpenView] = useState<boolean>(false)
  const [initializeEdit, setInitializeEdit] = useState<boolean>(false)

  const {
    blockDetails,
    setSelectedUnit: parentSetSelectedUnit,
    existingSelectedUnit,
    readOnly,
  } = props

  useEffect(() => {
    if (blockDetails?.length > 0 && !initializeMasterData) {
      setBlockListData([...blockDetails])
      setInitializeMasterData(true)
    }
  }, [blockDetails])

  useEffect(() => {
    if (parentSetSelectedUnit) {
      parentSetSelectedUnit(selectedUnit)
    }
  }, [selectedUnit])

  useEffect(() => {
    if (
      blockListData.length > 0 &&
      existingSelectedUnit &&
      existingSelectedUnit.length > 0 &&
      !initializeEdit
    ) {
      editSetup(blockListData, existingSelectedUnit)
    }
  }, [blockListData, existingSelectedUnit, initializeEdit])

  const editSetup = (data: BlockDetail[], existingSelectedUnit: string[]) => {
    initializeEditData(data, existingSelectedUnit)
    setInitializeEdit(true)
  }

  const initializeEditData = (
    data: BlockDetail[],
    existingSelectedUnit: string[]
  ) => {
    let temp: Unit[] = []
    data?.map(blocksObj => {
      blocksObj?.floorList.map(floorObj => {
        let tempCount = 0
        floorObj.unitList.map(unitObj => {
          let isExist = existingSelectedUnit?.some(x => x == unitObj?.unitId)
          if (isExist) {
            temp.push(unitObj)
            unitObj.isSelectedUnit = true
            tempCount++
          }
        })
        floorObj.totalSelected = tempCount
      })
      let countSelected = blocksObj?.floorList?.reduce(
        (prev: number, curr: Floor) => {
          return prev + curr?.totalSelected
        },
        0
      )
      blocksObj.totalAllSelected = countSelected
      let allUnitCount = blocksObj?.floorList?.reduce(
        (prev: any, curr: any) => {
          return prev + curr?.totalUnit
        },
        0
      )
      blocksObj.totalAllUnitFloorBlock = allUnitCount
    })
    setSelectedUnit([...temp])
    setBlockListData([...data])
  }

  const checkSelect = (isSelect: boolean, block: string) => {
    blockListData?.map(blocksObj => {
      if (blocksObj?.block == block) {
        if (isSelect) {
          let temp: Unit[] = []
          blocksObj?.floorList.map(floorObj => {
            let tempCount = 0
            floorObj.unitList.map(unitObj => {
              temp.push(unitObj)
              unitObj.isSelectedUnit = true
              tempCount++
            })
            floorObj.totalSelected = tempCount
          })
          let countSelected = blocksObj?.floorList?.reduce(
            (prev: any, curr: any) => {
              return prev + curr?.totalSelected
            },
            0
          )
          blocksObj.totalAllSelected = countSelected
          let allUnitCount = blocksObj?.floorList?.reduce(
            (prev: any, curr: any) => {
              return prev + curr?.totalUnit
            },
            0
          )
          blocksObj.totalAllUnitFloorBlock = allUnitCount
          setSelectedUnit([...selectedUnit, ...temp])
          // setSelectedUnit(prevSelectedUnit => [...prevSelectedUnit, ...temp])
          //deselect
        } else {
          blocksObj?.floorList.map(floorObj => {
            floorObj.unitList.map(unitObj => {
              let thisUnit = selectedUnit
              let unitIndex = selectedUnit?.findIndex(
                x => x.unitId == unitObj?.unitId
              )
              thisUnit.splice(unitIndex, 1)
              unitObj.isSelectedUnit = false
            })
            floorObj.totalSelected = floorObj.unitList?.filter(
              unit => unit.isSelectedUnit
            )?.length
          })
          let countSelected = blocksObj?.floorList?.reduce(
            (prev: any, curr: any) => {
              return prev + curr?.totalSelected
            },
            0
          )
          blocksObj.totalAllSelected = countSelected
          if (!!existingSelectedUnit) {
            blocksObj?.floorList.map(floorObj => {
              let tempCount = 0
              floorObj.unitList.map(unitObj => {
                if (selectedUnit.some(x => unitObj?.unitId == x?.unitId)) {
                  unitObj.isSelectedUnit = true
                  tempCount++
                }
              })
              floorObj.totalSelected = tempCount
            })
            let countSelected = blocksObj?.floorList?.reduce(
              (prev: any, curr: any) => {
                return prev + curr?.totalSelected
              },
              0
            )
            blocksObj.totalAllSelected = countSelected
          }
        }
        setBlockListData([...blockListData])
      }
    })
  }

  const initializeTemporaryData = () => {
    selectedBlock.totalAllSelected = previousUnitsCount
    blockListData?.map(blocksObj => {
      if (blocksObj?.block == selectedBlock?.block) {
        blocksObj?.floorList.map(floorObj => {
          if (floorObj.floorLabel == selectedLevel?.floorLabel) {
            floorObj.unitList.map(unitObj => {
              let unitSelected = previousSelected?.filter(
                x => x?.unitId == unitObj?.unitId
              )
              if (unitSelected?.length > 0) {
                unitObj.isSelectedUnit = true
              } else {
                unitObj.isSelectedUnit = false
              }
            })
            setSelectedUnit([...previousSelected])

            //update floor count
            floorObj.totalSelected = floorObj.unitList?.filter(
              unit => unit.isSelectedUnit
            )?.length
          }
        })

        setBlockListData([...blockListData])
      }
    })
  }

  const checkFloorSelect = (
    isSelect: boolean,
    block: string,
    floor: string
  ) => {
    blockListData?.map(blocksObj => {
      if (blocksObj?.block == block) {
        blocksObj?.floorList.map(floorObj => {
          if (floorObj?.floorLabel == floor) {
            let temp: Unit[] = []
            floorObj.unitList.map(unitObj => {
              if (isSelect) {
                if (!selectedUnit.includes(unitObj)) {
                  temp.push(unitObj)
                  unitObj.isSelectedUnit = true
                  floorObj.totalSelected = floorObj.totalUnit
                }
              } else {
                let thisUnit = selectedUnit
                let unitIndex = selectedUnit?.findIndex(
                  x => x.unitId == unitObj?.unitId
                )
                thisUnit.splice(unitIndex, 1)
                unitObj.isSelectedUnit = false

                floorObj.totalSelected = floorObj.unitList?.filter(
                  unit => unit.isSelectedUnit
                )?.length
              }
            })
            setSelectedUnit([...selectedUnit, ...temp])

            //count selected unit in a floor
            let countUnitInFloor = blocksObj?.floorList.reduce(
              (sum: number, item: Floor) => {
                if (item.totalSelected) {
                  return sum + item?.totalSelected
                } else {
                  return sum
                }
              },
              0
            )

            blocksObj.totalAllSelected = countUnitInFloor

            //total all unit in a block
            let allUnitCount = blocksObj?.floorList?.reduce(
              (prev: number, curr: Floor) => {
                return prev + curr?.totalUnit
              },
              0
            )
            blocksObj.totalAllUnitFloorBlock = allUnitCount
          }
        })
      }

      setBlockListData([...blockListData])
    })
  }

  const isIndeterminate = (data: BlockDetail) => {
    let allUnitSelectectedCount = data?.totalAllSelected
    let thisCount = data?.totalAllUnitFloorBlock
    if (allUnitSelectectedCount == 0) {
      return null
    }
    if (thisCount == allUnitSelectectedCount) {
      return false
    } else if (data?.totalAllSelected) {
      return true
    }
  }

  const isIndeterminateFloor = (data: Floor) => {
    if (data?.totalSelected == 0) {
      return null
    }
    if (data?.totalUnit == data?.totalSelected) {
      return false
    } else if (data?.totalSelected) {
      return true
    }
  }

  const unitDialog = (
    <Dialog
      fullWidth
      open={openUnit}
      onClose={() => {
        setOpenUnit(false)
        initializeTemporaryData()
        setSelectedLevel(null)
      }}
      header={
        <Subheader sx={{ padding: '10px 12px' }}>
          <SubheaderText
            primary={
              <Box borderBottom="1px solid #fff">
                <Box display="flex" margin="2px 0px" width="100%">
                  <Typography
                    className="text-xsTitle"
                    color="#FF9800"
                    component="span"
                    flex="1 1"
                    variant="inherit"
                  >
                    {selectedBlock?.block}
                  </Typography>
                </Box>
              </Box>
            }
            secondary={
              <Box
                color="#212121"
                display="flex"
                fontSize="10px"
                lineHeight="16px"
                mt="2px"
                width="100%"
              >
                <Typography
                  component="span"
                  className="text-xsTitle"
                  flex="1 1"
                  variant="inherit"
                >
                  {selectedLevel?.floorLabel}
                </Typography>
                <Typography
                  component="span"
                  className="text-icon text-desc"
                  variant="inherit"
                >
                  <HouseOutlined />
                  {selectedLevel?.totalSelected
                    ? selectedLevel?.totalSelected
                    : '0'}
                  &nbsp;Units | {selectedLevel?.totalUnit} Units
                </Typography>
              </Box>
            }
          />
        </Subheader>
      }
      body={
        <>
          <List className="list-unit-selection">
            <Grid
              container
              justifyContent="flex-start"
              alignItems="center"
              spacing={1}
            >
              {selectedLevel?.unitList
                ?.sort((a, b) => {
                  return a.unitNo.localeCompare(b.unitNo, 'en', {
                    numeric: true,
                  })
                })
                ?.map(v => (
                  <>
                    <Grid item key={v?.unitId}>
                      <ListItem
                        className="&.Mui-selected"
                        onClick={e => {
                          let changed = selectedUnit
                          if (
                            selectedLevel?.totalSelected == undefined ||
                            selectedLevel?.totalSelected == null
                          ) {
                            selectedLevel.totalSelected = 0
                          }
                          // if deselect
                          if (selectedUnit?.some(x => v?.unitId == x?.unitId)) {
                            v.isSelectedUnit = false
                            let changed = selectedUnit?.filter(
                              x => x?.unitId !== v.unitId
                            )
                            setSelectedUnit([...changed])
                            selectedLevel.totalSelected--
                            selectedBlock.totalAllSelected--

                            //if select
                          } else {
                            changed.push(v)
                            setSelectedUnit([...changed])
                            selectedLevel.totalSelected++
                            selectedBlock.totalAllSelected++
                            v.isSelectedUnit = true
                          }
                          setBlockListData([...blockListData])
                        }}
                        selected={v?.isSelectedUnit ? true : false}
                      >
                        <ListItemText
                          className="center"
                          primary={
                            <Typography
                              component="span"
                              className="text-xsTitle"
                              flex="1 1"
                              variant="inherit"
                            >
                              {v?.unitNo}
                            </Typography>
                          }
                        />
                      </ListItem>
                    </Grid>
                  </>
                ))}
            </Grid>
          </List>
        </>
      }
      footer={{
        buttons: [
          {
            children: 'Cancel',
            color: 'primary',
            onClick: () => {
              setOpenUnit(false)
              setSelectedLevel(null)
              initializeTemporaryData()
            },
          },
          {
            children: 'Save',
            color: 'primary',
            onClick: () => {
              setOpenUnit(false)
            },
          },
        ],
      }}
    />
  )

  const viewUnitDialog = (
    <Dialog
      fullWidth
      open={openView}
      onClose={() => {
        setOpenView(false)
      }}
      header={
        <Subheader sx={{ padding: '10px 12px' }}>
          <SubheaderText
            primary={
              <Box borderBottom="1px solid #fff">
                <Box display="flex" margin="2px 0px" width="100%">
                  <Typography
                    className="text-xsTitle"
                    color="#FF9800"
                    component="span"
                    flex="1 1"
                    variant="inherit"
                  >
                    {selectedBlock?.block}
                  </Typography>
                </Box>
              </Box>
            }
            secondary={
              <Box
                color="#212121"
                display="flex"
                fontSize="10px"
                lineHeight="16px"
                mt="2px"
                width="100%"
              >
                <Typography
                  component="span"
                  className="text-xsTitle"
                  flex="1 1"
                  variant="inherit"
                >
                  {selectedLevel?.floorLabel}
                </Typography>
                <Typography
                  component="span"
                  className="text-icon text-desc"
                  variant="inherit"
                >
                  <HouseOutlined />
                  {selectedLevel?.totalSelected
                    ? selectedLevel?.totalSelected
                    : '0'}
                  &nbsp;Units | {selectedLevel?.totalUnit} Units
                </Typography>
              </Box>
            }
          />
        </Subheader>
      }
      body={
        <>
          <List className="list-unit-selection">
            <Grid
              container
              justifyContent="flex-start"
              alignItems="center"
              spacing={1}
            >
              {selectedLevel?.unitList
                ?.sort((a, b) => {
                  return a.unitNo.localeCompare(b.unitNo, 'en', {
                    numeric: true,
                  })
                })
                ?.map(v => {
                  return v?.isSelectedUnit ? (
                    <>
                      <Grid item key={v?.unitId}>
                        <ListItem
                          className="&.Mui-selected"
                          selected={v?.isSelectedUnit ? true : false}
                        >
                          <ListItemText
                            className="center"
                            primary={
                              <Typography
                                component="span"
                                className="text-xsTitle"
                                flex="1 1"
                                variant="inherit"
                              >
                                {v?.unitNo}
                              </Typography>
                            }
                          />
                        </ListItem>
                      </Grid>
                    </>
                  ) : null
                })}
            </Grid>
          </List>
        </>
      }
    />
  )

  return (
    <>
      {unitDialog}
      {viewUnitDialog}
      {blockListData?.length == 0 ? (
        <EmptyState title="No Sales Unit Record Found" />
      ) : !!readOnly ? (
        blockListData
          ?.sort((a, b) => {
            return a.block.localeCompare(b.block, 'en', {
              numeric: true,
            })
          })
          ?.map((v, index) => {
            return v?.totalAllSelected > 0 ? (
              <>
                <Expansion>
                  <ExpansionSummary
                    expandIcon={<ExpandMore />}
                    aria-controls="panel-content"
                    id="expanion-panel"
                  >
                    <ListItemText
                      primary={
                        <Typography
                          color="common.black"
                          component="div"
                          display="flex"
                          variant="inherit"
                        >
                          <Typography
                            className="text-xsTitle"
                            component="span"
                            flex="1"
                            variant="inherit"
                          >
                            {v?.block}
                          </Typography>
                        </Typography>
                      }
                      secondary={
                        <Typography
                          component="div"
                          display="flex"
                          variant="inherit"
                          color="common.black"
                        >
                          <Typography
                            component="span"
                            className="text-icon text-desc"
                            variant="inherit"
                          >
                            <HouseOutlined />
                            {v?.totalFloor} Levels | {v.totalAllSelected ?? 0}{' '}
                            Unit Selected
                          </Typography>
                        </Typography>
                      }
                    />
                  </ExpansionSummary>
                  <ExpansionDetails>
                    <>
                      <List className="list-unit-selection">
                        <Grid
                          container
                          justifyContent="flex-start"
                          alignItems="center"
                          spacing={2}
                        >
                          {v?.floorList
                            ?.sort((a, b) => {
                              return a.floorLabel.localeCompare(
                                b.floorLabel,
                                'en',
                                { numeric: true }
                              )
                            })
                            ?.map((a, index) => {
                              return a?.totalSelected > 0 ? (
                                <Grid item>
                                  <ListItem
                                    onClick={() => {
                                      setSelectedLevel(a)
                                      setSelectedBlock(v)
                                      setOpenView(true)
                                    }}
                                    selected={
                                      a?.totalSelected > 0 ? true : false
                                    }
                                  >
                                    <ListItemText
                                      primary={
                                        <Typography
                                          component="span"
                                          className="text-xsTitle unit-selection-text"
                                          variant="inherit"
                                        >
                                          {a?.floorLabel}
                                        </Typography>
                                      }
                                      secondary={
                                        <Typography
                                          component="div"
                                          display="flex"
                                          variant="inherit"
                                          className="unit-selection-text"
                                        >
                                          <Typography
                                            component="span"
                                            className="text-icon text-desc"
                                            variant="inherit"
                                          >
                                            <HouseOutlined />
                                            {`${
                                              a?.totalSelected
                                                ? a?.totalSelected
                                                : '0'
                                            } | ${a?.totalUnit}`}
                                          </Typography>
                                        </Typography>
                                      }
                                    />
                                  </ListItem>
                                </Grid>
                              ) : null
                            })}
                        </Grid>
                      </List>
                    </>
                  </ExpansionDetails>
                </Expansion>
              </>
            ) : null
          })
      ) : (
        blockListData
          ?.sort((a, b) => {
            return a.block.localeCompare(b.block, 'en', {
              numeric: true,
            })
          })
          ?.map((v, index) => {
            return (
              <>
                <Expansion
                  onChange={(
                    event: React.SyntheticEvent,
                    expanded: boolean
                  ) => {
                    if (expanded == true) {
                      if (
                        v?.totalAllSelected == undefined ||
                        v?.totalAllSelected == null
                      ) {
                        v.totalAllSelected = 0
                        setPreviousUnitsCount(v?.totalAllSelected)
                      }
                      setSelectedLevel(null)
                      setSelectedBlock(v)
                    }
                  }}
                >
                  <ExpansionSummary
                    expandIcon={<ExpandMore />}
                    aria-controls="panel-content"
                    id="expanion-panel"
                  >
                    <Checkbox
                      edge="start"
                      tabIndex={-1}
                      disableRipple
                      size="small"
                      color="primary"
                      sx={{
                        padding: '0px 6px 0px 12px',
                        marginBottom: '12px',
                      }}
                      value={v?.block}
                      checked={
                        v?.totalAllUnitFloorBlock == v?.totalAllSelected &&
                        v?.totalAllUnitFloorBlock !== undefined
                      }
                      indeterminate={isIndeterminate(v)}
                      onClick={e => e.stopPropagation()}
                      onChange={e => {
                        checkSelect(e.target.checked, e.target.value)
                      }}
                    />
                    <ListItemText
                      primary={
                        <Typography
                          color="common.black"
                          component="div"
                          display="flex"
                          variant="inherit"
                        >
                          <Typography
                            className="text-xsTitle"
                            component="span"
                            flex="1"
                            variant="inherit"
                          >
                            {v?.block}
                          </Typography>
                        </Typography>
                      }
                      secondary={
                        <Typography
                          component="div"
                          display="flex"
                          variant="inherit"
                          color="common.black"
                        >
                          <Typography
                            component="span"
                            className="text-icon text-desc"
                            variant="inherit"
                          >
                            <HouseOutlined />
                            {v?.totalFloor} Levels | {v?.totalAllSelected ?? 0}{' '}
                            Unit Selected
                          </Typography>
                        </Typography>
                      }
                    />
                  </ExpansionSummary>
                  <ExpansionDetails>
                    <>
                      <List className="list-unit-selection">
                        <Grid
                          container
                          justifyContent="flex-start"
                          alignItems="center"
                          spacing={2}
                        >
                          {v?.floorList
                            .sort((a, b) => {
                              return a.floorLabel.localeCompare(
                                b.floorLabel,
                                'en',
                                { numeric: true }
                              )
                            })
                            .map((a, index) => (
                              <Grid item>
                                <ListItem
                                  onClick={() => {
                                    setSelectedLevel(a)
                                    setSelectedBlock(v)
                                    setOpenUnit(true)
                                    setPreviousSelected([...selectedUnit])
                                    setPreviousUnitsCount(v?.totalAllSelected)
                                  }}
                                  // selected={a?.totalSelected > 0 ? true : false}
                                >
                                  <ListItemText
                                    primary={
                                      <Typography
                                        color="common.black"
                                        component="div"
                                        display="flex"
                                        variant="inherit"
                                      >
                                        {' '}
                                        <Checkbox
                                          edge="start"
                                          tabIndex={-1}
                                          disableRipple
                                          size="small"
                                          color="primary"
                                          value={v?.block}
                                          checked={
                                            a?.totalSelected == a?.totalUnit
                                          }
                                          indeterminate={isIndeterminateFloor(
                                            a
                                          )}
                                          onClick={e => {
                                            e.stopPropagation()
                                          }}
                                          onChange={e => {
                                            setSelectedLevel(a)
                                            setSelectedBlock(v)
                                            checkFloorSelect(
                                              e.target.checked,
                                              e.target.value,
                                              a?.floorLabel
                                            )
                                          }}
                                        />
                                        <Typography
                                          component="span"
                                          className="text-xsTitle unit-selection-text"
                                          variant="inherit"
                                        >
                                          {a?.floorLabel}
                                        </Typography>
                                      </Typography>
                                    }
                                    secondary={
                                      <Typography
                                        component="div"
                                        display="flex"
                                        variant="inherit"
                                        className="unit-selection-text"
                                      >
                                        <Typography
                                          component="span"
                                          className="text-icon text-desc"
                                          variant="inherit"
                                        >
                                          <HouseOutlined />
                                          {`${
                                            a?.totalSelected
                                              ? a?.totalSelected
                                              : '0'
                                          } | ${a?.totalUnit}`}
                                        </Typography>
                                      </Typography>
                                    }
                                  />
                                </ListItem>
                              </Grid>
                            ))}
                        </Grid>
                      </List>
                    </>
                  </ExpansionDetails>
                </Expansion>
              </>
            )
          })
      )}
    </>
  )
}

export default BlockFloorUnitSelection
