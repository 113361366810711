import React, { lazy } from 'react'
import { RouteObject } from 'react-router-dom'

const Login = lazy(() => import('./Login'))
const Logout = lazy(() => import('./Logout'))
const ForgotPassword = lazy(() => import('./ForgotPassword'))
const ResetPassword = lazy(() => import('./ResetPassword'))
const NotFound = lazy(() => import('./NotFound'))
const AutoLogin = lazy(() => import('./AutoLogin'))

export const AUTH_PATH = {
  LOGIN: '/login',
  FORGOT_PASSWORD: '/forgot-password',
  UPDATE_PASSWORD: '/update-password/:token',
  NOT_FOUND: '/404-not-found',
  LOGOUT: '/logout',
}

const AuthRoutes: RouteObject[] = [
  {
    path: 'login',
    element: <Login />,
  },
  {
    path: 'forgot-password',
    element: <ForgotPassword />,
  },
  {
    path: 'update-password',
    element: <ResetPassword type="updatePassword" />,
  },
  {
    path: 'reset-password',
    element: <ResetPassword type="resetPassword" />,
  },
  {
    path: 'activate-account',
    element: <ResetPassword type="activateAccount" />,
  },
  {
    path: '404-not-found',
    element: <NotFound />,
  },
  {
    path: 'logout',
    element: <Logout />,
  },
  {
    path: 'autologin',
    element: <AutoLogin />,
  },
]

export default AuthRoutes
