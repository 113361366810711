import { Card, Subheader, SubContainerX, SubheaderText } from '@ifca-ui/core'
import { Box, Typography, ListItemButtonProps } from '@mui/material'
import { useRef } from 'react'
import NumberFormat from 'react-number-format'
import { formatDate } from 'helpers/StringNumberFunction/formatDate'
import { Functions, MonetizationOn } from '@mui/icons-material'
interface CardListItemProps {
  body: React.ReactNode
  itemButton?: ListItemButtonProps
  id?: string
}

interface CardListHeaderProps {
  title: React.ReactNode
  action?: React.ReactNode
  children?: any
}

export const CardListItem = ({ body, itemButton, id }: CardListItemProps) => {
  return (
    <Box className="card-list-item" id={id} key={id}>
      <Box display="flex" width="100%">
        {body}
        {itemButton}
      </Box>
    </Box>
  )
}

export const CardListHeader = ({
  title,
  action,
  children,
}: CardListHeaderProps) => {
  return (
    <Card
      cardContent={{
        sx: {
          display: 'block',
          padding: '0px',
          ':last-child': {
            paddingBottom: '0px',
          },
        },
      }}
    >
      <Subheader>
        <SubheaderText
          primary={
            <Box width="100%" display="flex" margin="2px 0px">
              <Typography flex="1 1" component="span" variant="inherit">
                {title}
              </Typography>
              <Typography component="span" variant="inherit">
                {action}
              </Typography>
            </Box>
          }
        />
      </Subheader>
      {children}
    </Card>
  )
}
