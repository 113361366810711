import { useState, type MouseEvent } from 'react'
/**
 * @param MenuOption
 * handle moreoption icon on listing page
 */
export const useMenuOption = (): any => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const [menu, setMenu] = useState({ id: '', index: 0, data: null })
  const handleClick = (
    event?: MouseEvent<HTMLButtonElement>,
    id: string = '',
    index: number = 0,
    data: any = null
  ): void => {
    event && setAnchorEl(event.currentTarget)
    setMenu({ id, index, data })
  }

  const handleClose = (): void => {
    setAnchorEl(null)
  }

  return {
    anchorEl,
    menu,
    setAnchorEl,
    handleClick,
    handleClose,
  }
}
