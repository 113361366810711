import React, { lazy } from 'react'
import { RouteObject } from 'react-router'

const ReasonCodeSubmenu = lazy(() => import('./ReasonCodeSubmenu'))
const CancellationReasonListing = lazy(
  () => import('./CancellationReasonListing')
)
const MarkupReasonListing = lazy(() => import('./MarkupReasonListing'))
const UnitTransferReasonListing = lazy(
  () => import('./UnitTransferReasonListing')
)
const PriceAdjustmentReasonListing = lazy(
  () => import('./PriceAdjustmentReasonListing')
)
const ReasonCodeListing = lazy(() => import('./ReasonCodeListing'))

const ReasonCodeRoutes: RouteObject[] = [
  {
    path: 'reason-code',
    children: [
      {
        path: '',
        element: <ReasonCodeSubmenu />,
      },
      // {
      //   path: `:reasonCodeType`,
      //   element: <ReasonCodeListing />,
      // },
      {
        path: `cancellation-reason`,
        element: <CancellationReasonListing />,
      },
      {
        path: `markup-reason`,
        element: <MarkupReasonListing />,
      },
      {
        path: `unit-transfer-reason`,
        element: <UnitTransferReasonListing />,
      },
      {
        path: `price-adjustment-reason`,
        element: <PriceAdjustmentReasonListing />,
      },
    ],
  },
]

export default ReasonCodeRoutes
