import moment from 'moment'
import { NricHelper } from '../nricHelper'

export const nricValidator = (
  nric: string,
  minAge?: number
): boolean | string => {
  let validatorsPattern = /\d{6}\d{2}\d{4}/g
  let nricString = nric.toString()
  if (!nricString.match(validatorsPattern)) {
    return 'Invalid NRIC format'
  }

  let nricYear = nricString.substring(0, 2)
  let nricMonth = nricString.substring(2, 4)
  let nricDay = nricString.substring(4, 6)
  let strDate = `${nricYear}-${nricMonth}-${nricDay}`
  if (!moment(strDate, 'YY-MM-DD').isValid()) {
    return 'Invalid NRIC date'
  }

  if (minAge) {
    //format the nric to xxxxxx-xx-xxxx
    nricString = `${nricString.substr(0, 6)}-${nricString.substr(
      6,
      2
    )}-${nricString.substr(8, 4)}`
    const nricFormat = new NricHelper(nricString)
    if (!nricFormat.valid) {
      return 'Invalid NRIC format'
    }
    let age = nricFormat.age
    if (age < minAge) {
      return `Minimum age is ${minAge} Please
            enter a valid D.O.B.`
    }
  }
  return true
}
