import { ReactNode } from 'react'

interface Props {
  children: ReactNode
  disabled?: boolean
}

const HideX = ({ children, disabled = true }: Props) => {
  return (
    <div
      style={{
        visibility: disabled ? 'hidden' : 'visible',
        userSelect: disabled ? 'none' : 'auto',
      }}
    >
      {children}
    </div>
  )
}

export default HideX
