import React, { lazy } from 'react'
import { RouteObject } from 'react-router'
import LoanApplicationRoutes from './LoanApplication/Routes'
import LoanSettlementRoutes from './LoanSettlement/Routes'
import RefinancingRoutes from './Refinancing/Routes'
import BankPaymentRoutes from './BankPayment/Routes'
import LoanCancellationRoutes from './LoanCancellation/Routes'

const BankLoanSubmenu = lazy(() => import('./BankLoanSubmenu'))

export const BANK_LOAN_PATH = {
  SUBMENU: '/:projectID/bank-loan',
}

const BankLoanRoutes: RouteObject[] = [
  {
    path: 'bank-loan',
    children: [
      {
        path: '',
        element: <BankLoanSubmenu />,
      },
      ...LoanApplicationRoutes,
      ...LoanSettlementRoutes,
      ...RefinancingRoutes,
      ...BankPaymentRoutes,
      ...LoanCancellationRoutes,
    ],
  },
]

export default BankLoanRoutes
