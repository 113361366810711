import React, { lazy } from 'react'
import { RouteObject } from 'react-router'
// import SubsaleSubmenu from './SubsaleSubmenu'

const SubsaleListing = lazy(() => import('./SubsaleListing'))
const SubsaleForm = lazy(() => import('./SubsaleForm'))
const SubsaleNewPurchaserForm = lazy(() => import('./SubsaleNewPurchaserForm'))
const SubsaleFormListing = lazy(() => import('./SubsaleFormListing'))
const SubsaleEndFinancierForm = lazy(() => import('./SubsaleEndFinancierForm'))
const SubsaleJointBuyerForm = lazy(() => import('./SubsaleJointBuyerForm'))
const SubsaleDetail = lazy(() => import('./SubsaleDetail'))
const SubsaleJointBuyerDetail = lazy(() => import('./SubsaleJointBuyerDetail'))
const SubsaleEndFinancierDetail = lazy(
  () => import('./SubsaleEndFinancierDetail')
)

const SubsaleRoutes: RouteObject[] = [
  {
    path: 'subsale',
    children: [
      {
        path: '',
        element: <SubsaleListing />,
      },
      {
        path: 'add',
        children: [
          {
            path: '',
            element: <SubsaleForm mode="add" />,
          },
          {
            path: 'new-purchaser',
            element: <SubsaleNewPurchaserForm mode="add" />,
          },
          {
            path: 'new-purchaser/listing',
            element: <SubsaleFormListing mode="add" />,
          },
          {
            path: 'new-purchaser/listing/end-financier',
            children: [
              {
                path: 'add',
                element: <SubsaleEndFinancierForm formMode="Add" mode="add" />,
              },
              {
                path: ':detailId/edit',
                element: <SubsaleEndFinancierForm formMode="Edit" mode="add" />,
              },
            ],
          },
          {
            path: 'new-purchaser/listing/joint-buyer',
            children: [
              {
                path: 'add',
                element: <SubsaleJointBuyerForm formMode="Add" mode="add" />,
              },
              {
                path: ':detailId/edit',
                element: <SubsaleJointBuyerForm formMode="Edit" mode="add" />,
              },
            ],
          },
        ],
      },
      {
        path: ':id/edit',
        children: [
          {
            path: '',
            element: <SubsaleForm mode="edit" />,
          },
          {
            path: 'new-purchaser',
            element: <SubsaleNewPurchaserForm mode="edit" />,
          },
          {
            path: 'new-purchaser/listing',
            element: <SubsaleFormListing mode="edit" />,
          },
          {
            path: 'new-purchaser/listing/end-financier',
            children: [
              {
                path: 'add',
                element: <SubsaleEndFinancierForm formMode="Add" mode="edit" />,
              },
              {
                path: ':detailId/edit',
                element: (
                  <SubsaleEndFinancierForm formMode="Edit" mode="edit" />
                ),
              },
            ],
          },
          {
            path: 'new-purchaser/listing/joint-buyer',
            children: [
              {
                path: 'add',
                element: <SubsaleJointBuyerForm formMode="Add" mode="edit" />,
              },
              {
                path: ':detailId/edit',
                element: <SubsaleJointBuyerForm formMode="Edit" mode="edit" />,
              },
            ],
          },
        ],
      },
      {
        path: ':id/detail',
        children: [
          {
            path: '',
            element: <SubsaleDetail />,
          },
          {
            path: ':detailId/joint-buyer-detail',
            element: <SubsaleJointBuyerDetail />,
          },
          {
            path: ':detailId/end-financier-detail',
            element: <SubsaleEndFinancierDetail />,
          },
        ],
      },
    ],
  },
]

export default SubsaleRoutes
