import React, { lazy } from 'react'
import { RouteObject } from 'react-router'

const UserListing = lazy(() =>
  import('./UserListing').then(module => ({
    default: module.UserListing,
  }))
)
const UserForm = lazy(() =>
  import('./UserForm').then(module => ({
    default: module.UserForm,
  }))
)
const UserDetail = lazy(() =>
  import('./UserDetail').then(module => ({
    default: module.UserDetail,
  }))
)

const userRoutes: RouteObject[] = [
  {
    path: 'user',
    children: [
      {
        path: '',
        element: <UserListing />,
      },
      {
        path: 'add',
        element: <UserForm mode="add" />,
      },
      {
        path: 'edit/:ID',
        element: <UserForm mode="edit" />,
      },
      {
        path: 'detail/:ID',
        element: <UserDetail />,
      },
    ],
  },
]

export default userRoutes
