import { RouteObject } from 'react-router'
import PACollectionRoutes from './Collection/Routes'
import PACollectionActivityForm from './CollectionActivity/PACollectionActivityForm'
import PACollectionActivityListing from './CollectionActivity/PACollectionActivityListing'
import PACollectionActivityRoutes from './CollectionActivity/Routes'
import PAContraRoutes from './Contra/Routes'
import PACreditNoteRoutes from './CreditNote/Routes'
import PADebitNoteRoutes from './DebitNote/Routes'
import PALateInterestChargesRoutes from './LateInterestCharges/Routes'
import PAmiscBillingRoutes from './MiscBilling/Routes'
import PAprogressBillingRoute from './ProgressBilling/Routes'
import { PurchaserAccountsDetail } from './PurchaserAccountsDetail'
import PurchaserAccountsSubmenu from './PurchaserAccountSubmenu'
import PARebateRoutes from './Rebate/Routes'
import PARefundRoutes from './Refund/Routes'
import PAPaymentReminderRoutes from './PaymentReminder/Routes'

export const SP_REMINDER_PATH = {
  LISTING: '/:projectID/purchaser-accounts',
}

//PA = purchasers' accounts
const PurchaserAccountsRoutes: RouteObject[] = [
  {
    path: 'purchaser-accounts',
    children: [
      {
        path: '',
        element: <PurchaserAccountsSubmenu />,
      },
      {
        path: ':saleID',
        children: [
          // {
          //   path: '',
          //   element: <PurchaserAccountsSubmenuTest />,
          // },
          {
            path: ':type',
            element: <PurchaserAccountsDetail />,
          },
          ...PAprogressBillingRoute,
          ...PAmiscBillingRoutes,
          ...PARebateRoutes,
          ...PACreditNoteRoutes,
          ...PADebitNoteRoutes,
          ...PAContraRoutes,
          ...PALateInterestChargesRoutes,
          ...PACollectionRoutes,
          ...PARefundRoutes,
          // ...PACollectionActivityRoutes,
          ...PAPaymentReminderRoutes,
          {
            path: 'collection-activity',
            children: [
              {
                path: '',
                element: (
                  <PACollectionActivityListing
                    mode="test"
                    pageMode="purchaser"
                  />
                ),
              },
              {
                path: 'add',
                element: (
                  <PACollectionActivityForm mode="add" pageMode="purchaser" />
                ),
              },

              {
                path: 'edit/:id',
                element: (
                  <PACollectionActivityForm mode="edit" pageMode="purchaser" />
                ),
              },
            ],
          },
        ],
      },
    ],
  },
]

export default PurchaserAccountsRoutes
