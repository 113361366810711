import React, { lazy } from 'react'
import { RouteObject } from 'react-router'

const VacantPossessionListing = lazy(() => import('./VacantPossessionListing'))
const VacantPossessionDetail = lazy(() => import('./VacantPossessionDetail'))
const VacantPossessionForm = lazy(() => import('./VacantPossessionForm'))

const VacantPossessionRoutes: RouteObject[] = [
  {
    path: 'vacant-possession',
    children: [
      {
        path: '',
        element: <VacantPossessionListing />,
      },
      {
        path: 'form/add',
        element: <VacantPossessionForm />,
      },
      {
        path: 'detail/:date',
        element: <VacantPossessionDetail />,
      },
    ],
  },
]

export default VacantPossessionRoutes
