import { CommonStatus } from 'generated/graphql'
import { CryptoHelper } from 'helpers/Crypto/Crypto'
import { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router'

export interface setLocalStorageInterface {
  key: string
  value: string
  //if you want to pass an object to the value, remember to change to JSON.stringify the object, then to change back to object after localStorage.getItem => JSON.parse
  //if you want to pass a date to the value, remember to change to date.toISOString()
  //if you want to pass a bolean to the value, remember to change to String(true)/String(false)
  //if you want to pass a number to the value, remember to change to String(number) => eg: String(30)
}

export interface routeStackInterface {
  pathname: string
  state: object
  status: CommonStatus
}

interface navToProps {
  routeUrl: string
  state?: any
  leftState?: any
  reset?: boolean
}

interface navMainToProps {
  routeUrl: string
}

interface navBackProps {
  passBackState?: any
  backIndex?: number
}

interface navReplaceProps {
  routeUrl: string
  state?: any
}

/**
 * navTo => navigate into a page
 *
 * navMainTo => sidebar navigation
 *
 * navBack => navigate back
 *
 * navReplace => navigate back and navigate into a page
 *
 * function starting with i (eg: iNavTo ) have similar function with its counterparts (eg: navTo) but have interface
 *
 * @returns
 */
const useNav = () => {
  let navigate = useNavigate()
  let location = useLocation()
  const navState: any = location?.state
  const [navData, setNavData] = useState(null)
  const isEmpty = (obj: object) => JSON.stringify(obj) === '{}'

  //region useffect to run when page is rendered
  useEffect(() => {
    //#region to call the current stack and take the state
    let routeStackCylinder: routeStackInterface[] =
      JSON.parse(localStorage.getItem('routeStack') ?? null) || []

    let currentRouteStack: routeStackInterface = routeStackCylinder?.find(
      x => x?.pathname == location.pathname
    )

    let stateData = currentRouteStack?.state

    if (!isEmpty(stateData)) {
      setNavData(stateData)
    }
    //#end region

    //#region checking for if there is no previous stack then this will be added as the first stack
    if (routeStackCylinder?.length === 0) {
      let obj: routeStackInterface = {
        pathname: location?.pathname,
        state: {},
        status: CommonStatus.Active,
      }

      routeStackCylinder.push(obj)

      localStorage.setItem('routeStack', JSON.stringify(routeStackCylinder))
    } else if (
      //else if to check if from the current stack, if any of the index's pathname is similar to the current pathname, then it will assume as backward/forward action with existing pathname
      routeStackCylinder
        .map(x => x?.pathname)
        .lastIndexOf(location?.pathname) !== -1
    ) {
      //if have then assume backward

      //the index of the routeStackCylinder which is the same with the pathname
      let currentIndex = routeStackCylinder
        .map(x => x?.pathname)
        .lastIndexOf(location?.pathname)

      if (routeStackCylinder[currentIndex]?.status === CommonStatus.Active) {
        //if the index's status is active then we assume it as going backward, hence we will make the index's stack and ONWARDS inactive
        let newRouteStackCylinder = routeStackCylinder?.map((x, i) => {
          if (i > currentIndex) {
            return { ...x, status: CommonStatus.Inactive }
          }
          return x
        })
        localStorage.setItem(
          'routeStack',
          JSON.stringify(newRouteStackCylinder)
        )
      } else {
        //else the index's status is inactive, then we assume it as going forward, hence we will make the index's stack active back
        let newRouteStackCylinder = routeStackCylinder?.map((x, i) => {
          if (i === currentIndex) {
            return { ...x, status: CommonStatus.Active }
          }
          return x
        })
        localStorage.setItem(
          'routeStack',
          JSON.stringify(newRouteStackCylinder)
        )
      }
    } else {
      //else we assume it is forward but to a new directory away from the listed stack
      //eg: A (active) => B (active & current page) => C (inactive) => D (inactive)
      //if the user which is currently at page B intends on going to route E, then it will NOT follow the history of the route which is after B it will go to C, hence it will remove up to the current page route (B) and create a new path onward
      //eg:  A (active) => B (active & current page) => E (active)
      //check status
      let existInactive = routeStackCylinder.some(
        (x, i) => x?.status === CommonStatus.Inactive
      )
      let newRouteStackCylinder = []
      if (existInactive === true) {
        let filteredRoute = routeStackCylinder?.filter(
          (x, i) => x?.status == CommonStatus.Active
        )
        newRouteStackCylinder = filteredRoute
      } else {
        let filteredRoute = routeStackCylinder
        newRouteStackCylinder = filteredRoute
      }

      let obj: routeStackInterface = {
        pathname: location?.pathname,
        state: {},
        status: CommonStatus.Active,
      }

      newRouteStackCylinder.push(obj)

      localStorage.setItem('routeStack', JSON.stringify(newRouteStackCylinder))
    }

    //#region to check localStorage newNavigation
    let newNavigation = JSON.parse(
      localStorage.getItem('newNavigation') ?? null
    )

    if (newNavigation) {
      navigate(`${newNavigation?.route}`, { state: newNavigation?.state })

      localStorage.removeItem('newNavigation')
    }
  }, [])

  //#region navTo but with 4 parameters

  //routeUrl is the first parameter which is has the same uses as navigate, if previously you would be using navigate('routeA'), then this will be navTo('routeA')

  //state is the second parameter which is also has the same use as navigate but this time u dont need to mention state, before this you might be using as navigate('routeA', {state: menu?.id}) now it will be like this, navTo('routeA', menu?.id)

  //leftState is the third paramters which is the state that u will be pass on the current route stack before navigating to the new page, this function can be used get the state back if you were to navigate back to the page using navBack, to give an example previously at credit note page if the filter status is at 'Completed', if we navigate to the detail page we will have to, navigate('detailPageRoute', {state: filterStatus}), then if we press the back button we have to pass back the state but this method ('listingPage', {state: filterStatus}) to go 'back' is actually going forward which is wrong. while using navigate(-1) which is the right method of navigating back has no feature of passing back a state

  //hence this function alongside navData, will be able to leave the filterStatus when navigating to a new page, then when using navBack, you will have the filterStatus via navData

  //reset is the fourth parameters, currrently this is use since purchaser account can be used from sales cancellation add form via the view ledger and from the sales admin submenu, if the user is from purchaser account via sales cancellation and press sales admin submenu, then access the purchaser account, the saleId from the state will be used which is not intended

  /**
   * forward navigation
   *
   * previous navigate usage for passing route and state
   *
   * => navigate(routeA, {state=menu?.id})
   *
   * new method
   *
   * => navTo(routeA, menu?.id)
   *
   * => navTo(el?.url, null, {}, true)
   *
   * @param routeUrl (non-nullable)  => navigation route
   * @param state (nullable) => passing state to next page
   * @param leftState  (nullable) => leaving a state at the current page before navigating to a new page
   * @param reset (nullable) => to reset route stack to current page for cases of multiple uses of same page
   *
   *
   */
  const navTo = (
    routeUrl: string,
    state?: any,
    leftState?: any,
    reset?: boolean
  ) => {
    try {
      let routeStackCylinder: routeStackInterface[] =
        JSON.parse(localStorage.getItem('routeStack') ?? null) || []

      if (reset) {
        //currrently this is use since purchaser account can be used from sales cancellation add form via the view ledger and from the sales admin submenu, if the user is from purchaser account via sales cancellation and press sales admin submenu, then access the purchaser account, the saleId from the state will be used which is not intended

        let indexToRemove = routeStackCylinder
          .map(x => x?.pathname)
          .lastIndexOf(location?.pathname)

        if (indexToRemove !== -1) {
          const updatedRouteStack = routeStackCylinder.slice(
            0,
            indexToRemove + 1
          )
          routeStackCylinder = updatedRouteStack
        }
      }

      //if got any state needed to be put to the stack before going to a new page/stack then this function takes the latest active stack (which is also the current page stack based on the top checking) then insert the state to the stack's state
      if (leftState) {
        for (let i = routeStackCylinder?.length - 1; i => 0; i--) {
          if (routeStackCylinder[i].status === CommonStatus.Active) {
            routeStackCylinder[i].state = leftState
            break
          }
        }
      }

      if (
        routeStackCylinder
          .map(x => x?.pathname)
          .lastIndexOf(location?.pathname) !== -1
      ) {
        //the index of the routeStackCylinder which is the same with the pathname
        let currentIndex = routeStackCylinder
          .map(x => x?.pathname)
          .lastIndexOf(location?.pathname)

        if (
          routeStackCylinder[currentIndex + 1]?.status === CommonStatus.Inactive
        ) {
          //if we are going forward and follow the same path history, we might carry a state to add form, if we use useNav the state should reset but if we use backward/forward browser then we should carry the state
          let newRouteStackCylinder = routeStackCylinder?.map((x, i) => {
            if (i === currentIndex + 1) {
              return { ...x, state: {} }
            }
            return x
          })

          routeStackCylinder = newRouteStackCylinder
        }
      }

      localStorage.setItem('routeStack', JSON.stringify(routeStackCylinder))
      navigate(`${routeUrl}`, { state: state })
    } catch (error) {
      console.log(error)
    }
  }
  //#endregion

  //#region navTo but with parameters

  /**
   * forward navigation
   *
   * previous navigate usage for passing route and state
   *
   * => navigate(routeA, {state=menu?.id})
   *
   * new method
   *
   * => iNavTo({routeUrl: routeA, state: menu?.id})
   *
   * => iNavTo({routeUrl: el?.url,reset: true})
   *
   * @param navToInput
   *
   * @object routeUrl (non-nullable)  => navigation route
   * @object state (nullable) => passing state to next page
   * @object leftState  (nullable) => leaving a state at the current page before navigating to a new page
   * @object reset (nullable) => to reset route stack to current page for cases of multiple uses of same page
   *
   *
   */
  const iNavTo = (navToInput: navToProps) => {
    try {
      navTo(
        navToInput.routeUrl,
        navToInput?.state,
        navToInput?.leftState,
        navToInput?.reset
      )
    } catch (error) {
      console.log(error)
    }
  }
  //#endregion

  //region navMainTo is used only at mainLayout

  /**
   * forward navigation used in side bar mainlayout navigation only
   *
   * => navMainTo(routeA)
   *
   * @param routeUrl (non-nullable)  => navigation route
   *
   *
   */
  const navMainTo = (routeUrl: string) => {
    try {
      let routeStackCylinder: routeStackInterface[] =
        JSON.parse(localStorage.getItem('routeStack') ?? null) || []

      let resetRoute = routeStackCylinder[0]

      routeStackCylinder = [resetRoute]

      if (
        routeStackCylinder
          .map(x => x?.pathname)
          .lastIndexOf(location?.pathname) !== -1
      ) {
        //the index of the routeStackCylinder which is the same with the pathname
        let currentIndex = routeStackCylinder
          .map(x => x?.pathname)
          .lastIndexOf(location?.pathname)

        if (
          routeStackCylinder[currentIndex + 1]?.status === CommonStatus.Inactive
        ) {
          //if we are going forward and follow the same path history, we might carry a state to add form, if we use useNav the state should reset but if we use backward/forward browser then we should carry the state
          let newRouteStackCylinder = routeStackCylinder?.map((x, i) => {
            if (i === currentIndex + 1) {
              return { ...x, state: {} }
            }
            return x
          })

          routeStackCylinder = newRouteStackCylinder
        }
      }

      localStorage.setItem('routeStack', JSON.stringify(routeStackCylinder))
      navigate(`${routeUrl}`)
    } catch (error) {
      console.log(error)
    }
  }
  //#endregion

  //#region navMainTo but with interface

  /**
   * forward navigation used in side bar mainlayout navigation only
   *
   * => iNavMainTo({routeUrl: routeA})
   *
   * @param navMainToInput
   *
   * @object routeUrl (non-nullable)  => navigation route
   *
   *
   */
  const iNavMainTo = (navMainToInput: navMainToProps) => {
    try {
      navMainTo(navMainToInput.routeUrl)
    } catch (error) {
      console.log(error)
    }
  }
  //#endregion

  //#region navBack to navigate back the page, also have the parameters

  //passbackState is used when we want to pass a state to a previous page as we are navigate back, previously in pages like refinancing/loan application forms have end financier card which can be add/deleted/edit by using useFieldArray, before this when pasing a new array from endfinform to the form (eg: refinancing form) it will use navigate('route', {state: state}), eventhough we are going back but we are actually navigating forward with a new state, when using browser back button or navigate(-1) it will become a problem.

  //backIndex is used when we want to navigate back more than 1, by default it is set to navigate(-1) but you custom to go back more than 1

  /**
   * backward navigation
   *
   * previous navigate usage for passing route and state
   *
   * => navigate(-1)
   *
   * new method
   *
   * => navBack()
   *
   * => navBack(state)
   *
   * => navBack(state, 2)
   *
   * @param passBackState (nullable)  => to place state at previous route stack
   *
   * @param backIndex (nullable)  => navigation backward more than 1 eg: navigate(-2)
   */
  const navBack = (passBackState?: any, backIndex: number = 1) => {
    navigate(-backIndex)

    let routeStackCylinder: routeStackInterface[] =
      JSON.parse(localStorage.getItem('routeStack') ?? null) || []

    if (passBackState) {
      let currentIndex = routeStackCylinder
        .map(x => x?.pathname)
        .lastIndexOf(location?.pathname)

      let newRouteStackCylinder = routeStackCylinder?.map((x, i) => {
        if (i === currentIndex - 1) {
          return { ...x, state: passBackState }
        }
        return x
      })

      routeStackCylinder = newRouteStackCylinder
    }
    localStorage.setItem('routeStack', JSON.stringify(routeStackCylinder))
  }

  //#endregion

  //#region navBack but with interface

  /**
   * backward navigation
   *
   * previous navigate usage for passing route and state
   *
   * => navigate(-1)
   *
   * new method
   *
   * => navBack({})
   *
   * => navBack({passBackState: state})
   *
   * => navBack({passBackState: state, backIndex: 2})
   *
   * @param passBackState (nullable)  => to place state at previous route stack
   *
   * @param backIndex (nullable)  => navigation backward more than 1 eg: navigate(-2)
   */

  /**
   * backward navigation
   *
   * previous navigate usage for passing route and state
   *
   * => navigate(-1)
   *
   * new method
   *
   * => navBack({})
   *
   * => navBack({passBackState: state})
   *
   * => navBack({passBackState: state, backIndex: 2})
   *
   * @param navBackInput
   *
   * @object passBackState (nullable)  => to place state at previous route stack
   *
   * @object backIndex (nullable)  => navigation backward more than 1 eg: navigate(-2)
   */
  const iNavBack = (navBackInput: navBackProps = {}) => {
    navBack(navBackInput?.passBackState, navBackInput?.backIndex)
  }

  //#endregion

  //#reigon navReplace function is for cases where if in Listing A we go to create a new form at Form A (current route: Listing A -> Form A), and when after Form A is created, if the feature inteded it to go to Detail Page A right after save (from Listing A -> Form A to Listing A -> Detail Page A), it will need to go back to Listing A then navigate to Detail Page A), Refer Property Sales Admin ->  Credit Control -> Late Payment Interest SubMenu -> Late Payment Interest -> Late Payment Interest Add Form (LatePaymentInterestForm)

  /**
   * Backward navigation then navigating to different page forward
   *
   * eg: (route A -> route B, navReplace(routeC) , then routeA -> route C)
   *
   * navReplace(routeA)
   *
   * navReplace(routeA, state)
   *
   * @param routeUrl (non-nullable)  => navigation route
   * @param state (nullable)  => passing state to next page
   */
  const navReplace = (routeUrl: string, state?: any) => {
    let newNavigation = {
      route: routeUrl,
      state: state ?? null,
    }

    //we will setitem the intended route we want to navigate after we go back, when the page is rendered, the useeffect in this custom hook will check for this localStorage, if have then it will proceed to the new route, while deleting the localStorage right after
    localStorage.setItem('newNavigation', JSON.stringify(newNavigation))
    navigate(-1)
  }
  //#endregion

  //#region navReplace but with interface

  /**
   * Backward navigation then navigating to different page forward
   *
   * eg: (route A -> route B, navReplace({routeUrl: routeC}) , then routeA -> route C)
   *
   * navReplace({routeUrl: routeA})
   *
   * navReplace({routeUrl: routeA, state: state})
   *
   * @param navReplaceInput
   *
   * @object routeUrl (non-nullable)  => navigation route
   * @object state (nullable)  => passing state to next page
   */
  const iNavReplace = (navReplaceInput: navReplaceProps) => {
    navReplace(navReplaceInput.routeUrl, navReplaceInput?.state)
  }

  //#endregion

  return {
    navTo,
    navBack,
    navReplace,
    navMainTo,
    iNavTo,
    iNavBack,
    iNavReplace,
    iNavMainTo,

    /**
     * navData of the current Route Stack
     */
    navData,

    /**
     * navState is location?.state
     */
    navState,
  }
}

export default useNav

//in the event that you still dont understand this custom hook, please refer this video https://www.youtube.com/watch?v=BBJa32lCaaY or ask WenBin/Azfar
