import { lazy } from 'react'
import { RouteObject } from 'react-router-dom'

const CP_Page = lazy(() => import('./CP_Page'))
const CP_Routes: RouteObject[] = [
  {
    path: 'CampaignPerformance',
    children: [
      {
        path: '',
        element: <CP_Page />,
      },
    ],
  },
]

export default CP_Routes
