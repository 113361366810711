export const sum = (array, key) => {
  if (!array || array?.length == 0) return 0
  return array?.reduce((a, b) => a + (Number(b[key]) || 0), 0)
}

export const customFormatNumber = amount => {
  if (amount) {
    return parseFloat(amount?.toString().replace(/,/g, ''))
  } else {
    return 0
  }
}
