import {
  AppBar,
  Tabs as MuiTabs,
  useMediaQuery,
  type TabsProps,
} from '@mui/material'
import { useTheme } from '@mui/material/styles'
import { styled } from '@mui/system'
import { type TopSpacing } from 'src/utils/types'

export const TabsRoot = styled(AppBar)`
  background: #fff;
  z-index: 100;
`
export const desktopTopSpacing = (topSpacing: TopSpacing): string => {
  switch (topSpacing) {
    // case 'sm':
    //   return '157px'
    // case 'md':
    //   return '100px'
    default:
      return '153px'
  }
}
export const mobileTopSpacing = (topSpacing: TopSpacing): string => {
  switch (topSpacing) {
    // case 'sm':
    //   return '157px'
    // case 'md':
    //   return '100px'
    default:
      return 'unset'
  }
}
type TabsPosition = 'static' | 'relative'
export interface tabsProps extends TabsProps {
  position?: TabsPosition
  // topSpacing?: TopSpacing | undefined
}

export const Tabs = ({ ...props }: tabsProps): JSX.Element => {
  const theme = useTheme()
  const isDesktop = useMediaQuery(theme.breakpoints.up('sm'), {
    defaultMatches: true,
  })
  return (
    <TabsRoot position="relative">
      <MuiTabs
        {...props}
        indicatorColor="primary"
        textColor="primary"
        variant={isDesktop ? null : 'fullWidth'}
      />
    </TabsRoot>
  )
}
