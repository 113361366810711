import { Dialog } from 'components/Dialogs/Dialog'
import { useState } from 'react'
import { registerSW } from 'virtual:pwa-register'

const ServiceWrokerWrapper = () => {
  const [showReload, setShowReload] = useState<boolean>(false)

  registerSW({
    onNeedRefresh() {
      setShowReload(true)
    },
  })

  const updateSW = registerSW({
    onRegistered(r) {
      r
        ? setInterval(
            () => {
              r.update()
            },
            60 * 60 * 1000
          )
        : setShowReload(false)
    },
  })

  return (
    <Dialog
      useDefaultDialogHeader={true}
      useDefaultDialogBody={true}
      open={showReload}
      defaultDialogData={{
        header: {
          title: {
            leftTopTitle: {
              title: {
                text: 'New Version',
              },
            },
          },
        },
        bodyText: 'A new version is available!',
      }}
      footer={{
        buttons: [
          {
            children: 'Reload',
            color: 'primary',
            type: 'button',
            onClick: () => {
              updateSW()
              setShowReload(false)
            },
          },
        ],
      }}
    />
  )
}

export default ServiceWrokerWrapper
