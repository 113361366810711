import React, { lazy } from 'react'
import { RouteObject } from 'react-router-dom'

// const EndFinancierListing = lazy(() => import('./EndFinancierListingNew'))
const EndFinancierListing = lazy(() => import('./EndFinancierListing'))
const EndFinancierSubmenu = lazy(() => import('./EndFinancierSubmenu'))
const EndFinancierForm = lazy(() => import('./EndFinancierForm'))

const EndFinancierRoutes: RouteObject[] = [
  {
    path: '',
    element: <EndFinancierListing />,
  },
  {
    path: '',
    children: [
      {
        path: ':id',
        element: <EndFinancierSubmenu />,
      },
      {
        path: 'end-financier-form/add',
        element: <EndFinancierForm formMode="Add" />,
      },
      {
        path: 'end-financier-form/:id/edit',
        element: <EndFinancierForm formMode="Edit" />,
      },
    ],
  },
]

export default EndFinancierRoutes
