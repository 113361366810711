import { Box, type BoxProps, type ButtonProps } from '@mui/material'
import { styled } from '@mui/material/styles'
import { FooterButtonProps, FooterButton } from '../Button'
import { useHotkeys } from 'react-hotkeys-hook'
import { useEffect, useMemo, useRef, useState } from 'react'
import { ButtonTypeEnum } from '../Button/FooterButton/FooterButton'

export const FooterFixedRoot = styled(Box)`
  width: 100%;
  position: fixed;
  bottom: 0;
  right: 0;
  background-color: #fff;
  z-index: 100;

  @media screen and (min-width: 600px) {
    width: calc(100% - 288px);
    bottom: 0;
    right: 24px;
  }
  &.full-width {
    left: 24px;
    width: calc(100% - 48px);
  }
`
export const FooterButtonContainer = styled(Box)`
  display: flex;
  justify-content: space-between;
  padding: 10px 24px;
  @media screen and (min-width: 600px) {
    justify-content: flex-end;
    .MuiButtonBase-root {
      margin-left: 18px;
    }
  }
`
export type hotkeyString = 'enter'
export interface FooterProps extends BoxProps {
  buttons?: ButtonProps[]
  hotkey?: hotkeyString
  heightOnChange?: (height: number) => void
}
export const Footer = ({
  buttons,
  hotkey,
  heightOnChange,
  ...props
}: FooterProps): JSX.Element => {
  const hotkeyFns = useMemo<{
    saveButtonFn: ButtonProps['onClick']
    cancelButtonFn: ButtonProps['onClick']
  }>(() => {
    // we'll find the types of buttons and map them to their respective hotkey functions
    // right now we only support save and cancel
    let saveButtonFn = () => {}
    let cancelButtonFn = () => {}

    buttons?.some(button => {
      if (button.type === ButtonTypeEnum.SAVE && button?.onClick) {
        saveButtonFn = button.onClick
        return true
      } else if (button.type === ButtonTypeEnum.CANCEL && button?.onClick) {
        cancelButtonFn = button.onClick
        return true
      } else {
        return false
      }
    })

    return {
      saveButtonFn,
      cancelButtonFn,
    }
  }, [buttons])

  useHotkeys(
    'enter',
    hotkeyFns.saveButtonFn,
    { scopes: ['save'] },
    { enableOnFormTags: ['INPUT', 'TEXTAREA', 'SELECT'] }
  )

  useHotkeys(
    'esc',
    hotkeyFns.cancelButtonFn,
    { scopes: ['cancel'] },
    { enableOnFormTags: ['INPUT', 'TEXTAREA', 'SELECT'] }
  )

  const footerRef = useRef<HTMLDivElement>(null)
  const [height, setHeight] = useState(0)

  //listen to window size on change
  const getWindowSize = () => {
    const { innerWidth, innerHeight } = window
    return { innerWidth, innerHeight }
  }

  const [windowSize, setWindowSize] = useState(getWindowSize())

  useEffect(() => {
    function handleWindowResize() {
      setWindowSize(getWindowSize())
    }

    window.addEventListener('resize', handleWindowResize)

    return () => {
      window.removeEventListener('resize', handleWindowResize)
    }
  }, [])

  // reset height
  useEffect(() => {
    const footer = footerRef?.current
    setHeight(footer?.clientHeight || 0)
  }, [footerRef?.current, buttons, windowSize])

  useEffect(() => {
    if (heightOnChange && typeof heightOnChange === 'function') {
      heightOnChange(footerRef?.current?.clientHeight || 0)
    }
  }, [height])

  return (
    <>
      <FooterFixedRoot
        ref={footerRef}
        id="footer"
        {...props}
        sx={{ boxShadow: 2, ...props?.sx }}
      >
        <FooterButtonContainer>
          {buttons?.map((button, i) => (
            <FooterButton
              key={i}
              variant="contained"
              sx={{
                margin: buttons?.length === 1 ? '0 auto !important' : 'unset',
              }}
              {...button}
            />
          ))}
        </FooterButtonContainer>
      </FooterFixedRoot>

      <div
        style={{
          height: `${height}px`,
        }}
      />
    </>
  )
}
