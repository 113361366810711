import { Box } from '@mui/system'
import { type FC } from 'react'

interface EmptyStateProps {
  title?: string
  subTitle?: string
}

export const EmptyState = ({
  title = 'No Record Found',
  subTitle,
}: EmptyStateProps): JSX.Element => {
  return (
    <Box
      key="1"
      component="div"
      sx={{
        textAlign: 'center',
        padding: '50px 12px',
      }}
    >
      <Box
        key="1"
        component="div"
        sx={{
          marginBottom: '6px',
          fontSize: '14px',
          fontWeight: 700,
          lineHeight: '20px',
        }}
      >
        {title}
      </Box>

      <Box key="2" component="div" className="text-desc">
        {subTitle}
      </Box>
    </Box>
  )
}

export const EmptyListFC: FC = () => (
  <EmptyState
    title="No record found."
    subTitle="Please click (+) to add new record."
  />
)
