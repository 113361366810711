import React, { lazy } from 'react'
import { RouteObject } from 'react-router'
import BridgingFinancingRoutes from './BridgingFinancing/Routes'
import RedemptionRepaymentRoutes from './RedemptionRepayment/Routes'

const BridgingLoanSubmenu = lazy(() => import('./BridgingLoanSubmenu'))
// const RedemptionRepaymentSettingForm = lazy(
//   () => import('./RedemptionRepaymentSetting/RedemptionRepaymentSettingForm')
// )

export const BRIDGING_LOAN_PATH = {
  SUBMENU: '/:projectID/bridging-loan',
}

const BridgingLoanRoutes: RouteObject[] = [
  {
    path: 'bridging-loan',
    children: [
      {
        path: '',
        element: <BridgingLoanSubmenu />,
      },
      // {
      //   path: 'redemption-repayment-setting',
      //   element: <RedemptionRepaymentSettingForm />,
      // },
      ...BridgingFinancingRoutes,
      ...RedemptionRepaymentRoutes,
    ],
  },
]

export default BridgingLoanRoutes
