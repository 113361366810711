import React, { lazy } from 'react'
import { RouteObject } from 'react-router'

const MsicCodeListing = lazy(() => import('./MsicCodeListing'))
const MsicCodeForm = lazy(() => import('./MsicCodeForm'))

const msicRoutes: RouteObject[] = [
  {
    path: 'msic-code',
    children: [
      {
        path: '',
        element: <MsicCodeListing />,
      },
      {
        path: ':id/add',
        element: <MsicCodeForm />,
      },
    ],
  },
]

export default msicRoutes
