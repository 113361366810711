import React, { lazy } from 'react'
import { RouteObject } from 'react-router'

const ProjectReminderSettingForm = lazy(
  () => import('./ProjectReminderSettingForm')
)

const ProjectReminderSettingRoutes: RouteObject[] = [
  {
    path: 'project-reminder-setting',
    element: <ProjectReminderSettingForm />,
  },
]

export default ProjectReminderSettingRoutes
