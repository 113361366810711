import React, { lazy } from 'react'
import { RouteObject } from 'react-router'

const SalesPackagesListing = lazy(() => import('./SalesPackagesListing'))
const SalesPackagesForm = lazy(() => import('./SalesPackagesForm'))

export const SALES_PACKAGES_PATH = {
  SALES_PACKAGES_LISTING: '/project/:projectID/sales-packages',
  SALES_PACKAGES_ADD: '/project/:projectID/sales-packages/add',
  SALES_PACKAGES_EDIT: '/project/:projectID/sales-packages/:id/edit',
}

const salesPackagesRoutes: RouteObject[] = [
  {
    path: 'sales-packages',
    children: [
      {
        path: '',
        element: <SalesPackagesListing />,
      },
      {
        path: 'add',
        element: <SalesPackagesForm mode="add" />,
      },
      {
        path: ':id/edit',
        element: <SalesPackagesForm mode="edit" />,
      },
    ],
  },
]

export default salesPackagesRoutes
