import { Expansion, ExpansionDetails, ExpansionSummary } from '@ifca-ui/core'
import { CalendarMonthRounded, ExpandMore } from '@mui/icons-material'
import { Box, Checkbox, FormControlLabel, Typography } from '@mui/material'
import { getUserProfile } from 'UserData'
import { useGetUserRoleIdsQuery } from 'generated/graphql'
import { formatDate } from 'helpers/StringNumberFunction/formatDate'
import { amtStr } from 'helpers/numFormatter'
import { useEffect, useState } from 'react'
import NumberFormat from 'react-number-format'
import { AttachmentsListComponent } from './AttachmentsListComponent'
import { PAdraftListComponent } from './PAdraftListComponent'

export interface props {
  listData?: []
  tableRef?: String
  refetch?: any
}
export const PAsubmitListComponent = props => {
  const [checkAll, setCheckAll] = useState(false)
  const [openDialog, setOpenDialog] = useState<boolean>(false)
  const [testList, setTestList] = useState<any>([])

  const style = {
    gridContainer: {
      display: 'grid',
      gridTemplateColumns: 'auto auto',
    },
  }

  const { data: { getUserRoleIds } = { getUserRoleIds: null } } =
    useGetUserRoleIdsQuery({})

  //#region handle check all
  useEffect(() => {
    if (props.listData?.length > 0 && getUserRoleIds?.length > 0) {
      if (
        props.idList?.length ==
          props.listData?.filter(
            x =>
              getUserRoleIds?.includes(x.approvalRoleId) ||
              x.approvalRoleId == null
          )?.length &&
        props.idList?.length > 0
      ) {
        setCheckAll(true)
      } else {
        setCheckAll(false)
      }
    }
  }, [props.idList, props.listData, getUserRoleIds])
  //#endregion

  return (
    <>
      {!getUserProfile()?.superUser && (
        <Typography padding="0 12px" component="div">
          <FormControlLabel
            control={
              <Checkbox
                checked={checkAll}
                onChange={event => {
                  setCheckAll(event.target.checked)
                  if (event.target.checked) {
                    props.listData?.map(el => {
                      const findIndex = props.idList?.indexOf(el.id)
                      const checked = findIndex > -1

                      if (
                        !checked &&
                        (getUserRoleIds?.includes(el.approvalRoleId) ||
                          el.approvalRoleId == null)
                      ) {
                        props.idList.push(el.id)
                      }
                    })
                  } else {
                    props.idList.splice(0)
                  }
                  props.setIdList([...props.idList])
                }}
              />
            }
            label="Select All"
            className="text-desc"
          />
        </Typography>
      )}

      {props.listData?.map((v, index) => {
        const findIndex = props.idList?.indexOf(v.id)
        const checked = findIndex > -1

        return (
          <Expansion key={v.id}>
            <ExpansionSummary
              expandIcon={<ExpandMore />}
              aria-controls="panel-content"
              id="expansion-panel"
            >
              {!getUserProfile()?.superUser && (
                <Checkbox
                  edge="start"
                  tabIndex={-1}
                  disableRipple
                  size="small"
                  color="primary"
                  sx={{
                    padding: '0px 6px 0px 12px',
                    marginBottom: '12px',
                  }}
                  onClick={e => {
                    e.stopPropagation()
                  }}
                  onChange={e => {
                    if (
                      getUserRoleIds?.includes(v.approvalRoleId) ||
                      v.approvalRoleId == null
                    ) {
                      if (checked) {
                        props.idList.splice(findIndex, 1)
                      } else {
                        props.idList.push(v.id)
                      }
                      props.setIdList([...props.idList])
                    }
                  }}
                  checked={checked}
                  disabled={
                    !getUserRoleIds?.includes(v.approvalRoleId) &&
                    v.approvalRoleId !== null
                  }
                />
              )}
              <PAdraftListComponent
                firstLine={{
                  left: {
                    // isCheckbox: true,
                    icon: <CalendarMonthRounded />,
                    text: `${formatDate(v?.documentDate) ?? '-'} | ${
                      formatDate(v?.dueDate) ?? '-'
                    } `,
                  },
                  right: {
                    amount: Math.abs(v?.documentAmount),
                    color: 'primary',
                    tooltip: 'Document Amount',
                  },
                }}
                secondLine={{
                  left: {
                    text: `${v?.description ? v?.description : '-'}`,
                  },
                  right: {
                    amount: Math.abs(v?.unallocatedAmount),
                    color: 'secondary',
                    tooltip: 'Unallocated Amount',
                  },
                }}
              />
            </ExpansionSummary>
            <ExpansionDetails>
              <div style={style.gridContainer}>
                <Box width="100%" boxSizing="border-box" marginBottom="14px">
                  <Typography
                    noWrap
                    component="div"
                    color="grey.600"
                    fontSize="10px"
                    fontWeight={400}
                    lineHeight="16px"
                  >
                    Request By
                  </Typography>
                  <Typography
                    noWrap
                    component="div"
                    color="common.black"
                    fontSize="12px"
                    fontWeight={500}
                    lineHeight="16px"
                  >
                    {v?.requestUserData ? v?.requestUserData?.name : '-'}
                  </Typography>
                </Box>
                <Box width="50%" boxSizing="border-box" marginBottom="14px">
                  <Typography
                    noWrap
                    component="div"
                    color="grey.600"
                    fontSize="10px"
                    fontWeight={400}
                    lineHeight="16px"
                  >
                    Amount
                  </Typography>

                  <Typography
                    noWrap
                    component="div"
                    color="common.black"
                    fontSize="12px"
                    fontWeight={500}
                    lineHeight="16px"
                  >
                    {amtStr(
                      Math.abs(v?.documentAmount) - v?.taxData?.totalTaxAmount
                    )}
                  </Typography>
                </Box>
                {v?.billingDetailTaxData && (
                  <>
                    <Box width="50%" boxSizing="border-box" marginBottom="14px">
                      <Typography
                        noWrap
                        component="div"
                        color="grey.600"
                        fontSize="10px"
                        fontWeight={400}
                        lineHeight="16px"
                      >
                        Tax
                      </Typography>
                      <Typography
                        noWrap
                        component="div"
                        color="common.black"
                        fontSize="12px"
                        fontWeight={500}
                        lineHeight="16px"
                      >
                        {v?.billingDetailTaxData?.taxSchemeName ?? '-'}
                      </Typography>
                    </Box>
                    <Box width="50%" boxSizing="border-box" marginBottom="14px">
                      <Typography
                        noWrap
                        component="div"
                        color="grey.600"
                        fontSize="10px"
                        fontWeight={400}
                        lineHeight="16px"
                      >
                        Tax Amount
                      </Typography>
                      <Typography
                        noWrap
                        component="div"
                        color="common.black"
                        fontSize="12px"
                        fontWeight={500}
                        lineHeight="16px"
                      >
                        {amtStr(v?.taxData?.totalTaxAmount)}
                      </Typography>
                    </Box>
                  </>
                )}

                <Box width="50%" boxSizing="border-box" marginBottom="14px">
                  <Typography
                    noWrap
                    component="div"
                    color="grey.600"
                    fontSize="10px"
                    fontWeight={400}
                    lineHeight="16px"
                  >
                    Total Amount
                  </Typography>
                  <Typography
                    noWrap
                    component="div"
                    color="common.black"
                    fontSize="12px"
                    fontWeight={500}
                    lineHeight="16px"
                  >
                    <NumberFormat
                      value={Math.abs(v?.documentAmount)}
                      displayType={'text'}
                      thousandSeparator={true}
                      fixedDecimalScale={true}
                      decimalScale={2}
                    />
                  </Typography>
                </Box>
                <Box width="100%" boxSizing="border-box" marginBottom="14px">
                  <Typography
                    noWrap
                    component="div"
                    color="grey.600"
                    fontSize="10px"
                    fontWeight={400}
                    lineHeight="16px"
                  >
                    Remarks
                  </Typography>
                  <Typography
                    noWrap
                    component="div"
                    color="common.black"
                    fontSize="12px"
                    fontWeight={500}
                    lineHeight="16px"
                  >
                    {v?.description == '' || null ? '-' : v?.description}
                  </Typography>
                </Box>
                <Box width="50%" boxSizing="border-box" marginBottom="14px">
                  <Typography
                    noWrap
                    component="div"
                    color="grey.600"
                    fontSize="10px"
                    fontWeight={400}
                    lineHeight="16px"
                  >
                    Submitted Date
                  </Typography>
                  <Typography
                    noWrap
                    component="div"
                    color="common.black"
                    fontSize="12px"
                    fontWeight={500}
                    lineHeight="16px"
                    overflow={'visible'}
                  >
                    {v?.submittedDate ? formatDate(v?.submittedDate) : '-'}
                  </Typography>
                </Box>
                <Box width="50%" boxSizing="border-box" marginBottom="14px">
                  <Typography
                    noWrap
                    component="div"
                    color="grey.600"
                    fontSize="10px"
                    fontWeight={400}
                    lineHeight="16px"
                  >
                    Submitted By
                  </Typography>
                  <Typography
                    noWrap
                    component="div"
                    color="common.black"
                    fontSize="12px"
                    fontWeight={500}
                    lineHeight="16px"
                    overflow={'visible'}
                  >
                    {v?.submitUserData?.name}
                  </Typography>
                </Box>
                <Box width="50%" boxSizing="border-box" marginBottom="14px">
                  <Typography
                    noWrap
                    component="div"
                    color="grey.600"
                    fontSize="10px"
                    fontWeight={400}
                    lineHeight="16px"
                  >
                    Attachments
                  </Typography>
                  <Typography
                    noWrap
                    component="div"
                    color="common.black"
                    fontSize="12px"
                    fontWeight={500}
                    lineHeight="16px"
                    overflow={'visible'}
                    className="text-underline text-xsTitle"
                    onClick={e => {
                      setOpenDialog(true)
                      setTestList(v?.attachment)
                    }}
                  >
                    {v?.attachment?.length}
                  </Typography>
                </Box>
                {v?.billItem && (
                  <Box width="50%" boxSizing="border-box" marginBottom="14px">
                    <Typography
                      noWrap
                      component="div"
                      color="grey.600"
                      fontSize="10px"
                      fontWeight={400}
                      lineHeight="16px"
                    >
                      Transaction Type
                    </Typography>
                    <Typography
                      noWrap
                      component="div"
                      color="common.black"
                      fontSize="12px"
                      fontWeight={500}
                      lineHeight="16px"
                      overflow={'visible'}
                    >
                      {v?.billItem?.name}
                    </Typography>
                  </Box>
                )}
              </div>
            </ExpansionDetails>
          </Expansion>
        )
      })}
      <AttachmentsListComponent
        openDialog={openDialog}
        setOpenDialog={setOpenDialog}
        listData={testList}
      />
    </>
  )
}
