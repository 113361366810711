import { lazy } from 'react'
import { RouteObject } from 'react-router'

const SalesAgencyPerfomance = lazy(() => import('./SalesAgencyPerfomance'))

export const SALES_MOVEMENT_PATH = {
  SUBMENU: '/business-insight/:projectID/sales-movement',
}

const SalesAgencyPerformanceRoutes: RouteObject[] = [
  {
    path: 'sales-agency-performance',
    children: [
      {
        path: '',
        element: <SalesAgencyPerfomance />,
      },
    ],
  },
]

export default SalesAgencyPerformanceRoutes
