import { Button, type ButtonProps } from '@mui/material'
import { styled } from '@mui/material/styles'

import { FontSizes, FontWeights, LineHeights } from '../../../styles/variables'

interface DialogButtonProps extends ButtonProps {}
export const DialogButton = styled(Button)<DialogButtonProps>`
  &.MuiButton-root {
    padding: 8px 10px;
    font-size: ${FontSizes.fs_12};
    line-height: ${LineHeights.lh_16};
    font-weight: ${FontWeights.semibold};
    text-transform: capitalize;
    min-width: 80px;
    &.MuiButton-containedPrimary {
      color: #ffffff;
    }
    &.Mui-disabled {
      color: #00000042;
    }
  }
`
