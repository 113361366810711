import { CommonSettingBase } from 'helpers/CommonRoutes'
import React, { lazy } from 'react'
import { RouteObject } from 'react-router'

const PaymentPlanListing = lazy(() => import('./PaymentPlanListing'))

const PaymentPlanForm = lazy(() => import('./PaymentPlanForm'))

const PaymentPlanDetailForm = lazy(() => import('./PaymentPlanDetailForm'))

const PaymentPlanView = lazy(() => import('./PaymentPlanView'))

const PaymentPlanDetailView = lazy(() => import('./PaymentPlanDetailView'))

const paymentPlanRoutes: RouteObject[] = [
  {
    path: 'payment-plan',
    children: [
      {
        path: '',
        element: <PaymentPlanListing />,
      },
      {
        path: 'add',
        children: [
          {
            path: '',
            element: <PaymentPlanForm mode="add" />,
          },
          {
            path: 'payment-plan-detail',
            children: [
              {
                path: 'add',
                element: <PaymentPlanDetailForm formMode="Add" mode="add" />,
              },
              {
                path: 'edit/:detailId',
                element: <PaymentPlanDetailForm formMode="Add" mode="edit" />,
              },
            ],
          },
        ],
      },
      {
        path: 'edit/:id',
        children: [
          {
            path: '',
            element: <PaymentPlanForm mode="edit" />,
          },
          {
            path: 'payment-plan-detail',
            children: [
              {
                path: 'add',
                element: <PaymentPlanDetailForm formMode="Edit" mode="add" />,
              },
              {
                path: 'edit/:detailId',
                element: <PaymentPlanDetailForm formMode="Edit" mode="edit" />,
              },
            ],
          },
        ],
      },
      {
        path: 'view/:id',
        children: [
          {
            path: '',
            element: <PaymentPlanView />,
          },
          {
            path: 'payment-plan-detail/:detailId',
            element: <PaymentPlanDetailView />,
          },
        ],
      },
    ],
  },
]
export default paymentPlanRoutes
