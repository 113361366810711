import React from 'react'

interface TextProps {
  // content: string
  fontSize?: string // Optional font size
  fontWeight?: 'normal' | 'bold' | 'lighter' | number
  color?: string // Optional color
  letterSpacing?: string // Optional letter spacing
  textAlign?: 'left' | 'right' | 'center' | 'justify' // Optional alignment
  maxLines?: number // Optional max lines for truncating text
  softWrap?: boolean // Whether text should break at soft line breaks
  overflow?: 'clip' | 'ellipsis' | 'visible' // How visual overflow should be handled
  className?: string // Optional class name
  children?: React.ReactNode
}

const UIlabel: React.FC<TextProps> = ({
  // content,
  fontSize = '12px',
  fontWeight = 'normal',
  color = 'black',
  letterSpacing = 'normal',
  textAlign = 'left',
  maxLines,
  softWrap = true,
  overflow = 'visible',
  className,
  children,
}) => {
  // Map fontWeight string to corresponding numeric values
  const fontWeightMap: Record<string, number> = {
    bold: 700,
    lighter: 300,
    normal: 400,
  }

  // Prepare style object conditionally
  const style: React.CSSProperties = {
    fontSize,
    fontWeight:
      typeof fontWeight === 'string'
        ? fontWeightMap[fontWeight] || 400
        : fontWeight,
    color,
    letterSpacing,
    textAlign,
    whiteSpace: softWrap ? 'normal' : 'nowrap', // Handle soft wrapping
    overflow: overflow === 'visible' ? 'visible' : 'hidden',
    textOverflow: overflow === 'ellipsis' ? 'ellipsis' : undefined,
    display: maxLines ? '-webkit-box' : 'block', // Only apply webkit-box for maxLines
    WebkitBoxOrient: maxLines ? 'vertical' : undefined,
    WebkitLineClamp: maxLines ? maxLines : undefined,
    lineClamp: maxLines ? maxLines : undefined, // Support other browsers
  }

  return (
    <div style={style} className={className}>
      {children ?? '-'}
    </div>
  )
}

export default UIlabel
