import { Box } from '@mui/system'
import type React from 'react'
interface FilePreviewContainerProps {
  children: React.ReactNode
}
export const FilePreviewContainer = ({
  children,
}: FilePreviewContainerProps): JSX.Element => {
  return (
    <Box
      width="100%"
      display="flex"
      flexDirection="row"
      flexWrap="wrap"
      mt="12px"
    >
      {children}
    </Box>
  )
}
