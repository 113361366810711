import {
  ColumnFlex,
  ExpansionDetails,
  RowFlex,
  RowGrid,
  Wrap,
} from '@ifca-ui/core'
import {
  AccountBalance,
  CalendarMonthRounded,
  KeyboardArrowRightOutlined,
  Person,
} from '@mui/icons-material'
import { Divider, IconButton, Tooltip } from '@mui/material'
import IconX from 'components/General/IconX'
import TextX from 'components/General/TextX'
import TooltipX from 'components/General/TooltipX'
import useAppData from 'components/General/useAppData'
import { PBdata } from 'generated/graphql'

type Props = {
  installmentData: PBdata[]
}

const PBexpansionDetailsPro = ({ installmentData }: Props) => {
  const { saleID, navTo, projectID } = useAppData()

  const handleClick = (PBtemplete: PBdata) => {
    navTo(
      `/${projectID}/purchaser-accounts/${saleID}/progress-billing/${
        PBtemplete.parentId || PBtemplete.id
      }/view`,
      {
        calculatedDocumentAmt: PBtemplete.sumOrangeAmount,
        outstandingAmt: PBtemplete.sumRedAmount,
      }
    )
  }

  const renderCreditDocs = (creditDocs: PBdata['creditDoc']) =>
    creditDocs?.map((doc, i) => (
      <span key={i}>
        <TextX data={doc?.document_date} /> | <TextX data={doc?.document_no} />
        {i !== creditDocs.length - 1 && ', '}
      </span>
    )) || <span>-</span>

  return (
    <ExpansionDetails sx={{ paddingRight: '10px' }}>
      <ColumnFlex gap="5px" fullWidth className="text-desc text-icon">
        {installmentData.map((PBtemplete, index) => (
          <RowFlex
            key={index}
            fullWidth
            crossAxisAlignment="center"
            gap="2px"
            className="clickable-item"
          >
            <ColumnFlex gap="5px" fullWidth>
              {index !== 0 && <Divider />}
              <div onClick={() => handleClick(PBtemplete)}>
                <ColumnFlex gap="4px" fullWidth>
                  <RowFlex mainAxisAlignment="space-between" fullWidth>
                    <RowFlex>
                      <IconX icon={CalendarMonthRounded} />
                      <RowFlex gap="4px">
                        <TextX data={PBtemplete.document_date} />
                        <div>|</div>
                        <TextX data={PBtemplete.document_no} />
                      </RowFlex>
                    </RowFlex>
                    <RowFlex className="c-orange">
                      <TooltipX title="Document Amount" placement="left">
                        <TextX
                          data={PBtemplete.sumOrangeAmount}
                          dataType="Number"
                          className="text-icon"
                          color="primary"
                        />
                      </TooltipX>
                    </RowFlex>
                  </RowFlex>
                  <RowFlex mainAxisAlignment="space-between" fullWidth>
                    {PBtemplete.isBuyer ? (
                      <RowGrid gridTemplateColumns="1fr 0.3fr">
                        <RowFlex>
                          <IconX icon={Person} />
                          <Wrap rowGap="4px">
                            {renderCreditDocs(PBtemplete.creditDoc)}
                          </Wrap>
                        </RowFlex>
                        <div></div>
                      </RowGrid>
                    ) : (
                      <Tooltip
                        title={`${PBtemplete?.financier_name ?? '-'} (${
                          PBtemplete?.financier_code ?? '-'
                        })`}
                        id="pbTooltip"
                        placement="top-start"
                      >
                        <div>
                          <RowGrid gridTemplateColumns="1fr 0.3fr">
                            <RowFlex>
                              <IconX icon={AccountBalance} />
                              <Wrap rowGap="4px">
                                {renderCreditDocs(PBtemplete.creditDoc)}
                              </Wrap>
                            </RowFlex>
                            <div></div>
                          </RowGrid>
                        </div>
                      </Tooltip>
                    )}
                    <RowFlex>
                      <TooltipX title="Outstanding Amount" placement="left">
                        <TextX
                          data={PBtemplete.sumRedAmount}
                          dataType={PBtemplete.sumRedAmount ? 'Number' : 'Text'}
                          className="text-icon"
                          color="red"
                        />
                      </TooltipX>
                    </RowFlex>
                  </RowFlex>
                </ColumnFlex>
              </div>
            </ColumnFlex>
            <IconButton sx={{ paddingRight: '0px' }}>
              <IconX icon={KeyboardArrowRightOutlined} size={25} />
            </IconButton>
          </RowFlex>
        ))}
      </ColumnFlex>
    </ExpansionDetails>
  )
}

export default PBexpansionDetailsPro
