import React, { lazy } from 'react'

const MiscBillingListing = lazy(() => import('./MiscBillingListing'))

const MiscBillingPosting = lazy(() => import('./MiscBillingPosting'))

const MiscBillingForm = lazy(() => import('./MiscBillingForm'))

const miscBillingRoutes = [
  {
    path: 'misc-billing',
    children: [
      {
        path: '',
        element: <MiscBillingListing />,
      },
      {
        path: 'add',
        element: <MiscBillingForm mode="add" />,
      },
      {
        path: 'edit/:id',
        element: <MiscBillingForm mode="edit" />,
      },
      {
        path: 'posting/:ID',
        element: <MiscBillingPosting />,
      },
    ],
  },
]

export default miscBillingRoutes
