import React, { lazy } from 'react'
import { RouteObject } from 'react-router'
import AreaVarianceRoutes from './AreaVariance/Routes'
import AreaVariancePolicyRoutes from './AreaVariancePolicy/Routes'

const AreaVarianceSubmenu = lazy(() => import('./AreaVarianceSubmenu'))

export const AREA_VARIANCE_PATH = {
  SUBMENU: '/:projectID/area-variance',
}

const AreaVarianceSubmenuRoutes: RouteObject[] = [
  {
    path: 'area-variance',
    children: [
      {
        path: '',
        element: <AreaVarianceSubmenu />,
      },
      ...AreaVarianceRoutes,
      ...AreaVariancePolicyRoutes,
    ],
  },
]

export default AreaVarianceSubmenuRoutes
