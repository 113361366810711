import { Button, type ButtonProps } from '@mui/material'
import { styled } from '@mui/material/styles'
import { FontSizes, FontWeights, LineHeights } from '../../../styles/variables'

export enum ButtonTypeEnum {
  // we have cancel and save types
  CANCEL = 'CANCEL',
  SAVE = 'SAVE',
}

export interface FooterButtonProps extends ButtonProps {
  type: ButtonTypeEnum
}

export const FooterButton = styled(Button)<FooterButtonProps>`
  &.MuiButton-root {
    padding: 10px 16px;
    font-size: ${FontSizes.fs_12};
    line-height: ${LineHeights.lh_16};
    font-weight: ${FontWeights.semibold};
    text-transform: capitalize;
    min-width: 140px;
    &.MuiButton-containedPrimary {
      color: #ffffff;
    }
    &.Mui-disabled {
      color: #00000042;
    }
  }
`
