import { Chip as MuiChip, type ChipProps } from '@mui/material'
import { styled } from '@mui/material/styles'

const MuiChipRoot = styled(MuiChip)`
  border-radius: 3px;
  padding: 1px 12px;
  height: auto;
  &.MuiChip-icon {
    margin-left: 0px;
  }
`

export const Chip = ({ ...props }: ChipProps): JSX.Element => {
  return <MuiChipRoot size="small" {...props} />
}
