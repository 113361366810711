import React, { lazy } from 'react'
import { RouteObject } from 'react-router-dom'
import TaxSchemeDetail from './TaxSchemeDetail'
import TaxSchemeForm from './TaxSchemeForm'
import TaxSchemeListing from './TaxSchemeListing'

const taxSchemeRoutes: RouteObject[] = [
  {
    path: 'tax-scheme',
    children: [
      {
        path: '',
        element: <TaxSchemeListing />,
      },
      {
        path: 'add',
        element: <TaxSchemeForm mode="add" />,
      },
      {
        path: ':id/edit',
        element: <TaxSchemeForm mode="edit" />,
      },
      {
        path: ':id',
        element: <TaxSchemeDetail />,
      },
    ],
  },
]

export default taxSchemeRoutes
