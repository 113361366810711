import { lazy } from 'react'
import { RouteObject } from 'react-router-dom'

const WaitingListAnalysisPage = lazy(() => import('./WaitingListAnalysisPage'))
const WaitingListRoutes: RouteObject[] = [
  {
    path: 'waiting-list-analysis',
    children: [
      {
        path: '',
        element: <WaitingListAnalysisPage />,
      },
    ],
  },
]

export default WaitingListRoutes
