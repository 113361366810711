import * as React from 'react'

export interface FileAttachment {
  File: File
  AttachmentID: string
  ResourceUrl?: string
}

export function useUploadAttachment(): any {
  const [previewFiles, setPreviewFiles] = React.useState<string[]>([])
  const [files, setFiles] = React.useState<any[]>([])
  const [existingFiles, setExistingFiles] = React.useState<FileAttachment[]>([])
  const [uploadedFiles, setUploadedFiles] = React.useState([])

  //to handle upload audio file type as of now
  const handleUploadManually = (file: File) => {
    const tempPreview = [...Array.from(previewFiles)]
    const tempFiles = [...Array.from(files)]
    const tempUploadedFiles = [...Array.from(uploadedFiles)]

    tempPreview.push(URL.createObjectURL(file))
    tempFiles.push(file)
    tempUploadedFiles.push(file)

    setPreviewFiles(tempPreview)
    setFiles(tempFiles)
    setUploadedFiles(tempUploadedFiles)
  }

  const handleUploadChange = (e: any): void => {
    const fileObj = []
    const fileArray = []
    const fileToPush = []

    fileObj.push(e.target.files)
    for (let i = 0; i < fileObj[0].length; i++) {
      fileArray.push(URL.createObjectURL(fileObj[0][i]))
      fileToPush.push(fileObj[0][i])
    }
    const tempPreview = [...previewFiles, ...fileArray]
    const tempFiles = [...files, ...fileToPush]
    const tempUploadFiles = [...uploadedFiles, ...fileToPush]

    setPreviewFiles(tempPreview)
    setFiles(tempFiles)
    setUploadedFiles(tempUploadFiles)
  }

  const handleRemove = (index: number): void => {
    const tempFiles = [...Array.from(files)]
    const tempPreview = [...Array.from(previewFiles)]
    let tempNewUploadFiles = [...Array.from(uploadedFiles)]
    const removedFile = tempFiles.splice(index, 1)
    tempPreview.splice(index, 1)
    tempNewUploadFiles = tempNewUploadFiles.filter(
      x => !removedFile.includes(x)
    )
    setFiles(tempFiles)
    setPreviewFiles(tempPreview)
    setUploadedFiles(tempNewUploadFiles)
  }

  const setExistingFile = (existingFiles: FileAttachment[]) => {
    let tempPreview = [...Array.from(previewFiles)]
    let tempFiles = [...Array.from(files)]
    for (let i = 0; i < existingFiles.length; i++) {
      // tempPreview.push(URL.createObjectURL(existingFiles[i].File))
      tempPreview.push(existingFiles[i].ResourceUrl)
      tempFiles.push(existingFiles[i].File)
    }

    setExistingFiles([...existingFiles])
    setFiles(tempFiles)
    setPreviewFiles(tempPreview)
  }

  const getExistingFileRemovedAttachmentIDs = (): string[] => {
    //return a list of attachmentIds that delete
    let result: string[] = []
    existingFiles.filter(x => !files.includes(x.File))
    for (let i = 0; i < existingFiles.length; i++) {
      let exist = files.includes(existingFiles[i].File)
      if (!exist) {
        result.push(existingFiles[i].AttachmentID)
      }
    }
    return result
  }

  const resetUploadedFiles = () => {
    setExistingFiles([])
    setFiles([])
    setPreviewFiles([])
    setExistingFiles([])
  }

  return {
    handleRemove,
    files,
    setFiles,
    previewFiles,
    setPreviewFiles,
    handleUploadChange,
    setExistingFile,
    getExistingFileRemovedAttachmentIDs,
    uploadedFiles,
    resetUploadedFiles,
    handleUploadManually,
  }
}
