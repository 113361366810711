import React, { lazy } from 'react'
import { RouteObject } from 'react-router'

const AccountJournalSubmenu = lazy(() => import('./AccountJournalSubmenu'))
const AccountMapping = lazy(() => import('./AccountMapping/AccountMapping'))
const GlExportListing = lazy(() => import('./GlExport/GLExportListing'))

const GlExportForm = lazy(() => import('./GlExport/GLExportForm'))
const GLExportDetail = lazy(() => import('./GlExport/GLExportDetail'))

export const ACCOUNT_JOURNAL_SUBMENU_PATH = {
  SUBMENU: '/account-journal',
  ACCOUNTMAPPING: '/account-journal/account-mapping',
  GL_EXPORT_LISTING: '/account-journal/gl-export',
  GL_EXPORT_FORM: '/account-journal/gl-export/add',
}

const AccountJournalRoutes: RouteObject[] = [
  {
    path: '',
    element: <AccountJournalSubmenu />,
  },
  {
    path: 'account-mapping',
    element: <AccountMapping />,
  },
  {
    path: 'gl-export',
    children: [
      {
        path: '',
        element: <GlExportListing />,
      },
      {
        path: 'add',
        element: <GlExportForm />,
      },
      {
        path: ':generationBatchId',
        element: <GLExportDetail />,
      },
    ],
  },
]

export default AccountJournalRoutes
