let navigationHistory: any = []
let searchValue = null

export class navigationService {
  setNavigationPath(s: {}) {
    navigationHistory.push(s)
    if (navigationHistory[0].path !== '/home') {
      navigationHistory.splice(0, navigationHistory.length - 1)
    }
    this.setSessionStorage()
  }
  updateState(y: string) {
    navigationHistory[navigationHistory.length - 2].state = y
    this.setSessionStorage()
    searchValue = null
  }
  backPerviousPage() {
    navigationHistory.splice(navigationHistory.length - 2, 2)
    this.setSessionStorage()
  }
  backHome() {
    navigationHistory.splice(0, navigationHistory.length)
    this.setSessionStorage()
  }
  clickSideBar(id) {
    let foundGBI = false
    let foundPBI = false
    let foundSalesCRM = false
    let foundSalesAdmin = false
    let foundSol = false
    let foundEndFin = false
    let foundCmnSet = false
    let foundProjSetup = false
    let foundSysAdmin = false
    for (let i = 0; i < navigationHistory.length; i++) {
      // foundGBI = navigationHistory[i].path === '/user-list'
      // foundPBI = navigationHistory[i].path === '/permission'
      // foundSalesCRM = navigationHistory[i].path
      // foundSalesAdmin = navigationHistory[i].path
      foundSol = navigationHistory[i].path === '/solicitor'
      foundEndFin = navigationHistory[i].path === '/end-financier'
      foundCmnSet = navigationHistory[i].path === '/common-setting'
      foundProjSetup = navigationHistory[i].path === '/project'
      foundSysAdmin = navigationHistory[i].path === '/system-admin'
      if (id === 'Solicitor') {
        if (foundSol) navigationHistory.splice(1, 2)
      }
      if (id === 'EndFinancier') {
        if (foundEndFin) navigationHistory.splice(1, 2)
      }
      if (id === 'CommonSetting') {
        if (foundCmnSet) navigationHistory.splice(1, 2)
      }
      if (id === 'Project Setup') {
        if (foundProjSetup) navigationHistory.splice(1, 2)
      }
      if (id === 'SystemAdmin') {
        if (foundSysAdmin) navigationHistory.splice(1, 2)
      }
    }
    this.setSessionStorage()
  }
  setSearchValue(s: string) {
    searchValue = s
  }
  getSearchValue() {
    return searchValue
  }
  clearArray() {
    navigationHistory.splice(0, navigationHistory.length)
    navigationHistory.push({ path: '/dashboard', state: '' })
    this.setSessionStorage()
  }
  //when projectid in case-list change
  removeCaseList() {
    navigationHistory.splice(1, 1)
    this.setSessionStorage()
  }
  setSessionStorage() {
    sessionStorage.setItem(
      'navigationHistory',
      JSON.stringify(navigationHistory)
    )
  }
}
