import React, { lazy } from 'react'
import { RouteObject } from 'react-router'

const ProjectPolicyForm = lazy(() => import('./ProjectPolicyForm'))

export const POLICY_PATH = {
  POLICY_FORM: '/project/:projectID/project-policy',
}

const projectPolicyRoutes: RouteObject[] = [
  {
    path: 'project-policy',
    element: <ProjectPolicyForm />,
  },
]

export default projectPolicyRoutes
