import { EmptyState } from '@ifca-ui/core'
import { PictureAsPdfSharp } from '@mui/icons-material'
import FileDownloadIcon from '@mui/icons-material/FileDownload'
import { Box, IconButton, ListItemIcon, Typography } from '@mui/material'
import { DialogForm } from 'components/Form/DialogForm'
import { CardListItem } from 'components/General/CardListItem'
import { saveAs } from 'file-saver'
import { useDownloadAttachmentMutation } from 'generated/graphql'
import { webShare } from 'helpers/webShare'
import { useState } from 'react'

export interface attachmentListProps {
  fileName: string
  resourceUrl: string
  mediaType: string
}
export interface DigitalDocumentDialog {
  listData: attachmentListProps[]
  openDialog: boolean
  setOpenDialog: any
  setReopenDialog?: any
}

export const AttachmentsListComponent = ({
  listData,
  openDialog,
  setOpenDialog,
  setReopenDialog,
}: DigitalDocumentDialog) => {
  const [downloadAttachments] = useDownloadAttachmentMutation({
    onCompleted: data => {
      if (data?.downloadAttachment) {
        let result = data?.downloadAttachment
        isDownload
          ? saveAs(result?.content, result?.fileName)
          : webShare(
              result?.fileName,
              '',
              '',
              result?.content,
              result?.fileName
            )
      }
    },
  })

  const [isDownload, setIsDownload] = useState<boolean>(false)

  const downloadAttachment = (attachmentUrl, attachmentName) => {
    downloadAttachments({
      variables: {
        input: {
          attachmentName: attachmentName,
          attachmentUrl: attachmentUrl,
        },
      },
    })
  }

  return (
    <>
      <DialogForm
        title={'Attachments'}
        dialogAction={openDialog}
        setOpenDialog={() => {
          setOpenDialog(false)
          if (setReopenDialog) {
            setReopenDialog(true)
          }
        }}
        body={
          <Box>
            {!listData || listData?.length === 0 ? (
              <EmptyState title="No Document Uploaded" />
            ) : (
              <>
                {listData?.map((el: attachmentListProps, index) => (
                  <CardListItem
                    body={
                      <>
                        <Typography
                          className="digital-document-item-style"
                          component="span"
                        >
                          <Typography
                            component="span"
                            className="text-icon text-xsTitle"
                            variant="inherit"
                          >
                            <ListItemIcon
                              onClick={() => {
                                setIsDownload(true)
                                downloadAttachment(
                                  el?.resourceUrl,
                                  el?.fileName
                                )
                              }}
                            >
                              {el?.mediaType === 'application/pdf' ? (
                                <PictureAsPdfSharp
                                  style={{
                                    color: 'red',
                                    width: '30px',
                                    height: '30px',
                                  }}
                                />
                              ) : (
                                <img
                                  className="item-img-style"
                                  src={el?.resourceUrl}
                                  alt={el?.fileName}
                                />
                              )}
                            </ListItemIcon>
                            &nbsp;
                          </Typography>
                          <Typography
                            className="text-icon text-xsTitle"
                            component="span"
                            variant="inherit"
                          >
                            {el.fileName}
                          </Typography>
                        </Typography>
                      </>
                    }
                    itemButton={
                      <>
                        <IconButton>
                          <FileDownloadIcon
                            onClick={() => {
                              setIsDownload(true)
                              downloadAttachment(el?.resourceUrl, el?.fileName)
                            }}
                          />
                        </IconButton>
                      </>
                    }
                  />
                ))}
              </>
            )}
          </Box>
        }
        footer={{
          buttons: [
            {
              children: 'Close',
              color: 'primary',
              onClick: () => {
                setOpenDialog(false)
                if (setReopenDialog) {
                  setReopenDialog(true)
                }
              },
            },
          ],
        }}
      />
    </>
  )
}
