import type * as React from 'react'
import { styled } from '@mui/material/styles'
import { Box, type BoxProps } from '@mui/material'
export const SubHeaderTextRoot = styled(Box)<SubheaderTextProps>`
  min-width: 0;
  margin: 0;
  flex: 1 1;
`
export interface SubheaderTextProps extends BoxProps {
  children?: React.ReactNode
  primary?: React.ReactNode
  secondary?: React.ReactNode
}
export const SubheaderText = ({
  children,
  primary,
  secondary,
  ...props
}: SubheaderTextProps): JSX.Element => {
  return (
    <SubHeaderTextRoot {...props}>
      {primary && <>{primary}</>}
      {secondary && <>{secondary}</>}
      {children}
    </SubHeaderTextRoot>
  )
}
