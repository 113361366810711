import React, { lazy } from 'react'
import { RouteObject } from 'react-router'

const RoleAndAccessSecurityListing = lazy(
  () => import('./RoleAuthorizationListing')
)
const RoleAuthForm = lazy(() => import('./RoleAuthorizationForm'))

export const ROLE_PATH = {
  ROLE_AUTH_LISTING: '/project/:projectID/project-role-authorisation',
  ROLE_AUTH_FORM:
    '/project/:projectID/project-role-authorisation/role-assignment/:roleID/form',
}

const roleAuthorizationRoutes: RouteObject[] = [
  {
    path: 'project-role-authorisation',
    children: [
      {
        path: '',
        element: <RoleAndAccessSecurityListing />,
      },
      {
        path: 'role-assignment/:roleID/form',
        element: <RoleAuthForm />,
      },
    ],
  },
]

export default roleAuthorizationRoutes
