import { lazy } from 'react'
import { RouteObject } from 'react-router'
const PAmiscBillingDetail = lazy(() => import('./PAmiscBillingDetail'))
const PAmiscBillingForm = lazy(() => import('./PAmiscBillingForm'))
const PAmiscBillingListing = lazy(() => import('./PAmiscBillingListing'))

//PA = purchaser's accounts
const PAmiscBillingRoutes: RouteObject[] = [
  {
    path: 'misc-billing',
    children: [
      {
        path: '',
        element: <PAmiscBillingListing tableRef="misc_billing" />,
      },
      {
        path: ':id/view',
        element: <PAmiscBillingDetail />,
      },
      {
        path: 'add',
        element: <PAmiscBillingForm mode="add" tableRef="misc_billing" />,
      },
      {
        path: ':id/edit',
        element: <PAmiscBillingForm mode="edit" tableRef="misc_billing" />,
      },
    ],
  },
]

export default PAmiscBillingRoutes
