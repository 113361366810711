import React, { lazy } from 'react'
import keyCollectionAppointmentRoutes from './KeyCollectionAppointment/KeyCollectionAppointmentRoutes'
import keyCollectionContractorRoutes from './KeyCollectionContractor/Routes'
import keyCollectionSettingRoutes from './KeyCollectionScheduleSetting/KeyCollectionScheduleSettingRoutes'

const KeyCollectionSubmenu = lazy(() => import('./KeyCollectionSubmenu'))

export const KEY_COLLECTION_PATH = {
  SUBMENU: '/:projectID/key-collection',
}

const KeyCollectionRoutes = [
  {
    path: 'key-collection',
    children: [
      {
        path: '',
        element: <KeyCollectionSubmenu />,
      },
      ...keyCollectionAppointmentRoutes,
      ...keyCollectionContractorRoutes,
      ...keyCollectionSettingRoutes,
    ],
  },
  // {
  //   props: { exact: true, path: KEY_COLLECTION_PATH.SUBMENU },
  //   component: <KeyCollectionSubmenu />,
  // },
  // ...keyCollectionAppointmentRoutes,
  // ...keyCollectionContractorRoutes,
  // ...keyCollectionSettingRoutes,
]

export default KeyCollectionRoutes
