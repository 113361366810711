enum JustifyContentEnum {
  CENTER = 'center',
  FLEX_END = 'flex-end',
  FLEX_START = 'flex-start',
  SPACE_AROUND = 'space-around',
  SPACE_BETWEEN = 'space-between',
  SPACE_EVENLY = 'space-evenly',
}

enum AlignItemsEnum {
  BASELINE = 'baseline',
  CENTER = 'center',
  END = 'end',
  FLEX_END = 'flex-end',
  FLEX_START = 'flex-start',
  NORMAL = 'normal',
  START = 'start',
  STRETCH = 'stretch',
}
interface IconXprops {
  icon: any
  size?: number
  color?: string
  backgroundColor?: string
  justifyContent?: keyof typeof JustifyContentEnum
  alignItems?: keyof typeof AlignItemsEnum
  onClick?: (event: React.MouseEvent<HTMLDivElement>) => void
  className?: string
}

const IconX = ({
  icon: IconComponent,
  size,
  color,
  backgroundColor,
  justifyContent,
  alignItems,
  onClick, // Include onClick in the component props
  className,
}: IconXprops) => {
  const iconStyle = {
    width: size,
    height: size,
    color: color,
    backgroundColor: backgroundColor,
  }

  const containerStyle = {
    display: 'flex',
    justifyContent: JustifyContentEnum[justifyContent || ''],
    alignItems: AlignItemsEnum[alignItems || ''],
  }

  return (
    <div style={containerStyle} onClick={onClick} className={className}>
      <IconComponent style={iconStyle} />
    </div>
  )
}

export default IconX
