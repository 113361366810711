import { lazy } from 'react'
import { RouteObject } from 'react-router'

const UnitTransferListing = lazy(() => import('./UnitTransferListing'))
const UnitTransferForm = lazy(() => import('./UnitTransferForm'))
const UnitTransferDetail = lazy(() => import('./UnitTransferDetail'))

const UnitTransferRoutes: RouteObject[] = [
  {
    path: 'unit-transfer',
    children: [
      {
        path: '',
        element: <UnitTransferListing />,
      },
      {
        path: 'add',
        element: <UnitTransferForm mode="add" />,
      },
      {
        path: ':id/edit',
        element: <UnitTransferForm mode="edit" />,
      },
      {
        path: ':id/detail',
        element: <UnitTransferDetail />,
      },
    ],
  },
]

export default UnitTransferRoutes
