import React, { lazy } from 'react'
import { RouteObject } from 'react-router-dom'

const CompanyListing = lazy(() => import('./CompanyListing'))
const CompanyDetail = lazy(() => import('./CompanyDetail'))
const CompanyProfile = lazy(() => import('./CompanyProfile'))
const CompanyForm = lazy(() => import('./CompanyForm'))
const BankProfileListing = lazy(
  () => import('./BankProfile/BankProfileListing')
)
const BankProfileForm = lazy(() => import('./BankProfile/BankProfileForm'))
const TownshipListing = lazy(() => import('./Township/TownshipListing'))
const NumberingStructureListing = lazy(
  () => import('./NumberingStructure/NumberingStructureListing')
)
const NumberingStructureForm = lazy(
  () => import('./NumberingStructure/NumberingStructureForm')
)

const companyRoutes: RouteObject[] = [
  {
    path: 'company',
    children: [
      {
        path: '',
        element: <CompanyListing />,
      },
      {
        path: 'company-form/add',
        element: <CompanyForm mode="add" />,
      },
      {
        path: 'company-form/:id/edit',
        element: <CompanyForm mode="edit" />,
      },
      {
        path: 'company-detail/:id',
        children: [
          {
            path: '',
            element: <CompanyDetail />,
          },
          {
            path: 'company-profile',
            element: <CompanyProfile />,
          },
          {
            path: 'company-township',
            element: <TownshipListing />,
          },
          {
            path: 'company-bank-profile',
            element: <BankProfileListing />,
          },
          {
            path: 'company-bank-profile/add',
            element: <BankProfileForm mode="add" />,
          },
          {
            path: 'company-bank-profile/:bankId/edit',
            element: <BankProfileForm mode="edit" />,
          },
          {
            path: 'company-numbering-structure',
            element: <NumberingStructureListing />,
          },
          {
            path: 'company-numbering-structure/:type',
            element: <NumberingStructureForm />,
          },
        ],
      },
    ],
  },
]

export default companyRoutes
