import { Box, Typography } from '@mui/material'
import type * as React from 'react'
import { styled } from '@mui/material/styles'

export const CopyRightLogo = styled('img')`
  height: 100%;
  width: 100%;
  max-width: 50px;
  max-height: 10px;
`
export interface CopyRightProps {
  /** Copy right product name */
  productTitle?: React.ReactNode
}
export const CopyRight: React.FC<CopyRightProps> = ({ productTitle }) => {
  return (
    <Box
      width="100%"
      position="fixed"
      bottom={0}
      right={0}
      color={'#212121'}
      p={'4px 12px'}
      boxSizing="border-box"
      bgcolor="#e5e5e5"
    >
      {/* <Box display="flex" mb="6px">
        <CopyRightLogo src={logo} alt="ifca-logo" loading="lazy" />
      </Box> */}
      <Box display="flex">
        <Box flex="1 1" fontSize="10px" lineHeight="16px" fontWeight={600}>
          <Typography
            component="span"
            className="text-desc"
            variant="inherit"
            marginRight="8px"
          >
            © Copyright 2023 -
          </Typography>

          {productTitle}
        </Box>
        <Typography
          component="span"
          className="text-desc"
          variant="inherit"
          display="flex"
          margin="auto"
        >
          All Rights Reserved.
        </Typography>
      </Box>
    </Box>
  )
}
