import React, { lazy } from 'react'
import { RouteObject } from 'react-router'

const CollectionList = lazy(() => import('./CollectionListing'))

const CollectionForm = lazy(() =>
  import('./CollectionForm').then(module => ({
    default: module.CollectionForm,
  }))
)

const CollectionEditForm = lazy(() =>
  import('./CollectionEditForm').then(module => ({
    default: module.CollectionEditForm,
  }))
)

const CollectionAllocationForm = lazy(() =>
  import('./CollectionAllocationForm').then(module => ({
    default: module.CollectionAllocationForm,
  }))
)

const CollectionDetail = lazy(() =>
  import('./CollectionDetail').then(module => ({
    default: module.CollectionDetail,
  }))
)

export const COLLECTION_PATH = {
  LISTING: '/collection',
  ADD: '/collection/add',
}

const CollectionRoutes: RouteObject[] = [
  {
    path: 'collection',
    children: [
      {
        path: '',
        element: <CollectionList />,
      },
      {
        path: 'add',
        element: <CollectionForm />,
      },
      {
        path: ':id',
        children: [
          {
            path: 'edit',
            element: <CollectionEditForm />,
          },
          {
            path: 'allocation',
            element: <CollectionAllocationForm />,
          },
          {
            path: 'detail',
            element: <CollectionDetail />,
          },
        ],
      },
    ],
  },
]

export default CollectionRoutes
