import { createTheme } from '@mui/material/styles'
import { ColorStyles, FontWeightClasses, Typography } from '../styles/variables'
import { palette } from './palette'
import { typography } from './typography'
/**
 * refer: https://material-ui.com/customization/theming/
 * Override material ui default styles
 * Palette
 * Typograhy
 * Spacing
 * Breakpoints
 * Density
 * Z-index
 */
export const Theme = createTheme({
  typography,
  palette,
  zIndex: {
    appBar: 1200,
    drawer: 1100,
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: {
        body: {
          margin: 0,
          background: '#f5f5f5',
          minHeight: '100%',
          fontFamily: 'Poppins, sans-serif',
          code: {
            fontFamily:
              'source-code-pro, Menlo, Monaco, Consolas, Courier New, monospace',
          },
          html: {
            background: '#f5f5f5',
            minHeight: '100%',
          },
          main: {
            width: 'calc(100% - 300px)',
          },
          u: {
            cursor: 'pointer',
          },
          '.text-icon': {
            display: 'flex',
            alignItems: 'center',
            overflow: 'hidden',
            span: {
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
            },
            'svg.MuiSvgIcon-root': {
              marginRight: '4px',
              fontSize: '14px',
              lineHeight: '16px',
            },
            svg: {
              marginRight: '4px',
              fontSize: '14px',
              lineHeight: '16px',
            },
          },
          ...ColorStyles,
          ...Typography,
          ...FontWeightClasses,
        },
      },
    },
    // Name of the component
    // MuiButton: {
    //   styleOverrides: {
    //     // Name of the slot
    //     root: {
    //       color: '#fffff',
    //     },
    //   },
    // },
  },
})

export const DefaultThemeOption = {
  typography,
  palette,
  zIndex: {
    appBar: 1200,
    drawer: 1100,
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: {
        body: {
          margin: 0,
          background: '#f5f5f5',
          minHeight: '100%',
          fontFamily: 'Poppins, sans-serif',
          code: {
            fontFamily:
              'source-code-pro, Menlo, Monaco, Consolas, Courier New, monospace',
          },
          html: {
            background: '#f5f5f5',
            minHeight: '100%',
          },
          main: {
            width: 'calc(100% - 300px)',
          },
          u: {
            cursor: 'pointer',
          },
          '.text-icon': {
            display: 'flex',
            alignItems: 'center',
            overflow: 'hidden',
            span: {
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
            },
            'svg.MuiSvgIcon-root': {
              marginRight: '4px',
              fontSize: '14px',
              lineHeight: '16px',
            },
            svg: {
              marginRight: '4px',
              fontSize: '14px',
              lineHeight: '16px',
            },
          },
          ...ColorStyles,
          ...Typography,
          ...FontWeightClasses,
        },
      },
    },
  },
}
