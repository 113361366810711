import moment from 'moment'

export class NricHelper {
  nric: string
  splitedNRIC: any | null
  age: number | null
  birthDate: Date | null
  gender: 'M' | 'F'
  valid: boolean

  constructor(nric: string) {
    this.splitedNRIC = this.splitNRIC(nric)
    if (this.splitedNRIC?.length && this.isValid()) {
      this.result()
    }
  }

  private result() {
    this.age = this.getAge()
    this.gender = this.getGender()
    this.nric = this.splitedNRIC[0]
    this.valid = this.isValid()
  }

  private getAge(): number {
    const year = new Date().getFullYear() % 100 //21
    const month = new Date().getMonth()
    const day = new Date().getDate()
    let age = year - this.splitedNRIC[2]
    const tempMonth = month - this.splitedNRIC[3] + 1
    const tempDays = day - this.splitedNRIC[4]

    this.birthDate = this.getBirthDate(age < 0 ? age + 100 : age)
    if (tempMonth < 0 || (tempMonth == 0 && tempDays < 0)) {
      age = age - 1
    }
    return age < 0 ? age + 100 : age
  }

  private getBirthDate(age: number): Date {
    let birhDate = new Date()
    birhDate.setFullYear(birhDate.getFullYear() - age)
    birhDate.setMonth(this.splitedNRIC[3] - 1)
    birhDate.setDate(this.splitedNRIC[4])
    return birhDate
  }

  private getGender(): 'M' | 'F' {
    return this.splitedNRIC
      ? Number(this.splitedNRIC[6] % 2) == 0
        ? 'F'
        : 'M'
      : null
  }

  private splitNRIC(nric: string) {
    const regex =
      '(([0-9]{2})(0[1-9]|1[0-2])(0[1-9]|[12][0-9]|3[01]))-([0-9]{2})-([0-9]{4})'
    this.splitedNRIC = nric.match(regex)
    return this.splitedNRIC
  }

  private isValid() {
    let nricYear = this.splitedNRIC[2]
    let nricMonth = this.splitedNRIC[3]
    let nricDay = this.splitedNRIC[4]
    let strDate = `${nricYear}-${nricMonth}-${nricDay}`
    return moment(strDate, 'YY-MM-DD').isValid()
  }
}
