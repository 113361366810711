import {
  DesktopHeader,
  Drawer,
  layoutClasses,
  LayoutRoot,
  ModuleSection,
  ProfileMenuItem,
  ProfileSection,
  RowFlex,
  Spinner,
} from '@ifca-ui/core'
import {
  PxBusinessInsight as BusinessInsightIcon,
  PxCommonSetting as CommonSettingIcon,
  PxEndFinancier as EndFinancierIcon,
  Home as HomeIcon,
  Logout as LogoutIcon,
  Profile as ProfileIcon,
  // PxHome as HomeIcon,
  // PxLogout as LogoutIcon,
  PxProjectSetup as ProjectSetupIcon,
  PxSalesAdmin as SalesAdminIcon,
  PxSalesCrm as SalesCrmIcon,
  PxSolicitor as SolicitorIcon,
  AccessSecurity as SystemAdminIcon,
} from '@ifca-ui/icons/dist/index.esm'
import {
  Box,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Menu,
  Typography,
  useMediaQuery,
} from '@mui/material'
//import { ListItem } from '@ifca-ui/core'
import { useTheme } from '@mui/material/styles'
import HeaderBg from 'assets/images/header/header-bg.svg'
import SideMenuBg from 'assets/images/product/sidemenu-background.svg'
import clsx from 'clsx'
import HeaderX from 'components/HeaderX/HeaderX'
import useNav from 'components/RouteService/useNav'
import ServiceWrokerWrapper from 'components/ServiceWorker/ServiceWrokerWrapper'
import AppContext, { AppContextProps } from 'containers/context/Context'
import { ACCESS_SECURITY_PATH } from 'containers/modules/AccessSecurity/Routes'
import { ACCT_PATH } from 'containers/modules/Account/Routes'
import { ACCOUNT_JOURNAL_SUBMENU_PATH } from 'containers/modules/AccountJournal/Routes'
import { COMMON_SETTING_PATH } from 'containers/modules/CommonSetting/Routes'
import { HOME_PATH } from 'containers/modules/Home/Routes'
import { PROJECT_PATH } from 'containers/modules/Project/Routes'
import {
  useAccountNameQuery,
  useGetAppAccessTokenLazyQuery,
  useProfileLazyQuery,
} from 'generated/graphql'
import useSocket from 'helpers/Hooks/useSocket'
import { navigationService } from 'navigationService'
import React, {
  FC,
  MouseEvent,
  ReactNode,
  Suspense,
  useContext,
  useEffect,
  useState,
} from 'react'
import { NavLink, Outlet, useLocation, useNavigate } from 'react-router-dom'
import {
  getUserProfile,
  setUserProfile,
  updateUserProfileObservable,
  UserProfile,
} from 'UserData'
import { version as appVersion } from '../../../package.json'
import { navigateReactUrl } from './windowOpen'

interface Modules {
  name?: string
  path?: string
  url?: string
  icon?: any
  iconActived?: any
  application?: string | null
}
interface MainLayoutProps {
  children?: ReactNode
  window?: () => Window
}
const MainLayout: FC<MainLayoutProps> = ({ children, window }) => {
  const navigate = useNavigate()
  const { navBack, navTo, navMainTo } = useNav()
  const projectID = localStorage.getItem('latestProject')
  const headerBgURL = HeaderBg
  const { pathname } = useLocation()
  const { rootState, rootDispatch } = useContext<AppContextProps>(AppContext)
  const { socketDisconnection } = useSocket()

  const {
    loading: accountLoading,
    data: { getAccountName } = { getAccountName: '' },
  } = useAccountNameQuery({})

  const [
    getProfile,
    {
      loading: profileLoading,
      data: { profile } = {
        profile: null,
      },
    },
  ] = useProfileLazyQuery({})

  useEffect(() => {
    updateUserProfileObservable().subscribe(x => {
      if (x) {
        getProfile()
      }
    })
    getProfile()
  }, [])

  // USER INFO
  useEffect(() => {
    if (profile && getAccountName) {
      let userAccDetail: UserProfile = {
        subscriptionName: getAccountName,
        ID: profile?.ID,
        contactNo: profile?.contactNo,
        email: profile?.email,
        name: profile?.name,
        superUser: profile?.superUser,
      }
      setUserProfile(userAccDetail)
    }
  }, [profile, getAccountName])

  const nav = new navigationService()

  const ModulesList: Modules[] = [
    {
      name: 'Home',
      path: HOME_PATH.HOME,
      icon: <HomeIcon className="svg-icon" />,
      iconActived: <HomeIcon className="svg-icon" />,
    },
    {
      name: 'Sales CRM',
      url: import.meta.env.VITE_SALES_URL,
      icon: <SalesCrmIcon className="svg-icon" />,
      iconActived: <SalesCrmIcon className="svg-icon" />,
      application: 'Sales',
      // ACLIndex: 0,
      // perm: HotelPermission.BookingIgnoreView,
    },
    {
      name: 'Digital reports',
      url: import.meta.env.VITE_REPORT_URL,
      icon: <SalesCrmIcon className="svg-icon" />,
      iconActived: <SalesCrmIcon className="svg-icon" />,
      application: 'Report Designer',
      // ACLIndex: 0,
      // perm: HotelPermission.BookingIgnoreView,
    },
    {
      name: 'Project Business Insight',
      path: `/business-insight/${projectID}`,
      // reroute: false,
      icon: <BusinessInsightIcon className="svg-icon" />,
      iconActived: <BusinessInsightIcon className="svg-icon" />,
      // ACLIndex: 0,
      // perm: HotelPermission.BookingIgnoreView,
    },
    {
      name: 'Property Sales Admin',
      path: `/${projectID}`,
      icon: <SalesAdminIcon className="svg-icon" />,
      iconActived: <SalesAdminIcon className="svg-icon" />,
      // ACLIndex: 0,
      // perm: HotelPermission.BookingIgnoreView,
    },
    {
      name: 'End Financier',
      path: '/end-financier',
      icon: <EndFinancierIcon className="svg-icon" />,
      iconActived: <EndFinancierIcon className="svg-icon" />,
      // ACLIndex: 0,
      // perm: HotelPermission.BookingIgnoreView,
    },
    {
      name: 'Solicitor',
      path: `/solicitor`,
      icon: <SolicitorIcon className="svg-icon" />,
      iconActived: <SolicitorIcon className="svg-icon" />,
      // ACLIndex: 0,
      // perm: HotelPermission.BookingIgnoreView,
    },
    {
      name: 'Contractor',
      path: `/contractor`,
      icon: <SolicitorIcon className="svg-icon" />,
      iconActived: <SolicitorIcon className="svg-icon" />,
      // ACLIndex: 0,
      // perm: HotelPermission.BookingIgnoreView,
    },
    {
      name: 'Common Setting',
      path: COMMON_SETTING_PATH.COMMON_SETTING_SUBMENU,
      icon: <CommonSettingIcon className="svg-icon" />,
      iconActived: <CommonSettingIcon className="svg-icon" />,
      // ACLIndex: 0,
      // perm: HotelPermission.BookingIgnoreView,
    },
    {
      name: 'Project Setup',
      path: PROJECT_PATH.PROJECT_LISTING,
      icon: <ProjectSetupIcon className="svg-icon" />,
      iconActived: <ProjectSetupIcon className="svg-icon" />,
      // ACLIndex: 0,
      // perm: HotelPermission.BookingIgnoreView,
    },
    {
      name: 'Access Security',
      path: ACCESS_SECURITY_PATH.ROOT,
      icon: <SystemAdminIcon className="svg-icon" />,
      iconActived: <SystemAdminIcon className="svg-icon" />,
      // ACLIndex: 0,
      // perm: HotelPermission.BookingIgnoreView,
    },
    {
      name: 'Account Journal',
      path: ACCOUNT_JOURNAL_SUBMENU_PATH.SUBMENU,
      icon: <SystemAdminIcon className="svg-icon" />,
      iconActived: <SystemAdminIcon className="svg-icon" />,
    },
    {
      name: 'Log Out',
      path: '/logout',
      icon: <LogoutIcon className="svg-icon" />,
      iconActived: <LogoutIcon className="svg-icon" />,
      // ACLIndex: 0,
      // perm: HotelPermission.BookingIgnoreView,
    },
    // {
    //   name: 'Group Business Insight',
    //   path: '/business-insight',
    //   reroute: false,
    //   reroutePath: '',
    //   icon: <BusinessInsightIcon className="svg-icon" />,
    //   iconActived: <BusinessInsightIcon className="svg-icon" />,
    //   // icon: BusinessInsight,
    //   // iconActived: ProjectSetupActive,
    //   // ACLIndex: 0,
    //   // perm: HotelPermission.BookingIgnoreView,
    // },
  ]
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const handleClick = (event: MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }
  const theme = useTheme()
  const container =
    window !== undefined ? () => window().document.body : undefined
  const isDesktop = useMediaQuery(theme.breakpoints.up('sm'), {
    defaultMatches: true,
  })
  const profileMenu = (
    <Menu
      id="simple-menu"
      anchorEl={anchorEl}
      keepMounted
      open={Boolean(anchorEl)}
      onClose={handleClose}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'left',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
    >
      <ProfileMenuItem
        onClick={() => {
          if (!isDesktop) {
            handleDrawerOpen()
          }
          handleClose()
          navTo(ACCT_PATH.PROFILE, null, null, true)
        }}
      >
        <ProfileIcon width="18px" height="18px" />
        Profile
      </ProfileMenuItem>
      <ProfileMenuItem onClick={() => clickLogout()}>
        <LogoutIcon width="18px" height="18px" />
        Logout
      </ProfileMenuItem>
    </Menu>
  )
  const handleDrawerOpen = () =>
    rootDispatch({ type: 'isDrawerOpen', payload: !rootState.isDrawerOpen })
  React.useEffect(() => {
    if (isDesktop && !rootState.isDrawerOpen) {
      document?.getElementById('main-header')?.classList.add('full-width')
      document
        ?.getElementById('subheader-container')
        ?.classList.add('full-width')
      document?.getElementById('footer')?.classList.add('full-width')
    } else {
      document?.getElementById('main-header')?.classList.remove('full-width')
      document
        ?.getElementById('subheader-container')
        ?.classList.remove('full-width')
      document?.getElementById('footer')?.classList.remove('full-width')
    }
  }, [rootState.isDrawerOpen, isDesktop])

  function handle(ob: Modules) {
    if (!isDesktop) {
      handleDrawerOpen()
    }

    if (ob.name === 'Home') {
      nav.backHome()
    }
    if (ob.name === 'Log Out') {
      //reset()
      sessionStorage.clear()
      nav.clearArray()
      socketDisconnection()
    }

    const subArray = [
      'Solicitor',
      'Contractor',
      'End Financier',
      'Project Setup',
      'Access Security',
    ]
    if (subArray.includes(ob.name)) {
      nav.clickSideBar(ob.name)
    }

    if (ob?.url) {
      if (ob?.application) {
        getAppAccessToken({
          variables: {
            appName: ob.application,
          },
        }).then(a => {
          navigateReactUrl(ob.url + '?q=' + a.data?.getAppAccessToken, '')
        })
      } else {
        navigateReactUrl(ob.url, '')
      }
    } else {
      navMainTo(ob.path)
    }
  }

  const [
    getAppAccessToken,
    {
      data: { getAppAccessToken: string } = {
        getAppAccessToken: null,
      },
    },
  ] = useGetAppAccessTokenLazyQuery({})

  function clickLogout() {
    navTo('/logout')
    sessionStorage.clear()
    nav.clearArray()
    //reset()
  }

  return (
    <>
      <ServiceWrokerWrapper />
      {profile && getAccountName && (
        <LayoutRoot
          component="div"
          className={layoutClasses.root}
          display="flex"
        >
          {isDesktop ? (
            <DesktopHeader
              title={getAccountName}
              leftIcon={{
                props: {
                  onClick: () => handleDrawerOpen(),
                },
              }}
            />
          ) : null}

          <HeaderX isDesktop={isDesktop} />
          <Drawer
            container={container}
            onClose={handleDrawerOpen}
            open={isDesktop ? rootState.isDrawerOpen : !rootState.isDrawerOpen}
            variant={isDesktop ? 'persistent' : 'temporary'}
          >
            <ProfileSection
              drawerImage={SideMenuBg}
              avatar={{
                src: `${profile?.avatar}`,
                // 'https://scontent.fkul8-1.fna.fbcdn.net/v/t1.6435-9/82939221_2922930267717357_668748647327858688_n.jpg?_nc_cat=109&ccb=1-5&_nc_sid=09cbfe&_nc_ohc=vj2htXI6WtoAX-3uAoA&_nc_ht=scontent.fkul8-1.fna&oh=42de33a80efda1ba65cfa21bc580c373&oe=617F4CC6',
              }}
              profile={{
                title: `${getUserProfile()?.name}`,
                subTitle: `${getUserProfile()?.email}`,
              }}
              dropDownIcon={{
                onClick: e => handleClick(e),
              }}
            />
            <ModuleSection as="div">
              <List disablePadding>
                {ModulesList.map((module, index) => {
                  return (
                    <ListItem
                      id={module.name}
                      key={index}
                      dense
                      divider
                      style={{
                        color: pathname === module.path ? 'inherit' : 'inherit'
                      }}
                      component={module?.path ? NavLink : null}
                      to={module?.path ?? null}
                      onClick={e => {
                        handle(module)
                      }}
                      
                    >
                      <ListItemIcon>
                        {pathname !== module.path && module.icon}
                        {pathname === module.path && module.iconActived}
                      </ListItemIcon>
                      <ListItemText
                        primary={module.name}
                        primaryTypographyProps={{
                          variant: 'inherit',
                          style: { color: 'inherit' }
                        }}
                        
                      />
                    </ListItem>
                  )
                })}
              </List>
              <RowFlex fullWidth mainAxisAlignment="center">
                <Typography component="span" className="text-desc">
                  v{appVersion}
                </Typography>
              </RowFlex>
            </ModuleSection>
          </Drawer>
          {profileMenu}
          <Box
            component="main"
            className={clsx(layoutClasses.mainContent, {
              [layoutClasses.contentShift]: rootState.isDrawerOpen,
            })}
          >
            <Suspense fallback={<Spinner />}>
              <div style={{ height: `${rootState.headerHeight}px` }} />
              <Outlet />
            </Suspense>
          </Box>
        </LayoutRoot>
      )}
    </>
  )
}
export default MainLayout
