import { lazy } from 'react'
import { RouteObject } from 'react-router-dom'

const ToPM_Page = lazy(() => import('./ToPM_Page'))
const ToPM_Routes: RouteObject[] = [
  {
    path: 'ToPM',
    children: [
      {
        path: '',
        element: <ToPM_Page />,
      },
    ],
  },
]

export default ToPM_Routes
