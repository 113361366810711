import React, { lazy } from 'react'
import { RouteObject } from 'react-router'

const AreaVariancePolicyListing = lazy(
  () => import('./AreaVariancePolicyListing')
)
const AreaVariancePolicyForm = lazy(() => import('./AreaVariancePolicyForm'))

const AreaVariancePolicyRoutes: RouteObject[] = [
  {
    path: 'area-variance-policy',
    children: [
      {
        path: '',
        element: <AreaVariancePolicyListing />,
      },
      {
        path: 'add',
        element: <AreaVariancePolicyForm mode="add" />,
      },
      {
        path: 'edit',
        element: <AreaVariancePolicyForm mode="edit" />,
      },
    ],
  },
]

export default AreaVariancePolicyRoutes
