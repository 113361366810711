import React, { lazy } from 'react'
import { RouteObject } from 'react-router'

const RefinancingListing = lazy(() => import('./RefinancingListing'))
const RefinancingForm = lazy(() => import('./RefinancingForm'))
const RefinancingEditForm = lazy(() => import('./RefinancingEditForm'))
const RefinancingDetail = lazy(() => import('./RefinancingDetail'))
const RefinancingEndFinDetail = lazy(() => import('./RefinancingEndFinDetail'))
const RefinancingEndFinForm = lazy(() => import('./RefinancingEndFinForm'))

export const REFINANCING_PATH = {
  LISTING: '/:projectID/bank-loan/refinancing',
  DETAIL: '/:projectID/bank-loan/refinancing/detail/:id',
  FORM_ADD: '/:projectID/bank-loan/refinancing/add',
  ENDFIN_FORM_ADD: '/:projectID/bank-loan/refinancing/end-financier/add',
}

const RefinancingRoutes: RouteObject[] = [
  {
    path: 'refinancing',
    children: [
      {
        path: '',
        element: <RefinancingListing />,
      },
      {
        path: 'detail',
        children: [
          {
            path: ':id',
            element: <RefinancingDetail />,
          },
          {
            path: ':id/detail/:endFinId',
            element: <RefinancingEndFinDetail />,
          },
        ],
      },
      {
        path: 'add',
        children: [
          {
            path: '',
            element: <RefinancingForm />,
          },
          {
            path: 'end-financier/:mode',
            element: <RefinancingEndFinForm />,
          },
        ],
      },

      {
        path: 'edit/:id',
        children: [
          {
            path: '',
            element: <RefinancingEditForm />,
          },
          {
            path: 'end-financier/:mode',
            element: <RefinancingEndFinForm />,
          },
        ],
      },
    ],
  },
]

export default RefinancingRoutes
