import { TextField } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { Controller } from 'react-hook-form'

interface props {
  control: any
  errors?: any
  isRequired?: boolean
  label: string
  name: string
  defaultValue?: any
  margin?: 'normal' | 'dense' | 'none'
  onChange?: React.ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement>
  onBlur?: React.FocusEventHandler<HTMLInputElement | HTMLTextAreaElement>
}

export const TextFormField = ({
  control,
  errors,
  isRequired = true,
  label,
  name,
  defaultValue,
  margin = 'normal',
  onChange: externalOnChange = () => {},
  onBlur: externalOnBlur = () => {},
}: props) => {
  const [data, setData] = useState(null)
  useEffect(() => {
    if (defaultValue) {
      setData(defaultValue)
    }
  }, [defaultValue])

  return (
    <Controller
      control={control}
      key={name}
      name={name}
      defaultValue={data}
      render={({ field: { onChange, onBlur, value } }) => (
        <TextField
          autoComplete="off"
          error={errors?.[name] ? true : false}
          fullWidth
          helperText={errors?.[name]?.message}
          label={`${label} ${isRequired ? '*' : ''}`}
          margin={margin}
          name={name}
          onBlur={event => {
            onBlur()
            externalOnBlur(event)
          }}
          onChange={event => {
            onChange(event)
            externalOnChange(event)
          }}
          value={value}
          variant="standard"
        />
      )}
    />
  )
}
