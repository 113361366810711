import { lazy } from 'react'
import { RouteObject } from 'react-router'
const PAcreditAllocationPage = lazy(() => import('./PAcreditAllocationPage'))
const PAcreditNoteForm = lazy(() => import('./PAcreditNoteForm'))
const PAcreditNoteListingPage = lazy(() => import('./PAcreditNoteListingPage'))
const PAcreditNoteDetail = lazy(() => import('./PAcreditNoteDetail'))

//PA = purchaser's accounts
const PACreditNoteRoutes: RouteObject[] = [
  {
    path: 'credit-note',
    children: [
      {
        path: '',
        element: <PAcreditNoteListingPage />,
      },
      {
        path: ':id/view',
        element: <PAcreditNoteDetail />,
      },
      {
        path: 'add',
        element: <PAcreditNoteForm mode="add" />,
      },
      {
        path: ':id/edit',
        element: <PAcreditNoteForm mode="edit" />,
      },
      {
        path: ':id/allocation',
        element: (
          <PAcreditAllocationPage tableRef="CREDIT_NOTE" path="credit-note" />
        ),
      },
    ],
  },
]

export default PACreditNoteRoutes
