let accessToken = ''

export const setAccessToken = (s: string) => {
  accessToken = s
  sessionStorage.setItem('tokenKey', s)
}

export const getAccessToken = () => {
  return sessionStorage.getItem('tokenKey')
}
