import { yupResolver } from '@hookform/resolvers/yup'
import {
  Card,
  Container,
  FileAttachment,
  FilePreviewCard,
  FilePreviewContainer,
  Footer,
  SubContainerX,
  Subheader,
  SubheaderText,
  useUploadAttachment,
} from '@ifca-ui/core'
import {
  Close,
  Email,
  HouseOutlined,
  Mic,
  Person,
  Phone,
  PictureAsPdfSharp,
  Stop,
} from '@mui/icons-material'
import { Avatar, Box, Grid, TextField, Typography } from '@mui/material'
import { MobileTimePicker } from '@mui/x-date-pickers'
import TextX from 'components/General/TextX'
import TooltipX from 'components/General/TooltipX'
import { TopSectionHeader } from 'components/Header/HeaderSection'
import { VoiceTextField } from 'components/Inputs/CustomTextfield'
import { DatePickerX } from 'components/Inputs/DatePickerX'
import { MultiPurposeFileUpload } from 'components/MultiPurposeFileUpload/MultiPurposeFileUpload'
import useNav from 'components/RouteService/useNav'
import AppContext, { AppContextProps } from 'containers/context/Context'
import {
  useAddPaCollectionActivityMutation,
  useEditPaCollectionActivityMutation,
  useGetCollectionActivityDetailByIdLazyQuery,
  useGetCollectionActivitySummaryLazyQuery,
  useProjectDetailQuery,
} from 'generated/graphql'
import { SystemMsgs } from 'helpers/Messages/SystemMsg'
import { formatTime } from 'helpers/StringNumberFunction/formatDate'
import { setSnackBar } from 'helpers/snackBarSubjectHelper'
import { CommonYupValidation } from 'helpers/yup'
import React, { useEffect, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { ReactMic } from 'react-mic'
import { useNavigate, useParams } from 'react-router'
import * as yup from 'yup'

export interface CollectionActivityProps {
  actionDate: Date
  actionTime: Date
  remark: string
  activityStatus: string
  // fileUpload: FileUpload
}

export interface CollectionObject {
  activityDate: string
  activityRemark: string
  activityStatus: string
}

interface Props {
  src?: string
  onClick: any
  remove?: any
  mediaType?: any
  diffClass?: boolean
}

const PACollectionActivityForm = (props: any) => {
  const { rootState, rootDispatch } =
    React.useContext<AppContextProps>(AppContext)

  const { mode, pageMode } = props
  let navigate = useNavigate()
  const { navBack, navTo } = useNav()
  const { projectID, saleID, id }: any = useParams()
  const [unitNo, setUnitNo] = useState(null)
  const [purchaserName, setPurchaserName] = useState(null)
  const [phoneNumber, setPhoneNumber] = useState(null)
  const [companyName, setCompanyName] = useState(null)
  const [isCompany, setIsCompany] = useState(false)
  const [officeTel, setOfficeTel] = useState(null)
  const [email, setEmail] = useState(null)
  const [outstanding, setOutstanding] = useState(null)
  const CollectionSchema = yup.object().shape({
    remark: CommonYupValidation.requireField(SystemMsgs.remark()),
    actionDate: CommonYupValidation.requireDate('Action Date is required'),
  })
  const [type, setType] = useState('')
  const [audioData, setAudioData] = useState(null)
  const [testShow, setShow] = useState(false)
  const [attachment, setAttachment] = useState(null)

  //#region Upload Function///////////////////////////////////////////////////////////////////////////
  const {
    files,
    handleUploadChange,
    handleRemove,
    previewFiles,
    setFiles,
    getExistingFileRemovedAttachmentIDs,
    setExistingFile,
    uploadedFiles,
    handleUploadManually,
  } = useUploadAttachment()

  const handleRecording = () => {
    setRecord(!record)
  }

  const handleEditUpload = async (file: any) => {
    const existingFile: FileAttachment[] = []
    const prevFile = []
    //start (new)
    const newFiles = await Promise.all(
      file.map(async (x, i) => {
        const data = await fetch(x.resourceUrl, { mode: 'no-cors' })
          .then(res => {
            return res.blob()
          })
          .then(file => {
            return new File([file], x.fileName, { type: x?.mediaType })
          })
        let temp: FileAttachment = {
          File: data,
          AttachmentID: x.id,
          ResourceUrl: x.resourceUrl,
        }
        prevFile.push(data)
        existingFile.push(temp)
      })
    )
    //end
    setExistingFile([...existingFile])
  }

  //#endregion //////////////////////////////////////////////////////////////////////////////////////////

  //#region voice recording
  const [voiceDescription, setVoiceDescription] = useState('')
  const [record, setRecord] = useState<boolean>(false)
  const [recordDescription, setRecordDescription] = useState<boolean>(false)

  const onStop = recordedBlob => {
    setShow(false)
    setRecord(false)
    setAudioData(recordedBlob)
  }
  //#endregion

  useEffect(() => {
    if (audioData) {
      let newFile = new File([audioData?.blob], 'recording', {
        type: 'audio/ogg; codecs=opus',
      } as FilePropertyBag)
      handleUploadManually(newFile)
    }
  }, [audioData])
  //#region yup region //////////////////////////////////////////////////////////////////////////////////

  const {
    handleSubmit,
    control,
    setValue,
    formState: { errors },
  } = useForm<CollectionActivityProps>({
    mode: 'onSubmit',
    defaultValues: {
      actionDate: new Date(),
      actionTime: new Date(),
      remark: '',
      activityStatus: 'NEW',
    },
    resolver: yupResolver(CollectionSchema),
  })

  //#endregion ///////////////////////////////////////////////////////////////////////////////////////////

  //#region GraphQL /////////////////////////////////////////////////////////////////////////////////////

  //Query Project Detail
  const {
    loading: curProjectLoading,
    error: curProjectError,
    data: { projectDetail } = { projectDetail: null },
  } = useProjectDetailQuery({
    variables: {
      id: projectID,
    },
  })

  //Add Mutation //
  const [addPACollectionActivity] = useAddPaCollectionActivityMutation({
    onCompleted: data => {
      if (data.addPACollectionActivity) {
        showSnackbar(SystemMsgs.createNewRecord())
        navBack()
      }
    },
  })

  //Edit Mutation //
  const [editPACollectionActivity, { loading: editLoading }] =
    useEditPaCollectionActivityMutation({
      onError: error => {
        return error.graphQLErrors.map(({ message }) => {
          rootDispatch({
            type: 'snackBar',
            payload: {
              open: true,
              message: message,
            },
          })
        })
      },
      onCompleted: data => {
        if (data.editPACollectionActivity) {
          showSnackbar(SystemMsgs.updateRecord())
          setTimeout(() => {
            navBack()
          }, 500)
        }
      },
    })

  //Lazy Query for default values in form //
  const [
    getCollectionActivity,
    {
      loading: collectionActivityLoading,
      data: { getCollectionActivityDetailByID } = {
        getCollectionActivityDetailByID: null,
      },
    },
  ] = useGetCollectionActivityDetailByIdLazyQuery({
    onCompleted: data => {
      setValue('actionDate', data.getCollectionActivityDetailByID?.activityDate)
      setValue('actionTime', data.getCollectionActivityDetailByID?.activityDate)
      setValue('remark', data.getCollectionActivityDetailByID?.activityRemark)
      handleEditUpload(data.getCollectionActivityDetailByID.attachment)
      setAttachment(data.getCollectionActivityDetailByID.attachment)
    },
    onError: error => {
      return error.graphQLErrors.map(({ message }) => {
        rootDispatch({
          type: 'snackBar',
          payload: {
            open: true,
            message: message,
          },
        })
      })
    },
  })

  useEffect(() => {
    if (mode === 'edit') {
      getCollectionActivity({
        variables: {
          id: id,
        },
      })
    }
  }, [mode])

  //Lazy Query for sub header information //
  const [
    GetCollectionActivitySummary,
    {
      loading: getCollectionActivitySummaryLoading,
      data: { getCollectionActivitySummary } = {
        getCollectionActivitySummary: null,
      },
    },
  ] = useGetCollectionActivitySummaryLazyQuery({
    onCompleted: data => {
      setUnitNo(data.getCollectionActivitySummary?.purchaserDetail?.unitNo)
      setPurchaserName(
        data.getCollectionActivitySummary?.purchaserDetail?.purchaserName
      )
      setPhoneNumber(
        data?.getCollectionActivitySummary?.purchaserDetail?.phoneNo
      )
      setCompanyName(
        data.getCollectionActivitySummary?.purchaserDetail?.companyName
      )
      setIsCompany(
        data.getCollectionActivitySummary?.purchaserDetail?.isCompany
      )
      setOfficeTel(
        data.getCollectionActivitySummary?.purchaserDetail?.officeTel
      )
      setEmail(data?.getCollectionActivitySummary?.purchaserDetail?.email)
      setOutstanding(data?.getCollectionActivitySummary?.totalOutstanding)
    },
    onError: error => {},
  })

  useEffect(() => {
    GetCollectionActivitySummary({
      variables: {
        saleId: saleID,
      },
    })
  }, [])

  //Snackbar message
  const showSnackbar = message => {
    setSnackBar(message)
  }

  //#endregion ///////////////////////////////////////////////////////////////////////////////////////

  //#region add/edit collection activity form ////////////////////////////////////////////////////////
  const onSubmit = (data: CollectionActivityProps) => {
    //#region Function where combine seperate input of both time and date into one date string
    const actionDate = new Date(data.actionDate)
    actionDate.setHours(0, 0, 0, 0)
    let stringDate: string = actionDate.toString()
    const actionTime = new Date(data.actionTime)
    let stringTime: string = actionTime.toISOString()
    const time = formatTime(stringTime)

    const t1: any = time.split(' ')
    const t2: any = t1[0].split(':')
    t2[0] = t1[1] === 'PM' ? 1 * t2[0] + 12 : t2[0]
    const time24 = (t2[0] < 10 ? '0' + t2[0] : t2[0]) + ':' + t2[1]
    const completeDate = stringDate.replace('00:00', time24.toString())
    //#endregion

    const submitData = {
      attachments: uploadedFiles,
      activityDate: completeDate,
      saleId: saleID,
      activityRemark: data.remark,
    }

    mode === 'add'
      ? addPACollectionActivity({
          variables: {
            input: {
              ...submitData,
              activityStatus: data.activityStatus,
            },
          },
        })
      : editPACollectionActivity({
          variables: {
            input: {
              ...submitData,
              deleteAttachments: getExistingFileRemovedAttachmentIDs(),
              id: id,
            },
          },
        })
  }

  //#endregion ////////////////////////////////////////////////////////////////////////////////////

  //#region header/subheader /////////////////////////////////////////////////////////////////////
  useEffect(() => {
    rootDispatch({
      type: 'headerComponent',
      payload: {
        ...rootState.headerComponent,
        leftIcon: {
          icon: 'close',
          props: {
            onClick: () => navBack(),
          },
        },
        rightIcon: {
          // icon: 'switch',
        },
        topSection: {
          smTitle: TopSectionHeader,
          title: `${projectDetail?.name ?? '-'}`,
        },
        bottomSection: {
          breadcrumbs: {
            maxItems: 4,
            current: 'Collection Activity',
          },
          rightText: mode === 'add' ? 'New' : 'Edit',
        },
      },
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [projectDetail])

  //#endregion

  return (
    <>
      <SubContainerX>
        <Subheader>
          <SubheaderText
            primary={
              <Box borderBottom="1px solid #fff">
                <Typography
                  component="div"
                  display="flex"
                  className="text-xsTitle c-orange"
                >
                  <Typography
                    component="u"
                    flex="1 1"
                    display="flex"
                    justifyContent={'unset'}
                    variant="inherit"
                    className="text-icon "
                  >
                    <HouseOutlined />
                    {unitNo}
                  </Typography>

                  <TooltipX title="Outstanding Amount" placement="left">
                    <TextX
                      data={outstanding}
                      dataType={'Number'}
                      className="text-icon"
                      color="red"
                    />
                  </TooltipX>
                </Typography>
              </Box>
            }
            secondary={
              <Box>
                <Typography
                  component="div"
                  display="flex"
                  className="text-xsTitle"
                >
                  <Typography
                    component="div"
                    flex="1 1"
                    display="flex"
                    justifyContent={'unset'}
                    variant="inherit"
                    className="text-icon "
                  >
                    <Person />
                    {!!!isCompany
                      ? purchaserName
                      : `${companyName} (${purchaserName})`}
                  </Typography>
                </Typography>
                <Typography
                  component="div"
                  display="flex"
                  className="text-xsTitle"
                >
                  <Typography
                    component="span"
                    display="flex"
                    justifyContent={'unset'}
                    variant="inherit"
                    className="text-icon"
                  >
                    <Email />
                    {email}
                  </Typography>
                  <Typography
                    component="span"
                    // flex="1 1"
                    display="flex"
                    justifyContent={'unset'}
                    variant="inherit"
                    className="text-icon"
                  >
                    {!!!isCompany ? (
                      <>
                        &nbsp;|&nbsp; <Phone />
                        {phoneNumber}
                      </>
                    ) : (
                      !!officeTel && (
                        <>
                          &nbsp;|&nbsp; <Phone />
                          {officeTel}
                        </>
                      )
                    )}
                  </Typography>
                </Typography>
              </Box>
            }
          />
        </Subheader>
      </SubContainerX>

      <form onSubmit={handleSubmit(onSubmit)} id="submit-form">
        <Container>
          <Card>
            <Box width="100%" display="flex">
              <Box width="50%" marginBottom="14px" pr="6px">
                <DatePickerX
                  control={control}
                  name="actionDate"
                  label="Action Date"
                  required
                  helperText={errors?.actionDate?.message}
                  error={!!errors?.actionDate}
                />
              </Box>
              <Box width="50%" marginBottom="14px" pr="6px">
                <Controller
                  control={control}
                  name="actionTime"
                  render={({ field: { onChange, onBlur, value } }) => (
                    <MobileTimePicker
                      label="Action Time"
                      onChange={newValue => onChange(newValue)}
                      value={value}
                      inputFormat="HH:mm"
                      renderInput={params => (
                        <TextField
                          {...params}
                          margin="normal"
                          required
                          fullWidth
                          variant="standard"
                          value={value}
                        />
                      )}
                    />
                  )}
                />
              </Box>
            </Box>
            <VoiceTextField
              id="voicetextfield"
              mounted={true}
              label="Remarks"
              name="remark"
              required
              value={voiceDescription}
              setValue={setValue}
              record={recordDescription}
              setRecord={setRecordDescription}
              controllerProps={{
                error: !!errors?.remark,
                helperText: errors?.remark?.message,
                control: control,
                autoComplete: 'off',
              }}
            />
            <Box
              justifyContent="center"
              style={{
                paddingTop: '30px',
                paddingBottom: '20px',
                width: '100%',
              }}
            >
              <MultiPurposeFileUpload
                handleUploadFile={handleUploadChange}
                setMode={setType}
                excludeText
                stateCondition={type => {
                  if (type === 'audio') {
                    setShow(true)
                    setRecord(true)
                  } else {
                    setShow(false)
                    setRecord(false)
                  }
                }}
                inputField={
                  <>
                    <div className="m-b-8 multipurpose-content">
                      <div hidden={!testShow}>
                        <Grid container className="recorder">
                          <Grid xs={10}>
                            <ReactMic
                              record={record}
                              visualSetting="sinewave"
                              className="recorder-box"
                              onStop={onStop}
                              // onData={onData}
                              strokeColor="#001380"
                              backgroundColor="#E5EFFF"
                            />
                          </Grid>
                          <Grid xs={2}>
                            <Avatar
                              className={!record ? 'icon-style' : 'icon-record'}
                              onClick={() => handleRecording()}
                            >
                              {!record ? (
                                <Mic className="icon-direct" />
                              ) : (
                                <Stop className="icon-direct" />
                              )}
                            </Avatar>
                          </Grid>
                        </Grid>
                      </div>
                    </div>
                  </>
                }
                result={
                  <>
                    {previewFiles?.map((file, index) =>
                      !files[index]?.type?.includes('audio') ? (
                        <FilePreviewContainer>
                          <FilePreviewCard
                            key={index}
                            label={files[index]?.name}
                            badge={{
                              invisible: false,
                              onClick: () => {
                                handleRemove(index)
                              },
                            }}
                          >
                            {files[index]?.type?.includes('pdf') ? (
                              <PictureAsPdfSharp style={{ color: '#e2574c' }} />
                            ) : (
                              <Avatar variant="square" src={file} />
                            )}
                          </FilePreviewCard>
                        </FilePreviewContainer>
                      ) : (
                        <div className="audio-div">
                          <audio controls className="audio-style">
                            <source src={file} />
                          </audio>
                          <Avatar
                            className="close-btn-audio"
                            onClick={e => handleRemove(index)}
                          >
                            <Close />
                          </Avatar>
                        </div>
                      )
                    )}
                  </>
                }
                timerPart={<></>}
              />
            </Box>
          </Card>
        </Container>
      </form>
      <Footer
        buttons={[
          {
            children: 'Save',
            color: 'primary',
            onClick: () => handleSubmit(onSubmit)(),
          },
        ]}
      />
    </>
  )
}

export default PACollectionActivityForm
