import React, { lazy } from 'react'
import { RouteObject } from 'react-router'

const ClassificationCodeListing = lazy(
  () => import('./ClassificationCodeListing')
)
const ClassificationCodeForm = lazy(() => import('./ClassificationCodeForm'))

const classificationCodeRoutes: RouteObject[] = [
  {
    path: 'classification-code',
    children: [
      {
        path: '',
        element: <ClassificationCodeListing />,
      },
      {
        path: ':id/add',
        element: <ClassificationCodeForm />,
      },
    ],
  },
]

export default classificationCodeRoutes
