import React, { lazy } from 'react'
import { RouteObject } from 'react-router'

const LoanApplicationListing = lazy(() => import('./LoanApplicationListing'))
const LoanApplicationForm = lazy(() => import('./LoanApplicationForm'))
const LoanApplicationDetail = lazy(() => import('./LoanApplicationDetail'))
const LoanAppEndFinForm = lazy(() => import('./LoanAppEndFinForm'))
const EndFinancierLoanDetail = lazy(() => import('./LoanAppEndFinDetail'))

const LoanSettlementRoutes: RouteObject[] = [
  {
    path: 'loan-application',
    children: [
      {
        path: '',
        element: <LoanApplicationListing />,
      },
      {
        path: 'detail',
        children: [
          {
            path: ':id',
            element: <LoanApplicationDetail mode="edit" />,
          },
          {
            path: ':id/view/:endFinLoanId',
            element: <EndFinancierLoanDetail />,
          },
          {
            path: ':id/add',
            element: <LoanAppEndFinForm formMode="Add" mode="edit" />,
          },
          {
            path: ':id/edit/:endFinLoanId',
            element: <LoanAppEndFinForm formMode="Edit" mode="edit" />,
          },
        ],
      },
      {
        path: 'add',
        children: [
          {
            path: '',
            element: <LoanApplicationForm mode="add" />,
          },
          {
            path: 'end-financier',
            children: [
              {
                path: 'add',
                element: <LoanAppEndFinForm formMode="Add" mode="add" />,
              },
              {
                path: ':detailId/edit',
                element: <LoanAppEndFinForm formMode="Edit" mode="add" />,
              },
            ],
          },
        ],
      },
    ],
  },
]

export default LoanSettlementRoutes
