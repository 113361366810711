import * as React from 'react'
import { AlignItemsEnum, JustifyContentEnum, OverflowEnum } from './LayoutXenum'

type props = {
  children?: React.ReactNode
  colGap?: string
  crossAxisAlignment?: AlignItemsEnum
  direction?: 'row' | 'column'
  fullWidth?: boolean
  height?: string
  mainAxisAlignment?: JustifyContentEnum
  overflow?: OverflowEnum
  rowGap?: string
}

export const Wrap = ({
  children,
  colGap = '0px',
  crossAxisAlignment,
  direction = 'row',
  fullWidth,
  height,
  mainAxisAlignment,
  overflow = 'visible',
  rowGap = '0px',
}: props) => {
  return (
    <div
      style={{
        alignItems: crossAxisAlignment,
        display: 'flex',
        flexFlow: `${direction} wrap`,
        gap: `${colGap} ${rowGap}`,
        height,
        justifyContent: mainAxisAlignment,
        overflow: overflow,
        verticalAlign: 'bottom',
        width: fullWidth ? '100%' : 'auto',
        wordBreak: 'break-word',
      }}
    >
      {children}
    </div>
  )
}
