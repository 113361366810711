import { lazy } from 'react'
import { RouteObject } from 'react-router'

const ContractEOTListing = lazy(() =>
  import('./ContractEOTListing').then(module => ({
    default: module.ContractEOTListing,
  }))
)
const ContractEOTForm = lazy(() =>
  import('./ContractEOTForm').then(module => ({
    default: module.ContractEOTForm,
  }))
)

const contractEOTRoutes: RouteObject[] = [
  {
    path: 'extention-time',
    children: [
      {
        path: '',
        element: <ContractEOTListing />,
      },
      {
        path: 'add',
        element: <ContractEOTForm mode="add" />,
      },
      {
        path: ':id/edit',
        element: <ContractEOTForm mode="edit" />,
      },
    ],
  },
]

export default contractEOTRoutes
