import { lazy } from 'react'
import { RouteObject } from 'react-router'
const PAprogressBillingListing = lazy(
  () => import('./PAprogressBillingListing')
)
const PAprogressBillingDetail = lazy(() => import('./PAprogressBillingDetail'))

const PAprogressBillingRoute: RouteObject[] = [
  {
    path: 'progress-billing',
    children: [
      {
        path: '',
        element: <PAprogressBillingListing isPA={true} />,
      },
      {
        path: ':id/view',
        element: <PAprogressBillingDetail isPA={true} />,
      },
    ],
  },
]

export default PAprogressBillingRoute
