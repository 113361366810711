import React, { lazy } from 'react'
import { RouteObject } from 'react-router'

const StakeholderUnitSummaryListing = lazy(
  () => import('./StakeholderUnitSummaryListing')
)
const StakeholderUnitSummaryDetail = lazy(
  () => import('./StakeholderUnitSummaryDetail')
)

const StakeholderUnitSummaryRoutes: RouteObject[] = [
  {
    path: 'unit-summary',
    children: [
      {
        path: '',
        element: <StakeholderUnitSummaryListing />,
      },
      {
        path: ':id/detail',
        element: <StakeholderUnitSummaryDetail />,
      },
    ],
  },
]

export default StakeholderUnitSummaryRoutes
