import { Snackbar as MuiSnackbar, type SnackbarProps } from '@mui/material'
import { styled } from '@mui/material/styles'
import { Colors } from '../../styles/variables'

const SnackbarRoot = styled(MuiSnackbar)`
  background-color: rgb(50, 50, 50);
  color: rgba(255, 255, 255, 0.87);
  font-weight: 500;

  & .MuiSnackbarContent-action .MuiButton-root {
    color: ${Colors.orange};
  }
`
export const Snackbar = ({ ...props }: SnackbarProps): JSX.Element => {
  return <SnackbarRoot {...props} />
}
