import {
  Container,
  EmptyState,
  List,
  ListItem,
  ListItemText,
  SearchBar,
  Subheader,
  SubheaderText,
  useMenuOption,
  SubContainerX,
} from '@ifca-ui/core'
import { Event, KeyboardArrowRight } from '@mui/icons-material'
import { Box, IconButton, Typography } from '@mui/material'
import { TopSectionHeader } from 'components/Header/HeaderSection'
import useNav from 'components/RouteService/useNav'
import AppContext, { AppContextProps } from 'containers/context/Context'
import {
  useGetCollectionActivityBySaleIdQuery,
  useGetCollectionActivityListQuery,
  useProjectDetailQuery,
} from 'generated/graphql'
import { useFuseSearch } from 'helpers/Hooks/useSearch'
import { formatDate } from 'helpers/StringNumberFunction/formatDate'
import React, { useContext, useEffect } from 'react'
import { useNavigate, useParams } from 'react-router'

const CollectionActivityListing = (props: any) => {
  const { rootState, rootDispatch } = useContext<AppContextProps>(AppContext)
  let navigate = useNavigate()
  const { navBack, navTo, navData, iNavTo } = useNav()
  const { pageMode } = props

  const { projectID, saleID }: any = useParams()
  const { filteredList, handleSearch, setOriginalListing } = useFuseSearch()
  const [search, setSearchInput] = React.useState('')
  const { anchorEl, menu, handleClick, handleClose } = useMenuOption()
  const [filter, setFilter] = React.useState([])

  //#region
  const {
    loading,
    error,
    data: { projectDetail } = { projectDetail: null },
  } = useProjectDetailQuery({
    variables: {
      id: projectID,
    },
  })

  const {
    loading: caListing,
    error: caError,
    data: { getCollectionActivityList } = { getCollectionActivityList: null },
  } = useGetCollectionActivityListQuery({})

  const {
    refetch,
    loading: collectionLoading,
    error: collectionError,
    data: { getCollectionActivityBySaleId } = {
      getCollectionActivityBySaleId: [],
    },
  } = useGetCollectionActivityBySaleIdQuery({
    fetchPolicy: 'network-only',
    onCompleted(data) {
      setFilter(
        data?.getCollectionActivityBySaleId?.filter(x => x?.remark !== null)
      )
    },
  })
  //#endregiongetCollectionActivityBySaleId

  useEffect(() => {
    if (filter) {
      setOriginalListing(filter)
    }
  }, [filter])

  //#region to set search input based on routeStack
  useEffect(() => {
    if (navData?.['searchInput']) {
      setSearchInput(navData?.['searchInput'])
      searchValue(navData?.['searchInput'])
    }
  }, [navData])
  //#endregion

  //#region search collection activity
  const searchValue = x => {
    handleSearch(x, ['purchaserName'])
  }
  //#endregion

  //#region header
  useEffect(() => {
    rootDispatch({
      type: 'headerComponent',
      payload: {
        ...rootState.headerComponent,
        leftIcon: {
          icon: 'back',
          props: {
            onClick: () => navBack(),
          },
        },
        rightIcon: {
          // icon: 'switch',
        },
        topSection: {
          smTitle: TopSectionHeader,
          title: `${projectDetail?.name ?? '-'}`,
        },
        bottomSection: {
          breadcrumbs: {
            maxItems: 3,
            current: 'Collection Activity',
          },
        },
      },
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [projectDetail])
  //#endregion

  return (
    <>
      <SubContainerX>
        <Subheader>
          <SubheaderText
            primary={
              // <Box borderBottom="1px solid #fff">
              <Typography
                component="div"
                display="flex"
                className="text-xsTitle "
              >
                <Typography
                  flex="1 1"
                  display="flex"
                  justifyContent={'unset'}
                  variant="inherit"
                  className="text-icon c-orange "
                >
                  Collection Activity Listing
                </Typography>
                <Typography
                  flex="1 1"
                  display="flex"
                  justifyContent={'right'}
                  variant="inherit"
                  className="text-icon"
                >
                  <Event />
                </Typography>
              </Typography>
              // </Box>
            }
          />
        </Subheader>
        <SearchBar
          title={
            <Typography
              component="span"
              className="text-mdLabel"
              variant="inherit"
            >
              {`Collection Activity Listing `} (
              <Typography
                component="span"
                variant="inherit"
                className="search-bar-count-f"
              >
                {`${filteredList?.length ?? 0}`}
              </Typography>
              )
            </Typography>
          }
          searchInput={{
            value: search,
            onChange: e => {
              setSearchInput(e.target.value)
              searchValue(e?.target?.value)
            },
            onChangeClearInput: () => {
              searchValue('')
              setSearchInput('')
            },
          }}
        />
      </SubContainerX>
      <>
        <Container>
          <List>
            {getCollectionActivityBySaleId === undefined ||
            getCollectionActivityBySaleId?.length === 0 ? (
              <EmptyState />
            ) : (
              filteredList?.map((x, i) => (
                // <ListItem>
                <ListItem
                  secondaryAction={
                    <IconButton
                      onClick={() =>
                        iNavTo({
                          routeUrl: `/${projectID}/credit-control/collection-activity/${x.saleId}`,
                          leftState: { searchInput: search },
                        })
                      }
                    >
                      <KeyboardArrowRight />
                    </IconButton>
                  }
                  onClick={() =>
                    iNavTo({
                      routeUrl: `/${projectID}/credit-control/collection-activity/${x.saleId}`,
                      leftState: { searchInput: search },
                    })
                  }
                >
                  <ListItemText
                    primary={
                      <Box>
                        <Typography
                          component="div"
                          display="flex"
                          variant="inherit"
                        >
                          <Typography
                            className="text-desc"
                            variant="inherit"
                            flex="1"
                          >
                            {formatDate(x?.date)}
                          </Typography>
                          <Typography
                            component="u"
                            className="text-xsTitle"
                            variant="inherit"
                            flex="1"
                          >
                            {/* {x?.unitNo} */}
                          </Typography>
                        </Typography>
                        <Typography
                          component="div"
                          display="flex"
                          variant="inherit"
                          className="text-desc"
                        >
                          <Typography
                            className={x?.status === 'NEW' ? 'color-red' : ''}
                            variant="inherit"
                            flex="1"
                          >
                            {x?.remark}
                          </Typography>
                        </Typography>
                      </Box>
                    }
                    secondary={
                      <Typography
                        component="div"
                        display="flex"
                        variant="inherit"
                      >
                        <Typography
                          component="span"
                          className="text-xsTitle u"
                          variant="inherit"
                          flex="1"
                          color="#454545"
                        >
                          {x?.unitNo}
                        </Typography>
                      </Typography>
                    }
                  />
                </ListItem>
              ))
            )}
          </List>
        </Container>
      </>
    </>
  )
}

export default CollectionActivityListing
 