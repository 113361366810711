import { lazy } from 'react'
import { RouteObject } from 'react-router'

export const DESIGN_E_LETTER_PATH = {
  DESIGNER_SUBMENU: `/common-setting/designer-submenu`,
}

const DesignerForm = lazy(() => import('./Designer'))
const DesignerSubmenu = lazy(() => import('./DXTempSubmenu'))
const DesignerView = lazy(() => import('./DocumentViewer'))
const eLetterPrintingRoutes: RouteObject[] = [
  {
    path: 'designer-submenu',
    children: [
      {
        path: '',
        element: <DesignerSubmenu />,
      },
      {
        path: 'design/:reportId',
        element: <DesignerForm />,
      },
      {
        path: 'view/:reportId',
        element: <DesignerView />,
      },
    ],
  },
]

export default eLetterPrintingRoutes
