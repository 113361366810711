import { lazy } from 'react'
import { RouteObject } from 'react-router'

const SalesDemographicAnalysis = lazy(
  () => import('./SalesDemographicAnalysis')
)

const SalesDemographicAnalysisRoutes: RouteObject[] = [
  {
    path: 'sales-demographic-analysis',
    children: [
      {
        path: '',
        element: <SalesDemographicAnalysis />,
      },
    ],
  },
]

export default SalesDemographicAnalysisRoutes
