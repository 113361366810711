import { Avatar } from '@mui/material'
import { Folder, Image, Mic, Title } from '@mui/icons-material'
import React from 'react'

export interface fileUploadProps {
  inputField
  result
  timerPart
  handleUploadFile
  setMode
  excludeText?
  excludeAudio?
  stateCondition?
}

export const MultiPurposeFileUpload = ({
  inputField,
  result,
  timerPart,
  handleUploadFile,
  setMode,
  excludeText,
  excludeAudio,
  stateCondition,
}: fileUploadProps) => {
  return (
    <div className="multipurpose">
      <div>{inputField} </div>
      <div>{result} </div>

      <div className="multipurpose-fileupload">
        {excludeText ? null : (
          <div className="avatar-wrapper m-r6" onClick={() => setMode('text')}>
            <Avatar>
              <Title />
            </Avatar>
            <span className="desc fw-medium">Text</span>
          </div>
        )}
        {excludeAudio ? null : (
          <div
            className="avatar-wrapper m-r6"
            onClick={() => {
              setMode('audio')
              stateCondition('audio')
            }}
          >
            <input
              accept={'.wav, .mp3, .webm'}
              id="audio-file"
              type="audio"
              hidden
              onChange={e => {
                handleUploadFile(e)
                e.target.value = null
              }}
            />
            <Avatar>
              <Mic />
            </Avatar>
            <span className="desc fw-medium">Audio</span>
          </div>
        )}
        <div
          className="avatar-wrapper m-r6"
          onClick={() => {
            setMode('media')
            stateCondition('media')
          }}
        >
          <input
            accept={'.png, .jpg, .jpeg, .mp4, .avi, .mkv, .mov, .flv, .3gp'}
            id="image-file"
            type="file"
            hidden
            multiple
            onChange={e => {
              handleUploadFile(e)
              e.target.value = null
            }}
          />
          <label htmlFor="image-file">
            <Avatar>
              <Image />
            </Avatar>
          </label>
          <span className="desc fw-medium">Photo/Video</span>
        </div>
        <div
          className="avatar-wrapper m-r6"
          onClick={() => {
            setMode('docs')
            stateCondition('docs')
          }}
        >
          <input
            accept={
              'application/msword, application/*, application/vnd.openxmlformats-officedocument.wordprocessingml.document'
            }
            id="doc-file"
            type="file"
            hidden
            multiple
            onChange={e => {
              handleUploadFile(e)
              e.target.value = null
            }}
          />
          <label htmlFor="doc-file">
            <Avatar>
              <Folder />
            </Avatar>
          </label>
          <span className="desc fw-medium">Document</span>
        </div>
      </div>
      <div>{timerPart}</div>
    </div>
  )
}

// const p = () => {
// multiple remarks
// <List>
// {remarks?.map((v, i) => (
//   <>
//     <ListItem>
//       {/* <Close /> */}
//       <span>
//         {i}. {v}{' '}
//       </span>
//     </ListItem>
//   </>
// ))}
// </List>
// {audioData?.map((v, i) => (
//   <audio controls>
//     <source src={v?.blobURL} type="audio/webm" />
//   </audio>
// ))}
// }
