import { lazy } from 'react'
import { RouteObject } from 'react-router-dom'

const ApprovalPolicyListing = lazy(() => import('./ApprovalPolicyListing'))
const ApprovalPolicyForm = lazy(() => import('./ApprovalPolicyForm'))
const ApprovalPolicyFormNoLimit = lazy(
  () => import('./ApprovalPolicyFormNoLimit')
)

const approvalPolicyRoutes: RouteObject[] = [
  {
    path: 'approval-policy',
    children: [
      {
        path: '',
        element: <ApprovalPolicyListing />,
      },
      {
        path: ':category',
        element: <ApprovalPolicyForm />,
      },
      {
        path: ':category/no-limit',
        element: <ApprovalPolicyFormNoLimit />,
      },
    ],
  },
]

export default approvalPolicyRoutes
