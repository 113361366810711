import React, { lazy } from 'react'
import { RouteObject } from 'react-router-dom'
import TaxEditForm from './TaxEditForm'

const CommonSettingBase = '/common-setting'

const TaxTypeListing = lazy(() => import('./TaxTypeListing'))
const TaxSetupListing = lazy(() => import('./TaxSetupListing'))
const TaxListing = lazy(() => import('./TaxListing'))
const TaxForm = lazy(() => import('./TaxForm'))
const TaxDetail = lazy(() => import('./TaxDetail'))

export const TAX_SETUP_PATH = {
  TAX_TYPE_LISTING: `${CommonSettingBase}/tax-setup`,
  TAX_LISTING: `${CommonSettingBase}/tax-setup/:schemeType`,
}

const taxSetupRoutes: RouteObject[] = [
  {
    path: 'tax-setup',
    children: [
      {
        path: '',
        element: <TaxSetupListing />,
      },
      // {
      //   path: '',
      //   element: <TaxTypeListing />,
      // },
      // {
      //   path: `:schemeType/:schemeTypeId`,
      //   element: <TaxListing />,
      // },
      {
        path: `:taxId/detail`,
        element: <TaxDetail />,
      },
      {
        path: `:taxId/edit`,
        element: <TaxForm mode="edit" />,
        // element: <TaxEditForm />,
      },
      {
        path: `:add`,
        element: <TaxForm mode="add" />,
      },
    ],
  },
]

export default taxSetupRoutes
