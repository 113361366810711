import React, { lazy } from 'react'
import { RouteObject } from 'react-router'

const PaymentReminderListing = lazy(
  () =>
    import('../PaymentReminderSetting/PaymentReminder/PaymentReminderListing')
)
const PaymentReminderForm = lazy(
  () => import('../PaymentReminderSetting/PaymentReminder/PaymentReminderForm')
)
const PaymentReminderSubmenu = lazy(
  () => import('../PaymentReminderSetting/PaymentReminderSubmenu')
)
const PaymentReminderExceptionListing = lazy(
  () =>
    import(
      '../PaymentReminderSetting/PaymentReminderException/PaymentReminderExceptionListing'
    )
)
const PaymentReminderExceptionForm = lazy(
  () =>
    import(
      '../PaymentReminderSetting/PaymentReminderException/PaymentReminderExceptionForm'
    )
)

const PaymentReminderSettingRoutes: RouteObject[] = [
  {
    path: 'payment-reminder-setting',
    children: [
      {
        path: '',
        children: [
          {
            path: '',
            element: <PaymentReminderSubmenu />,
          },
          {
            path: 'payment-reminder',
            children: [
              { path: '', element: <PaymentReminderListing /> },
              {
                path: 'detail/:generationBatchId',
                element: <PaymentReminderForm mode="detail" />,
              },
              {
                path: 'add',
                element: <PaymentReminderForm mode="add" />,
              },
            ],
          },
          {
            path: 'payment-reminder-exception',
            children: [
              {
                path: '',
                element: <PaymentReminderExceptionListing />,
              },
              {
                path: 'add',
                element: <PaymentReminderExceptionForm />,
              },
            ],
          },
        ],
      },
    ],
  },
]

export default PaymentReminderSettingRoutes
