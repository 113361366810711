import { lazy } from 'react'
import { RouteObject } from 'react-router-dom'

const BookingListBIpage = lazy(() => import('./BookingListBIpage'))
const BookingListBIroutes: RouteObject[] = [
  {
    path: 'bookingList',
    children: [
      {
        path: '',
        element: <BookingListBIpage />,
      },
    ],
  },
]

export default BookingListBIroutes
