import moment from 'moment'

export const isSameOrBeforeTime = (startTime, endTime) => {
  return moment(startTime, 'HH:mm').isSameOrBefore(moment(endTime, 'HH:mm'))
}

export const isSameOrBeforeDate = (startDate, endDate) => {
  const start = moment(startDate).startOf('day')
  const end = moment(endDate).startOf('day')
  return start.isSameOrBefore(end, 'day')
}

export const isAfterDate = (startDate, endDate) => {
  const start = moment(startDate).startOf('day')
  const end = moment(endDate).startOf('day')
  return end.isAfter(start, 'day')
}

export const isSameOrAfterDate = (startDate, endDate) => {
  const start = moment(startDate).startOf('day')
  const end = moment(endDate).startOf('day')
  return end.isSameOrAfter(start, 'day')
}

export const isSameTime = (startTime, endTime) => {
  return moment(startTime, 'HH:mm').isSame(moment(endTime, 'HH:mm'))
}

export const isSameDate = (startDate, endDate) => {
  const start = moment(startDate).startOf('day')
  const end = moment(endDate).startOf('day')
  return start.isSame(end, 'day')
}

export const isBeforeTime = (startTime, endTime) => {
  return moment(startTime, 'HH:mm').isBefore(moment(endTime, 'HH:mm'))
}

export const isBeforeDate = (startDate, endDate) => {
  const start = moment(startDate).startOf('day')
  const end = moment(endDate).startOf('day')
  return start.isBefore(end, 'day')
}

export const timeDifferent = (startTime, endTime) => {
  startTime = moment(startTime)
  endTime = moment(endTime)
  return endTime.diff(startTime, 'minutes')
}

export const momentFormatDate = date => {
  return moment(date)?.format('DD MMM yyyy')
}

// get date and format time to 00:00:00
export const getDate = (date?) => {
  if (date) return moment(date).startOf('day').toDate()

  return moment().startOf('day').toDate()
}

// get next date and format time to 00:00:00
export const getNextDate = () => {
  return moment().add(1, 'day').startOf('day').toDate()
}
