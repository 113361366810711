import useAppData from 'components/General/useAppData'
import {
  useGetNewPBdataLazyQuery,
  useGetNewPBdataQuery,
  useGetSalesContractByIdQuery,
  useReversalProgressBillingMutation,
} from 'generated/graphql'
import { SystemMsgs } from 'helpers/Messages/SystemMsg'
import { setSnackBar } from 'helpers/snackBarSubjectHelper'
import { useState } from 'react'

export const useSalesContractById = () => {
  const { saleID } = useAppData()
  const { data: salesContractData = { getSalesContractById: null } } =
    useGetSalesContractByIdQuery({
      variables: { saleID },
    })
  const salesContract = salesContractData.getSalesContractById

  return { salesContract }
}

export const useReversalPB = (saleID, refetchData) => {
  const [reversalProgressBilling] = useReversalProgressBillingMutation({
    onError: error => {},
    onCompleted: data => {
      setSnackBar(SystemMsgs.reverseRecord())
      refetchData()
    },
  })
  const reversalData = (id: string[]) => {
    const progressBillingInput = id?.map(el => {
      return {
        saleId: saleID,
        progressBillingId: el,
      }
    })
    reversalProgressBilling({
      variables: { input: progressBillingInput, removeGenerationBatch: true },
    }) // Pass the object to the function
  }

  return { reversalData }
}

export const useGetData = saleID => {
  const {
    data: { getNewPBdata } = { getNewPBdata: {} },
    refetch: refetchData,
  } = useGetNewPBdataQuery({
    variables: { saleId: saleID },
  })

  return { getNewPBdata, refetchData }
}
