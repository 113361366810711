import React, { ReactNode, Suspense } from 'react'
import { Navigate, Outlet, Route } from 'react-router'
import { Spinner } from '@ifca-ui/core'

export const Blank = () => {
  return (
    <Suspense fallback={<Spinner />}>
      <Outlet />
    </Suspense>
  )
}
