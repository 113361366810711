import { lazy } from 'react'
import { RouteObject } from 'react-router'
const PArebateAllocationPage = lazy(() => import('./PArebateAllocationPage'))
const PArebateDetail = lazy(() => import('./PArebateDetail'))
const PArebateForm = lazy(() => import('./PArebateForm'))
const PArebateListingPage = lazy(() => import('./PArebateListingPage'))

const PARebateRoutes: RouteObject[] = [
  {
    path: 'rebate',
    children: [
      {
        path: '',
        element: <PArebateListingPage tableRef="rebate" />,
      },

      {
        path: 'add',
        element: <PArebateForm mode="add" tableRef="rebate" />,
      },
      {
        path: ':id/edit',
        element: <PArebateForm mode="edit" tableRef="rebate" />,
      },
      {
        path: ':id/view',
        element: <PArebateDetail tableRef="rebate" />,
      },
      {
        path: ':id/allocation',
        element: <PArebateAllocationPage tableRef="rebate" />,
      },
    ],
  },
]

export default PARebateRoutes
