import { yupResolver } from '@hookform/resolvers/yup'
import { Card, MenuItem, Subheader, SubheaderText } from '@ifca-ui/core'
import { Autocomplete, Box, Switch, TextField, Typography } from '@mui/material'
import { Dialog } from 'components/Dialogs/Dialog'
import {
  BillItem,
  useAddBillItemMutation,
  useEditBillItemMutation,
  useGetTaxSchemeOutputListingQuery,
  useGetMsicCodeListingQuery,
  useGetClassificationCodeListingQuery,
} from 'generated/graphql'
import { SystemMsgs } from 'helpers/Messages/SystemMsg'
import { setSnackBar } from 'helpers/snackBarSubjectHelper'
import { CommonYupValidation } from 'helpers/yup'
import { useEffect, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import * as yup from 'yup'
import Collapse from '@mui/material/Collapse'

interface TaxSchemeObject {
  taxSchemeId: string
  taxSchemeName: string
}
interface classificationProps {
  id: string
  classificationCode?: string
  description?: string
}
interface msicProps {
  id: string
  msicCode?: string
  description?: string
}
interface billItemsDialogProps {
  open: boolean
  setOpen: any
  handleDialogClose: any
  billItemId?: string
  refetch: any
  mode: 'ADD' | 'EDIT'
  menuData?: any
  setDialogMode: any
}

interface BillItemProps {
  name: string
  billItemType: string
  isEinvIntegrated: boolean
  classification: classificationProps
  msic: msicProps
  // taxScheme: TaxSchemeObject
}

export const BillItemsDialog = ({
  open,
  setOpen,
  handleDialogClose,
  billItemId,
  refetch,
  menuData,
  mode,
  setDialogMode,
}: billItemsDialogProps) => {
  const [addNew, setAddNew] = useState(false)
  const [taxSchemeList, setTaxSchemeList] = useState<TaxSchemeObject[]>([])

  //#region schema validation
  const schema = yup.object().shape({
    name: CommonYupValidation.requireField(SystemMsgs.name()),
    billItemType: CommonYupValidation.requireField('Type is required'),
    // taxScheme: CommonYupValidation.requireArray('Tax Scheme is required.'),
    classification: yup.mixed().when('isEinvIntegrated', {
      is: value => value == true,
      then: CommonYupValidation.requireArray('Classification Code is required'),
    }),
    msic: yup.mixed().when('isEinvIntegrated', {
      is: value => value == true,
      then: CommonYupValidation.requireArray('Msic Code is required'),
    }),
  })
  //#endregion

  //#region use form method
  const {
    handleSubmit,
    control,
    reset,
    setValue,
    watch,
    formState: { errors },
  } = useForm<BillItemProps>({
    defaultValues: {
      name: '',
      billItemType: '',
      isEinvIntegrated: false,
      classification: null,
      msic: null,
      // taxScheme: null,
    },
    mode: 'onSubmit',
    resolver: yupResolver(schema),
  })
  //#endregion

  //#region add bill item api
  const [addBillItem, { loading: addBillItemLoading }] = useAddBillItemMutation(
    {
      onError: error => {
        if (addNew) {
          setOpen(true)
        }
      },
      onCompleted: data => {
        if (data?.addBillItem) {
          setSnackBar(SystemMsgs.createNewRecord())
        }
        if (addNew) {
          setOpen(true)
        }
        refetch()
      },
    }
  )
  //#endregion

  //#region edit bill item api
  const [editBillItem] = useEditBillItemMutation({
    onError: error => {},
    onCompleted: data => {
      if (data?.editBillItem) {
        setSnackBar(SystemMsgs.updateRecord())
        refetch()
      }
    },
  })
  //#endregion

  //#region submit form to api
  const onSubmit = data => {
    setAddNew(false)
    // console.log('d', data)
    let haveClassificationId: string
    let haveMsicId: string
    if (data?.isEinvIntegrated) {
      haveClassificationId = data?.classification?.id
      haveMsicId = data?.msic?.id
    } else if (!data?.isEinvIntegrated) {
      haveClassificationId = null
      haveMsicId = null
    }

    if (mode === 'ADD') {
      addBillItem({
        variables: {
          input: {
            name: data.name,
            billItemType: data.billItemType,
            classificationId: haveClassificationId,
            msicId: haveMsicId,
          },
        },
      })
    } else {
      editBillItem({
        variables: {
          input: {
            id: billItemId,
            name: data.name,
            billItemType: data.billItemType,
            classificationId: haveClassificationId,
            msicId: haveMsicId,
          },
        },
      })
    }
    handleDialogClose()
  }

  const onSaveAndNew = data => {
    onSubmit(data)
    setDialogMode('ADD')
    setAddNew(true)
  }
  //#endregion

  //#region get tax scheme api
  // const {
  //   data: { getTaxSchemeOutputListing } = {
  //     getTaxSchemeOutputListing: [],
  //   },
  // } = useGetTaxSchemeOutputListingQuery({
  //   onCompleted: data => {
  //     let tempTaxSchemeList = data.getTaxSchemeOutputListing?.map(x => {
  //       return {
  //         taxSchemeId: x.taxSchemeId,
  //         taxSchemeName: x.taxSchemeName,
  //       } as TaxSchemeObject
  //     })
  //     setTaxSchemeList(tempTaxSchemeList)
  //   },
  // })
  //#endregion

  //#region e-invoice
  const {
    loading: msicLoading,
    // refetch,
    data: { getMsicCodeListing } = {
      getMsicCodeListing: [],
    },
  } = useGetMsicCodeListingQuery({})

  const {
    loading: classificationLoading,
    // refetch,
    data: { getClassificationCodeListing } = {
      getClassificationCodeListing: [],
    },
  } = useGetClassificationCodeListingQuery({})
  //#endregion

  //#region reset form
  useEffect(() => {
    if (!open) {
      reset()
    }

    if (mode == 'EDIT') {
      setValue('name', menuData?.name)
      setValue('billItemType', menuData?.billItemType)

      //if previous have e-invoice will set the switch to true
      //then map back the previously selected classification and msic to show the code and description
      if (menuData?.classificationId !== null && menuData?.msicId !== null) {
        setValue('isEinvIntegrated', true)

        if (getMsicCodeListing?.length !== 0) {
          let msicObject = getMsicCodeListing?.find(
            el => el?.id == menuData?.msicId
          )
          if (msicObject) {
            setValue('msic', msicObject)
          }
        }

        if (getClassificationCodeListing?.length !== 0) {
          let classificationObject = getClassificationCodeListing?.find(
            el => el?.id == menuData?.classificationId
          )

          if (classificationObject) {
            setValue('classification', classificationObject)
          }
        }
      }
    }
  }, [open, mode, getMsicCodeListing, getClassificationCodeListing])
  //#endregion

  const enumListing = [
    {
      type: BillItem.Misc,
      name: 'Misc Billing',
    },
    {
      type: BillItem.Rebate,
      name: 'Rebate',
    },
    {
      type: BillItem.Contra,
      name: 'Contra',
    },
  ]

  return (
    <>
      <Dialog
        fullWidth={true}
        open={open}
        onClose={() => handleDialogClose}
        header={
          <Subheader sx={{ padding: '10px 12px' }}>
            <SubheaderText
              primary={
                <Box borderBottom="1px solid #fff">
                  <Box display="flex" margin="2px 0px" width="100%">
                    <Typography
                      className="text-xsTitle"
                      color="#FF9800"
                      component="span"
                      flex="1 1"
                      variant="inherit"
                    >
                      Transaction Type
                    </Typography>
                    <Typography
                      className="text-xsTitle"
                      color="primary.dark"
                      component="span"
                      variant="inherit"
                    >
                      {mode === 'ADD' ? 'New' : 'Edit'}
                    </Typography>
                  </Box>
                </Box>
              }
            />
          </Subheader>
        }
        body={
          <>
            <Controller
              control={control}
              name="name"
              render={({ field: { onChange, onBlur, value } }) => (
                <TextField
                  label="Name"
                  autoComplete="off"
                  error={errors?.name ? true : false}
                  helperText={errors?.name?.message}
                  onChange={onChange}
                  onBlur={onBlur}
                  value={value}
                  fullWidth
                  margin="normal"
                  variant="standard"
                  required
                />
              )}
            />

            {menuData?.systemUse == false || mode == 'ADD' ? (
              <Controller
                control={control}
                name="billItemType"
                render={({ field: { onChange, onBlur, value } }) => (
                  <TextField
                    select
                    variant="standard"
                    fullWidth
                    margin="normal"
                    onBlur={onBlur}
                    onChange={onChange}
                    autoComplete="off"
                    name="billItemType"
                    label="Type"
                    required
                    value={value}
                    helperText={errors?.billItemType?.message}
                    error={errors?.billItemType ? true : false}
                  >
                    {enumListing?.map(v => (
                      <MenuItem value={v.type}>{v.name}</MenuItem>
                    ))}
                  </TextField>
                )}
              />
            ) : null}

            <Box width="100%" display="flex">
              <Box width="50%">
                <Typography
                  paddingTop="10px"
                  variant="inherit"
                  className="text-smTitle"
                  color="primary"
                >
                  Implement E-invoice
                </Typography>
              </Box>
              <Box width="50%" display="flex" justifyContent="flex-end">
                <Controller
                  control={control}
                  name="isEinvIntegrated"
                  render={({ field: { onChange, onBlur, value } }) => (
                    <Switch
                      value={value}
                      color="primary"
                      onChange={e => onChange(e.target.checked)}
                      onBlur={onBlur}
                      checked={value}
                    />
                  )}
                />
              </Box>
            </Box>
            <Collapse
              in={watch('isEinvIntegrated')}
              timeout="auto"
              unmountOnExit
              sx={{ width: '100%' }}
            >
              <Controller
                control={control}
                name="classification"
                render={({ field: { onChange, onBlur, value } }) => (
                  <Autocomplete
                    fullWidth
                    options={getClassificationCodeListing}
                    getOptionLabel={
                      option =>
                        `${option?.['classificationCode']} ${
                          option?.['description']
                            ? `${option?.['description']} `
                            : ''
                        }`
                      // [
                      //   option?.['classificationCode'],
                      //   option?.['description'],
                      // ].join('\n')
                    }
                    value={value}
                    onChange={(event, value) => {
                      onChange(value)
                    }}
                    renderInput={params => (
                      <TextField
                        {...params}
                        label="Classification Code"
                        variant="standard"
                        margin="normal"
                        autoComplete="off"
                        value={value}
                        required={watch('isEinvIntegrated') ? true : false}
                        helperText={errors?.classification?.message}
                        error={errors?.classification ? true : false}
                      />
                    )}
                  />
                )}
              />

              <Controller
                control={control}
                name="msic"
                render={({ field: { onChange, onBlur, value } }) => (
                  <Autocomplete
                    fullWidth
                    getOptionLabel={option =>
                      `${option?.['msicCode']}${
                        option?.['description']
                          ? ` (${option?.['description']})`
                          : ''
                      }`
                    }
                    options={getMsicCodeListing}
                    value={value}
                    onChange={(event, value) => {
                      onChange(value)
                    }}
                    renderInput={params => (
                      <TextField
                        {...params}
                        label="Msic Code"
                        variant="standard"
                        margin="normal"
                        autoComplete="off"
                        value={value}
                        required={watch('isEinvIntegrated') ? true : false}
                        helperText={errors?.msic?.message}
                        error={errors?.msic ? true : false}
                      />
                    )}
                  />
                )}
              />
            </Collapse>
            {/* <Controller
              control={control}
              name="taxScheme"
              render={({ field: { onChange, onBlur, value } }) => (
                <Autocomplete
                  fullWidth
                  getOptionLabel={option => `${option?.['taxSchemeName']}`}
                  options={taxSchemeList}
                  value={value}
                  onChange={(event, newValue) => {
                    onChange(newValue)
                  }}
                  renderInput={params => (
                    <TextField
                      {...params}
                      label="Tax Code"
                      variant="standard"
                      margin="normal"
                      autoComplete="off"
                      value={value}
                      helperText={errors?.taxScheme?.message}
                      error={errors?.taxScheme ? true : false}
                      required
                    />
                  )}
                />
              )}
            /> */}
          </>
        }
        footer={{
          buttons:
            mode == 'EDIT'
              ? [
                  {
                    children: 'Cancel',
                    color: 'primary',
                    onClick: () => handleDialogClose(),
                  },

                  {
                    children: 'Save',
                    color: 'primary',
                    onClick: () => handleSubmit(onSubmit)(),
                  },
                ]
              : [
                  {
                    children: 'Cancel',
                    color: 'primary',
                    onClick: () => handleDialogClose(),
                  },
                  {
                    children: 'Save & New',
                    color: 'primary',
                    onClick: () => handleSubmit(onSaveAndNew)(),
                  },
                  {
                    children: 'Save',
                    color: 'primary',
                    onClick: () => handleSubmit(onSubmit)(),
                  },
                ],
        }}
      />
    </>
  )
}
