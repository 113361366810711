import React from 'react'

interface SizedBoxProps {
  width?: number | string
  height?: number | string
  padding?: string
  margin?: string
  children?: React.ReactNode
}

/**
 * @example
 * ```tsx
 * <SizedBox height={'30px'} width={'20%'} />
 *
 * <SizedBox padding={EdgeInsets.all(8)}>
 *   <span>Typical eight-pixel padding on all sides</span>
 * </SizedBox>
 *
 * <SizedBox padding={EdgeInsets.symmetric({ vertical: 8 })}>
 *   <span>Eight pixel padding above and below, no horizontal paddings</span>
 * </SizedBox>
 *
 * <SizedBox margin={EdgeInsets.only({ left: 40 })}>
 *   <span>Left margin indent of 40 pixels</span>
 * </SizedBox>
 * ```
 */

export const SizedBox: React.FC<SizedBoxProps> = ({
  width,
  height,
  padding,
  margin,
  children,
}) => {
  const style: React.CSSProperties = {
    width: width ?? 'auto',
    height: height ?? 'auto',
    padding: padding ?? '0',
    margin: margin ?? '0',
  }

  return <div style={style}>{children}</div>
}
