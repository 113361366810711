import { lazy } from 'react'
import { RouteObject } from 'react-router'
import ArchitectCertificatesRoutes from './ArchitectCertificates/Routes'
import MiscBillingRoutes from './MiscBilling/Routes'
import ProgressBillingRoutes from './ProgressBilling/Routes'
import StandardMiscChargesRoutes from './StandardMiscCharges/Routes'

const ProgressBillingSubmenu = lazy(() => import('./ProgressBillingSubmenu'))
const MiscBillingSubmenu = lazy(() => import('./MiscBillingSubmenu'))

const ProgressMiscBillingRoutes: RouteObject[] = [
  {
    path: 'progress-billing-submenu',
    children: [
      {
        path: '',
        element: <ProgressBillingSubmenu />,
      },
      ...ArchitectCertificatesRoutes,
      ...ProgressBillingRoutes,
    ],
  },
  {
    path: 'misc-billing-submenu',
    children: [
      {
        path: '',
        element: <MiscBillingSubmenu />,
      },
      ...StandardMiscChargesRoutes,
      ...MiscBillingRoutes,
    ],
  },
]

export default ProgressMiscBillingRoutes
