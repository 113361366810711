import { parsePhoneNumber } from 'libphonenumber-js'

export class PhoneValidationService {
  /**
   *
   * @param contactNo to verify
   * @returns boolean
   */
  static isValidPoneNumber(contactNo: string) {
    try {
      let phoneNumber = parsePhoneNumber(contactNo)
      return phoneNumber?.isValid()
    } catch (error) {
      return false
    }
  }

  /**
   *
   * @param contactNo to include "+" if missing
   * @returns contactNo
   */
  static validatePhonePrefix(contactNo: string) {
    if (contactNo !== null) {
      if (contactNo.charAt(0) !== '+') {
        contactNo = `+${contactNo}`
      }
    }

    return contactNo
  }
}
