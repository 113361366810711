import { Dialog, Subheader, SubheaderText } from '@ifca-ui/core'
import { Box, Typography } from '@mui/material'
import React, { useState } from 'react'

interface DialogFormProps {
  body?: {}
  dialogAction?: boolean
  mode?: String
  footer?: any
  title: string
  subtitle?: string
  setOpenDialog?: any
}
export const DialogForm = (props: DialogFormProps) => {
  const { body, mode, dialogAction, footer, title, subtitle, setOpenDialog } =
    props

  return (
    <Dialog
      fullWidth={true}
      open={dialogAction}
      onClose={(event, reason) => {
        if (reason != 'backdropClick' && reason != 'escapeKeyDown') {
          setOpenDialog(false)
        }
      }}
      header={
        <Subheader sx={{ padding: '10px 12px' }}>
          <SubheaderText
            primary={
              <Box borderBottom="1px solid #fff">
                <Box display="flex" margin="2px 0px" width="100%">
                  <Typography
                    className="text-xsTitle dialog-title-color"
                    component="span"
                    flex="1 1"
                    variant="inherit"
                  >
                    {title}
                  </Typography>
                  {!subtitle && (
                    <Typography
                      className="text-xsTitle dialog-title-color"
                      component="span"
                      variant="inherit"
                    >
                      {mode === 'Add' || mode === 'add'
                        ? 'New'
                        : mode == null
                          ? ''
                          : 'Edit'}
                    </Typography>
                  )}
                </Box>
              </Box>
            }
            secondary={
              <>
                {subtitle && (
                  <Box borderBottom="1px solid #fff">
                    <Box display="flex" margin="2px 0px" width="100%">
                      <Typography
                        className="text-xsTitle dialog-title-color"
                        component="span"
                        flex="1 1"
                        variant="inherit"
                      >
                        {subtitle}
                      </Typography>
                      <Typography
                        className="text-xsTitle dialog-title-color"
                        component="span"
                        variant="inherit"
                      >
                        {mode === 'Add' || mode === 'add'
                          ? 'New'
                          : mode == null
                            ? ''
                            : 'Edit'}
                      </Typography>
                    </Box>
                  </Box>
                )}
              </>
            }
          />
        </Subheader>
      }
      body={body}
      footer={footer}
    />
  )
}
