import { ColumnFlex } from '@ifca-ui/core'
import { Typography } from '@mui/material'
import { useConfirmDialog } from 'components/Dialogs/useConfirmDialog'
import { CardListHeader } from 'components/General/CardListItem'
import useAppData from 'components/General/useAppData'
import { ProgressBillingDataList } from 'generated/graphql'
import React, { useState } from 'react'
import { useReversalPB } from '../PBcomponent/PAprogressBillingBloc'
import PBexpansionInstallmentSummaryPro from './PBexpansionInstallmentSummaryPro'
import PBexpansionSummaryPro from './PBexpansionSummaryPro'

interface Props {
  isPA: boolean
  type: string
  pbListData: ProgressBillingDataList['sale_subproduct']
  refetchData: any
}

export const PBtypeListPro = ({
  isPA,
  type,
  pbListData,
  refetchData,
}: Props) => {
  const { saleID, navTo, projectID } = useAppData()
  const { reversalData } = useReversalPB(saleID, refetchData)
  const { showConfirmDialog, ConfirmDialogX } = useConfirmDialog()
  const [reversalId, setReversalId] = useState([])

  // const filteredData = pbListData.filter(item => !item.isReversal)
  // const lastIndex = filteredData.length - 1

  const handleReversal = () => {
    // if (item.allow_reversal && !item.isReversal && isPA) {
    //   const reversalIds = item.details
    //     .map(data => data.progressBillingId)
    //     .filter(Boolean)
    //   setReversalId(reversalIds)
    //   showConfirmDialog()
    // }
  }
  return (
    <>
      <ConfirmDialogX
        title={'Progress Billing'}
        bodyText={'Are you sure want to reversal?'}
        rightTopTitle={'Reversal'}
        action={() => {
          reversalData(reversalId)
        }}
      />
      <div>
        {pbListData.length >= 1 &&
          pbListData.map((AddOnRef, index) => {
            return (
              <div key={index}>
                <CardListHeader
                  title={
                    <Typography
                      className="text-xsTitle"
                      color="#FF9800"
                      component="span"
                    >
                      {AddOnRef?.titleBar}
                    </Typography>
                  }
                />
                <ColumnFlex gap={'5px'}>
                  {AddOnRef?.PBdata?.map((item, index) => {
                    let haveSubData = item.installmentData?.length > 1
                    return (
                      <React.Fragment key={item.id}>
                        {haveSubData ? (
                          <PBexpansionInstallmentSummaryPro
                            PBdata={item}
                            isPA={isPA}
                            handleReversal={handleReversal}
                            lastIndex={1}
                            index={index}
                            key={index}
                          />
                        ) : (
                          <PBexpansionSummaryPro
                            PBdata={item}
                            isPA={isPA}
                            handleReversal={handleReversal}
                            lastIndex={1}
                            index={index}
                            key={index}
                          />
                        )}
                      </React.Fragment>
                    )
                  })}
                </ColumnFlex>
              </div>
            )
          })}
      </div>
    </>
  )
}
