import { type Theme } from '@mui/material'
import Backdrop from '@mui/material/Backdrop'
import CircularProgress from '@mui/material/CircularProgress'
import * as React from 'react'

export function Spinner(): JSX.Element {
  const [open, setOpen] = React.useState(false)
  React.useEffect(() => {
    setOpen(true)
  }, [open])

  const handleClose = (): void => {
    setOpen(false)
  }
  return (
    <Backdrop
      sx={{
        color: (theme: Theme) => theme.palette.primary.main,
        zIndex: 99999,
      }}
      open={open}
      onClick={handleClose}
      transitionDuration={2000}
    >
      <CircularProgress color="inherit" />
    </Backdrop>
  )
}
