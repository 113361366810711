import { Box, Typography } from '@mui/material'
import React from 'react'
import { styled } from '@mui/material/styles'

const CopyRightLogo = styled('img')`
  width: 100%;
  max-width: 60px;
`
export interface AuthCopyRightProps {
  /** Copy right auth logo */
  logo?: string
}
export const AuthCopyRight: React.FC<AuthCopyRightProps> = ({ logo }) => {
  const [year] = React.useState(new Date().getFullYear())

  return (
    <Box
      width="100%"
      position="fixed"
      bottom={0}
      right={0}
      color={'white'}
      fontSize="10px"
      fontWeight={500}
      p={'6px 8px'}
      boxSizing="border-box"
    >
      <Box display="flex" mb="6px">
        <CopyRightLogo src={logo} alt="ifca-logo" loading="lazy" />
      </Box>
      <Box display="flex">
        <Box flex="1 1" fontSize="10PX" lineHeight="12px" fontWeight={500}>
          <Typography component="span" className="text-desc" variant="inherit">
            © Copyright {year} IFCA MSC Berhad
          </Typography>
        </Box>
        <Typography component="span" className="text-desc" variant="inherit">
          All Rights Reserved.
        </Typography>
      </Box>
    </Box>
  )
}
