import { SubContainerX, Subheader, SubheaderText } from '@ifca-ui/core'
import { Box, Typography } from '@mui/material'
import { useProjectDetailQuery } from 'generated/graphql'
import React from 'react'
import { useParams } from 'react-router'

export const ProjectSubHeader = () => {
  const { projectID }: any = useParams()

  const {
    data: { projectDetail } = {
      projectDetail: undefined,
    },
  } = useProjectDetailQuery({
    variables: {
      id: projectID,
    },
  })

  return (
    <SubContainerX>
      <Subheader>
        <SubheaderText
          primary={
            <Box borderBottom="1px solid #fff">
              <Box width="100%" display="flex">
                <Typography
                  flex="1 1"
                  component="span"
                  variant="inherit"
                  color="primary.dark"
                  className="text-xsTitle"
                  margin="2px 0px"
                >
                  {projectDetail?.name}
                </Typography>
              </Box>
            </Box>
          }
        />
      </Subheader>
    </SubContainerX>
  )
}
