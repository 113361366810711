import React, { lazy } from 'react'
import { RouteObject } from 'react-router'

const UnitListing = lazy(() => import('./UnitListing'))
const UnitSetup = lazy(() => import('./UnitSetup'))
const SetupForm1 = lazy(() => import('../Form/SetupForm1'))
const SetupForm2 = lazy(() => import('../Form/SetupForm2'))
const SetupForm3 = lazy(() => import('../Form/SetupForm3'))
const SetupForm4 = lazy(() => import('../Form/UnitSetupForm4'))
const UnitSetupEditForm = lazy(() => import('./UnitSetupEditForm'))
const ImportUnitForm = lazy(() => import('../ImportUnit'))

export const UNIT_PATH = {
  UNIT_LISTING: 'unit-listing',
  IMPORT_EXCEL: 'unit-import',
  SETUP_FORM_1: 'unit-setup-form-1',
  SETUP_FORM_2: 'unit-setup-form-2',
  SETUP_FORM_3: 'unit-setup-form-3',
  SETUP_FORM_4: 'unit-setup-form-4',
}

const unitSetupRoutes: RouteObject[] = [
  {
    path: 'unit-listing',
    element: <UnitListing />,
  },
  // {
  //   path: 'unit-setup-form',
  //   element: <UnitSetup />,
  // },
  {
    path: 'unit-import',
    element: <ImportUnitForm title={'Unit Setup'} />,
  },
  {
    path: 'unit-setup-form-edit',
    element: <UnitSetupEditForm />,
  },
  {
    path: 'unit-setup-form-1',
    element: <SetupForm1 isCarpark={false} />,
  },
  {
    path: 'unit-setup-form-2',
    element: <SetupForm2 isCarpark={false} />,
  },
  {
    path: 'unit-setup-form-3',
    element: <SetupForm3 isCarpark={false} />,
  },
  {
    path: 'unit-setup-form-4',
    element: <SetupForm4 isCarpark={false} />,
  },
]

export default unitSetupRoutes
