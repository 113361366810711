import { Button, type ButtonProps } from '@mui/material'
import {
  Colors,
  FontSizes,
  LineHeights,
  FontWeights,
} from '../../../styles/variables'
import { styled } from '@mui/material/styles'

interface ResetButtonProps extends ButtonProps {}
export const ResetButton = styled(Button)<ResetButtonProps>`
  &.MuiButton-root {
    background-color: ${Colors.white};
    padding: 4px 16px;
    font-size: ${FontSizes.fs_12};
    line-height: ${LineHeights.lh_16};
    font-weight: ${FontWeights.semibold};
    text-transform: capitalize;
  }
  &.MuiButton-outlinedPrimary {
    border: 1px solid ${Colors.primaryOrange};
  }
`
