import { createGlobalStyle } from 'styled-components'
import {
  ColorStyles,
  FontFamily,
  FontWeightClasses,
  Typography,
} from './variables'

export const UICoreGlobalStyle = createGlobalStyle`
body {
  font-family: ${FontFamily?.default};
  margin: 0;
  -webkit-font-smoothing: antialiased;

}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
html {
  background: #f5f5f5;
}
html,
body {
  min-height: 100%;

}
main {
  width: calc(100% - 300px);
}
u {
  cursor: pointer;
}
${ColorStyles}
${FontWeightClasses}
${Typography}
 
`
