export const ColorGradients = {
  blue: 'linear-gradient(to right, #3340ff, #000ed9)',
}
export const Colors = {
  white: '#ffffff',
  lightGrey: '#f5f5f5',
  grey: '#9B9B9B',
  darkGrey: '8A8A8A',
  black: '#212121',
  darkBlue: '#002672',
  primaryOrange: '#FF9800',
  lightOrange: '#faf2e8',
  actionButtonBlue: '#7EBDFF',
  subheaderBlue: '#BFD3FF',
  lightBlue: '#DBE5FF',
  green: '#00B259',
  orange: '#FFB11F',
  red: '#FF1B40',
}
export const ColorStyles = {
  '.color-white': {
    color: Colors.white,
  },
  '.color-black': {
    color: Colors.black,
  },
  '.color-dark-grey': {
    color: Colors.darkGrey,
  },
  '.color-grey': {
    color: Colors.grey,
  },
  '.color-light-grey': {
    color: Colors.lightGrey,
  },
  '.color-dark-blue': {
    color: Colors.darkBlue,
  },
  '.color-primary-orange': {
    color: Colors.primaryOrange,
  },
  '.color-light-orange': {
    color: Colors.lightOrange,
  },
  '.color-action-btn-blue': {
    color: Colors.actionButtonBlue,
  },
  '.color-subheader-blue': {
    color: Colors.subheaderBlue,
  },
  '.color-light-blue': {
    color: Colors.lightBlue,
  },
  // others
  '.color-red': {
    color: Colors.red,
  },
  '.color-green': {
    color: Colors.green,
  },
  '.color-orange': {
    color: Colors.orange,
  },
}
