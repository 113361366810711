import React, { lazy } from 'react'
import { RouteObject } from 'react-router'

const DownpaymentReminderListing = lazy(
  () => import('./DownpaymentReminderListing')
)
const DownpaymentReminderForm = lazy(() => import('./DownpaymentReminderForm'))

export const DOWNPAYMENT_REMINDER_PATH = {
  LISTING: '/:projectID/downpayment-reminder',
  DETAIL: '/:projectID/downpayment-reminder/detail/:id',
  FORM_ADD: '/:projectID/downpayment-reminder/add',
}

const DownpaymentReminderRoutes: RouteObject[] = [
  {
    path: 'downpayment-reminder',
    children: [
      {
        path: '',
        element: <DownpaymentReminderListing />,
      },
      {
        path: 'detail/:generationBatch',
        element: <DownpaymentReminderForm mode="detail" />,
      },
      {
        path: 'add',
        element: <DownpaymentReminderForm mode="add" />,
      },
    ],
  },
]

export default DownpaymentReminderRoutes
