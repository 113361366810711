import { RouteObject } from 'react-router'
import React, { lazy } from 'react'

const ContractVOListing = lazy(() =>
  import('./ContractVOListing').then(module => ({
    default: module.ContractVOListing,
  }))
)

const ContractVODetail = lazy(() =>
  import('./ContractVODetail').then(module => ({
    default: module.ContractVODetail,
  }))
)

const ContractVOForm = lazy(() =>
  import('./ContractVOForm').then(module => ({
    default: module.ContractVOForm,
  }))
)

const ContractVoRoutes: RouteObject[] = [
  {
    path: '/contract-management/:contractID/contract-vo',
    element: <ContractVOListing />,
  },
  {
    path: '/contract-management/:contractID/contract-vo/detail/:voID',
    element: <ContractVODetail />,
  },
  {
    path: '/contract-management/:contractID/contract-vo/add',
    element: <ContractVOForm />,
  },
  {
    path: '/contract-management/:contractID/contract-vo/edit/:voID',
    element: <ContractVOForm />,
  },
]

export default ContractVoRoutes
