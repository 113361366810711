import React, { lazy } from 'react'

const KeyCollectionAppointmentListing = lazy(
  () => import('./KeyCollectionAppointmentListing')
)

const KeyCollectionAppointmentForm = lazy(
  () => import('./KeyCollectionAppointmentForm')
)

const KeyCollectionAppointmentEditForm = lazy(
  () => import('./KeyCollectionAppointmentEditForm')
)

const KeyCollectionAppointmentDetail = lazy(
  () => import('./KeyCollectionAppointmentDetail')
)

const keyCollectionAppointmentRoutes = [
  {
    path: 'collection-appointment',
    children: [
      {
        path: '',
        element: <KeyCollectionAppointmentListing />,
      },
      {
        path: 'add',
        element: <KeyCollectionAppointmentForm mode="add" />,
      },
      {
        path: 'edit/:id',
        element: <KeyCollectionAppointmentEditForm />,
      },

      {
        path: 'detail/:id',
        element: <KeyCollectionAppointmentDetail />,
      },
    ],
  },
]
export default keyCollectionAppointmentRoutes
